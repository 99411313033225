import { ILanguages, LanguageList } from './language'

const createGoogleTTSRequest = (text: string, languageCode: ILanguages) => {
  const googleLanguageCode = LanguageList[languageCode]

  const ssml = `<speak>
    <prosody pitch="medium" rate="110%">${text}</prosody>
  </speak>` 

  return {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      input: {
        ssml,
      },
      voice: {
        languageCode: googleLanguageCode.ttsLanguageCode,
        name: googleLanguageCode.gglVoiceName,
      },
      audioConfig: {
        audioEncoding: 'MP3',
      },
    }),
    method: 'POST',
  }
}

export const playGoogleAudio = async (
  text: string,
  languageCode: ILanguages,
): Promise<ArrayBuffer> => {
  const googleEndpoint = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  const payload = createGoogleTTSRequest(text, languageCode)
  const response = await fetch(googleEndpoint, payload)
  const result = await response.json()

  // Turn base64 to binary
  const bytes = window.atob(result.audioContent.replace(/^.*,/, ''))
  const buffer = new Uint8Array(bytes.length)

  for (let i = 0; i < bytes.length; ++i) {
    buffer[i] = bytes.charCodeAt(i)
  }

  return buffer.buffer
}
