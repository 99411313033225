import './Troubleshooting.css'

import { observer } from 'mobx-react-lite'

export const Troubleshooting = observer(({ title }: { title?: string }) => {

  return (
    <>
      <h2 className="mb-4 text-center">{title}</h2>
      <div>
        <h4>サウンドの確認</h4>
        <p>マイクとスピーカーの入出力デバイスが正しく設定されていることを確認してください。</p>

        <h5>Windowsの場合:</h5>
        <ol>
          <li><strong>「設定」メニューを開く</strong>: スタートメニューから「設定」を開きます。</li>
          <li><strong>「システム」を選択</strong>: 「システム」カテゴリを選択します。</li>
          <li><strong>「サウンド」を選択</strong>: 左側のメニューから「サウンド」を選択します。</li>
          <li><strong>「入力」と「出力」のデバイスを確認</strong>:
            入力（マイク）と出力（スピーカー）のデバイスが正しく選択されていることを確認します。
          </li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic01.png" alt="設定"/></div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic02.png" alt="サウンド"/></div>

        <h5>Macの場合:</h5>
        <ol>
          <li><strong>「システム環境設定」を開く</strong>: Appleメニューから「システム環境設定」を選択します。
          </li>
          <li><strong>「サウンド」を選択</strong>: 「サウンド」アイコンをクリックします。</li>
          <li><strong>「出力」と「入力」のデバイスを確認</strong>:「出力」タブと「入力」タブを切り替えて、正しいデバイスが選択されていることを確認します
          </li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic03.png" alt="システム環境設定"/>
        </div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic04.png" alt="サウンド"/>
        </div>
      </div>
      <div>
        <h4>音量ミキサーの確認</h4>
        <h5>Windowsの場合:</h5>
        <ol>
          <li><strong>タスクバーの音量アイコンを右クリック</strong>: 画面右下にあるスピーカーアイコンを右クリックします。
          </li>
          <li><strong>「音量ミキサーを開く」を選択</strong>: ポップアップメニューから「音量ミキサーを開く」を選択します。
          </li>
          <li><strong>各アプリケーションの音量レベルを確認</strong>:音量ミキサーウィンドウで、システム音量と各アプリケーションの音量レベルを確認し、適切なレベルに設定されているか確認します。
          </li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic05.png" alt="設定"/></div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic06.png" alt="サウンド"/></div>

        <h5>Macの場合:</h5>
        <ol>
          <li><strong>画面右上のスピーカーアイコンをクリック</strong>: 画面右上にあるスピーカーアイコンをクリックします。
          </li>
          <li><strong>「サウンド環境設定」を選択</strong>: ドロップダウンメニューから「サウンド環境設定」を選択します。
          </li>
          <li><strong>「出力」と「入力」タブで音量レベルを確認</strong>:「サウンド」ウィンドウが表示されます。「出力」タブと「入力」タブを切り替えて、それぞれの音量レベルが適切に設定されているか確認します。
          </li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic07.png" alt="システム環境設定"/></div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic08.png" alt="サウンド"/></div>
      </div>
      <div>
        <h4>複数人利用における確認</h4>
        <p>複数人による会議システムご利用の場合、以下の点にご注意ください。</p>

        <h5>参考ケース</h5>
        <p>参加者の一部または全てがイヤフォンではなくスピーカーを使用している場合、ご利用用途に合っているかご確認ください。</p>

        <h6>①パソコン内臓スピーカー</h6>
        <p>パソコンによって、エコーキャンセル機能が搭載されている機種もございますが、エコーキャンセル機能が搭載されていないもしくは、設定が行われていない場合、そのパソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。エコーキャンセルをご確認いただくか、イヤフォンをご利用ください。</p>
        <ul>
          <li>パソコンにエコーキャンセル機能が搭載されているか？</li>
          <li>パソコンのエコーキャンセルが機能しているか？</li>
          <li>マイクとスピーカーが内臓スピーカーに設定されているか？</li>
        </ul>

        <h6>②外付けスピーカー</h6>
        <p>ほとんどの場合、外付けスピーカーは、エコーキャンセル機能を搭載していません。この場合、パソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。イヤフォンをご利用ください。</p>

        <h6>③外付けマイクスピーカー</h6>
        <p>ほとんどの場合、外付けマイクスピーカーには、エコーキャンセル機能が搭載されています。</p>

        <h6>＜エコーキャンセル機能が搭載されている＞</h6>
        <p>エコーキャンセル機能が正しく設定されていない場合、そのパソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。エコーキャンセルが機能しているかをご確認ください。</p>
        <ul>
          <li>スピーカマイクのエコーキャンセルが機能しているか？</li>
          <li>マイクとスピーカーがスピーカマイクに設定されているか？</li>
        </ul>

        <h6>＜エコーキャンセル機能が搭載されていない＞</h6>
        <p>パソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。イヤフォンをご利用ください。</p>
      </div>
    </>
  )
});
