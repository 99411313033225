import { ILanguages, LanguageList } from './language'

const createAzureTtsRequest = (text: string, languageCode: ILanguages) => {
  const { azureVoiceName, ttsLanguageCode } = LanguageList[languageCode]

  const body = `<speak
    version="1.0"
    xmlns="http://www.w3.org/2001/10/synthesis"
    xml:lang="${ttsLanguageCode}">
    <voice name="${azureVoiceName}"
      ><prosody pitch="medium" rate="1.10">${text}</prosody></voice
    >
  </speak>`

  return {
    headers: {
      'Content-Type': 'application/ssml+xml',
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_AZURE_SUBSCRIPTION_KEY || '',
      'X-Microsoft-OutputFormat': 'audio-48khz-192kbitrate-mono-mp3',
      'User-Agent': 'CFace',
    },
    body,
    method: 'POST',
  }
}

export const playAzureAudio = async (
  text: string,
  languageCode: ILanguages,
): Promise<ArrayBuffer> => {
  const azureEndpoint = 'https://japaneast.tts.speech.microsoft.com/cognitiveservices/v1'

  const payload = createAzureTtsRequest(text, languageCode)
  const response = await fetch(azureEndpoint, payload)
  console.log('Azure TTS result', response)

  const blob = await response.blob()
  return await blob.arrayBuffer()
}
