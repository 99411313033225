import type { Socket } from 'socket.io-client'
import { io } from 'socket.io-client'
import { useEffect, useMemo, useRef, useState } from 'react'
import useQuery from '../hook/useQuery';

import { LocalStorage } from '../store/LocalStorage'
import { addTranslation, translate } from '../api/GoogleApi';
import { ILanguages } from '../util/language'
import Messages from '../store/Messages'
import { INITIAL_ID, htmlDecode, changeLanguageAgenda, changeLanguageMinutes } from '../util/util'
import AdvisorStateInst, { RealtimeMinute } from '../store/AdvisorState'
import ConfirmMeetingActiveModalState from '../store/ConfirmMeetingActiveModalState'
import { sentryLog } from "../util/sentry";
import advisorMessages from "../store/AdvisorMessages";

const MT_EVENTS = {
  ROOM_CLOSED: 'roomClosed',
  ROOM_BEGIN:  'roomBegin',
  ROOM_ID: 'roomId',
  ROOM_CLOSE: 'roomClose',
  JOIN_ROOM: 'joinRoom',
  CHANGE_NAME: 'changeName',
  CHANGE_LABELS: 'changeLabels',
  MEMBERS: 'members',
  SEND_MSG: 'sendMessage',
  RECEIVE_MSG: 'receiveMessage',
  TRANS: 'translation',
  ADD_MEM: 'onAddMember',
  NEW_MEM: 'newMeetingUser',
  CONNECT: 'connection',
  DISCONNECT: 'disconnect',
  INITIATE_ADVISORY: 'initiateAdvisory',
  STOP_ADVISORY: 'stopAdvisory',
  BROADCAST_ADVISORY: 'broadcastAdvisory',
  BROADCAST_ADVISORY_ACCURACY: 'broadcastAdvisoryAccuracy',
  BROADCAST_STOP_ADVISORY: 'broadcastStopAdvisory',
  REALTIME_UPDATE: 'realtimeUpdate',
  AGENDA_ADD: 'agendaAdd',
  AGENDA_EDIT: 'agendaEdit',
  AGENDA_DELETE: 'agendaDelete',
  AGENDA_CHANGE: 'agendaChange',
  ALERT: 'alert',
  CONFIRM_MEETING_ACTIVE: 'confirmMeetingActive',
  ADVISOR_CHAT_REQUEST: 'advisorChatRequest',
  ADVISOR_CHAT_RESPONSE: 'advisorChatResponse',
} as const

const ADVISOR_EVENT = {
  INIT: '[$init]',
  END:  '[$end]',
} as const

export type AssistantMessageSteamResponse = {
  finish: boolean;
  delta: string;
  snapshot: string;
};


export const useChat = (auth?: string | null) => {
  const [members, setMembers] = useState<{ [id: string]: ChatUser }>({})

  // get room id back from server after jwt token verified
  const roomOwnerIdRef = useRef(INITIAL_ID)
  const meetingIdRef = useRef(INITIAL_ID)
  const enableAIAdvisorRef = useRef(false)

  const membersRef = useRef(members)
  useEffect(() => {
    membersRef.current = members
  }, [members])

  const socketRef = useRef<Socket>()

  const { getSummaryFlag } = useQuery();
  const summaryFlag = getSummaryFlag();

  useEffect(() => {
    if (LocalStorage.name && LocalStorage.uid && auth && !summaryFlag && meetingIdRef.current) {
      if(!socketRef.current){
        console.log('create socket')
        socketRef.current = io(process.env.REACT_APP_BASE_URL!.replace('/api', '/mt'), {
          rejectUnauthorized: false,
          secure: false,
          transports: ['websocket'],
          reconnection: true,
          auth: {
            token: auth,
            sender: {
              id: LocalStorage.uid,
              displayName: LocalStorage.name,
            },
            domain: process.env.REACT_APP_FIREBASE_PROJECTID,
          },
        })
      }

      socketRef.current.on('connect', () => {
        LocalStorage.setValue('roomState', 'waiting')
        console.log('Connected', socketRef.current?.id)
        // socketRef.current.emit(MT_EVENTS.JOIN_ROOM, meetingIdRef.current)
      }).on(MT_EVENTS.ROOM_ID, (roomId: string, roomOwnerId: string, agenda: string, minutes: RealtimeMinute[], enableAIAdvisor: boolean) => {
        meetingIdRef.current = roomId
        roomOwnerIdRef.current = roomOwnerId;
        enableAIAdvisorRef.current = enableAIAdvisor; // AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
        socketRef.current?.emit(MT_EVENTS.JOIN_ROOM, meetingIdRef.current)
        console.log('realtime init', minutes);
        const language = LocalStorage?.language[0] || ILanguages['ja-JP'];
        if(minutes && minutes.length !== 0){
          AdvisorStateInst.updateMinute(changeLanguageMinutes(language, minutes), changeLanguageAgenda(language, agenda));
        }
      }).on('connect_error', (error: any) => {
        if(error?.message === 'User not authenticated'){
          LocalStorage.setValue('roomState', 'notexist')
          return;
        }
        sentryLog(error)
        if (error?.message === 'code:400') {
          LocalStorage.setValue('roomState', 'completed')
        } else {
          console.warn('Unhandled socket error', error)
        }
      }).on("connect_failed", (error : any) => {
        sentryLog(error)
      }).on(MT_EVENTS.DISCONNECT, () => {
        console.log('Disconnected', socketRef.current?.id)
      }).on(MT_EVENTS.NEW_MEM, (data: any) => {
        const newUser: ChatUser = JSON.parse(data)
        // addNewUser(newUser)
        console.log(MT_EVENTS.NEW_MEM, newUser)
      }).on(MT_EVENTS.MEMBERS, (data: any) => {
        setMembers(JSON.parse(data))
      }).on(MT_EVENTS.RECEIVE_MSG, async (data: any) => {
        const message: ChatMessage = data
        if (!message.translations) {
          message.translations = {}
        }
        // guard agains empty message
        if (!message.text) {
          return
        }
        if(summaryFlag) {
          return
        }

        const sourceLanguage    = message.sourceLanguage as ILanguages
        const primaryLanguage = !LocalStorage.language || LocalStorage.language.length === 0 ? [ILanguages["ja-JP"]] : LocalStorage.language;

        if (sourceLanguage && !primaryLanguage.includes(sourceLanguage)) {
          const sourceToPrimary = htmlDecode(await translate(message.text, sourceLanguage, primaryLanguage[0]))
          message.translatedLang = primaryLanguage[0]
          message.translated = sourceToPrimary

          if (sourceToPrimary) {
            socketRef.current?.emit(MT_EVENTS.TRANS, message.id, sourceToPrimary, primaryLanguage)
          }
        }

        Messages.add(message)

        if(sourceLanguage !== null){
          await addTranslation(message.text, sourceLanguage, async (toLanguage: ILanguages, translatedText: string) => {
            Messages.addTranslation(message.id, toLanguage, translatedText);
          })
        }

      }).on(MT_EVENTS.TRANS, (id: string, message: string, lang: string) => {
        Messages.addTranslation(id, lang, message)
      }).on(MT_EVENTS.CHANGE_NAME, (userId, newName) => {
        Messages.changeName(userId, newName)
      }).on(MT_EVENTS.CHANGE_LABELS, (userId: string, idMap: { [id: string]: string }) => {
        Messages.changeLabel(userId, idMap)
      }).on(MT_EVENTS.ROOM_BEGIN,  (roomInfo) => {
        console.log("Received `ROOM_BEGIN`");
        LocalStorage.setValue('roomState', 'incall')
        LocalStorage.setValue('endTime', new Date(roomInfo.endTime))
      }).on(MT_EVENTS.ROOM_CLOSED, () => {
        console.log("Received `ROOM_CLOSED`");
        LocalStorage.setValue('roomState', 'completed')
        socketRef.current?.disconnect()
      }).on(MT_EVENTS.BROADCAST_ADVISORY, (id, message) => {
        if(summaryFlag) {
          return
        }
        switch(message) {
          case ADVISOR_EVENT.INIT:
            AdvisorStateInst.initialize(id); break;
          case ADVISOR_EVENT.END:
            AdvisorStateInst.finish(id); break;
          default:
            AdvisorStateInst.feed(id, message); break;
        }
      }).on(MT_EVENTS.BROADCAST_STOP_ADVISORY, () => {
        try {
          AdvisorStateInst.stop();
        }catch (error: any){
          sentryLog(error);
        }
      }).on(MT_EVENTS.BROADCAST_ADVISORY_ACCURACY, (id, message) => {
        if(summaryFlag) {
          return
        }
        switch(message) {
          case ADVISOR_EVENT.INIT:
            break;
          case ADVISOR_EVENT.END:
            AdvisorStateInst.llmAdvisorMessage = '';
            break;
          default:
            AdvisorStateInst.llmAdvisorMessage = message;
            break;
        }
      }).on(MT_EVENTS.REALTIME_UPDATE, (agenda: string | null, minutes: RealtimeMinute[]) => {
        console.log('realtime update', agenda, minutes);
        const language = LocalStorage?.language[0] || ILanguages['ja-JP'];
        if(minutes && minutes.length !== 0){
          let changeAgenda = agenda;
          if(agenda){
            changeAgenda = changeLanguageAgenda(language, agenda);
          }
          AdvisorStateInst.updateMinute(changeLanguageMinutes(language, minutes), changeAgenda);
        }
      }).on(MT_EVENTS.ALERT, (message: string) => {
        if(LocalStorage.roomClosed){
          return;
        }
        alert(message);
      }).on(MT_EVENTS.CONFIRM_MEETING_ACTIVE, () => {
        // 会議を継続するかを尋ねる
        ConfirmMeetingActiveModalState.callback();
      }).on(MT_EVENTS.ADVISOR_CHAT_RESPONSE, (id: string, response: AssistantMessageSteamResponse) => {
        // 会議が終了している場合は処理しない
        if(LocalStorage.roomClosed){
          return;
        }
        // IDが存在しない場合は処理しない
        if(!advisorMessages.existMessage(id)){
          return;
        }
        // メッセージを更新
        advisorMessages.beforeStreamMessage();
        advisorMessages.updateUnfinishedMessage(id, response.snapshot, response.finish);
      })

      return () => {
        try {
          if(socketRef.current){
            socketRef.current.off()
            if(socketRef.current.connected){
              socketRef.current.disconnect()
            }
            // socketRef.current.off('connect')
            // socketRef.current.off('disconnect')
            // socketRef.current.off('newMeetingUser')
            // socketRef.current.off('members')
            // socketRef.current.off('receiveMessage')
          }
        } catch (e) {
          // ignored
        }
      }
    }
  }, [auth, summaryFlag])

  const memoHandles = useMemo(() => {
    return {
      sendMessage: (message: string, id: string, speakerId: string, talkLabel: string, language?: ILanguages) => {
        socketRef.current?.emit(MT_EVENTS.SEND_MSG, { id, message },
                                meetingIdRef.current,
                                speakerId,
                                talkLabel,
                                language);
      },
      changeName:  (name: string) => {
        socketRef.current?.emit(MT_EVENTS.CHANGE_NAME, name);
      },
      changeLabels:  (idMap: { [id: string]: string }) => {
        socketRef.current?.emit(MT_EVENTS.CHANGE_LABELS, idMap);
      },
      agendaAdd:  (val: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_ADD, val);
      },
      agendaEdit:  (val: string, targetAgenda: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_EDIT, val, targetAgenda);
      },
      agendaDelete:  (targetAgenda: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_DELETE, targetAgenda);
      },
      agendaChange:  (targetAgenda: string) => {
        socketRef.current?.emit(MT_EVENTS.AGENDA_CHANGE, changeLanguageAgenda(ILanguages['ja-JP'], targetAgenda));
      },
      initiateAdvisory: () => {
        socketRef.current?.emit(MT_EVENTS.INITIATE_ADVISORY, LocalStorage.language[0], AdvisorStateInst.llmAdvisorMessage);
        AdvisorStateInst.llmAdvisorMessage = ''
      },
      stopAdvisory: () => {
        try {
          socketRef.current?.emit(MT_EVENTS.STOP_ADVISORY);
        }catch (error: any){
          sentryLog(error);
        }
      },
      roomClose:  () => {
        socketRef.current?.emit(MT_EVENTS.ROOM_CLOSE);
      },
      advisorChatRequest: (id: string, content: string) => {
        socketRef.current?.emit(MT_EVENTS.ADVISOR_CHAT_REQUEST, id, content, LocalStorage.language[0]);
      }
    };
  }, []);

  return {
    ...memoHandles,
    meetingIdRef,
    roomOwnerIdRef,
    enableAIAdvisorRef,
  }
}
