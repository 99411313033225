import { useLocation } from 'react-router-dom';
import { LocalStorage } from "../store/LocalStorage";
import { isNotProduction } from "../util/util";

const useQuery = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    // 要約のみのウインドウとして表示する時のGETパラメータ
    const paramSummaryWindow = 'summary';
    // データを共有したい時にiframeで表示する時のGETパラメータ
    const paramShareWindow = 'share';
    // STTのURLを変更する場合のGETパラメータ（開発・ステージング環境のみ）
    const paramChangeSTTWindow = 'stt';

    // 要約のみのウインドウかどうかのフラグとして使用
    const getSummaryFlag = () => {
        return query.get(paramSummaryWindow) === '1';
    };

    // データ共有用にiframeで表示しているかどうかのフラグとして使用
    const getShareFlag = () => {
        return query.get(paramShareWindow) === '1';
    };

    // STTのURLが指定されているか確認
    const hasSttURL = () => {
        // 開発・ステージング環境のみ使用
        if (isNotProduction()) {
            return !!query.get(paramChangeSTTWindow);
        }
        return false;
    };

    // STTのURLの確認
    const getSttURL = () => {
        // デフォルトのURL
        let url = process.env.REACT_APP_SPEAKER_DIARIZATION_URL!;

        // 開発・ステージング環境のみ使用
        if (isNotProduction()) {
            // GETパラメータをURLとして取得
            if (query.get(paramChangeSTTWindow) && typeof query.get(paramChangeSTTWindow) === 'string') {
                url = query.get(paramChangeSTTWindow) ?? url;
            }
        }
        return url;
    };

    // メッセージを受信しローカルストレージに保管する
    const receiveMessage = () => {
        const messageHandler = (event: MessageEvent<{ownerId?: string}>) => {
            if(process.env.REACT_APP_CLIPEAR_USER_MANGEMENT_URL){
                const url = new URL(process.env.REACT_APP_CLIPEAR_USER_MANGEMENT_URL);
                if (event.origin === url.origin) {
                    // オーナーIDが含まれている場合はローカルストレージに保管
                    if(event.data && typeof event.data === 'object' && typeof event.data.ownerId === 'string'){
                        LocalStorage.setValue('ownerId', event.data.ownerId);
                    }
                }
            }
        };

        window.addEventListener('message', messageHandler);

        // コンポーネントのアンマウント時にイベントリスナーを削除
        return () => {
            window.removeEventListener('message', messageHandler);
        };
    }

    return { query, getSummaryFlag, getShareFlag, hasSttURL, getSttURL, paramSummaryWindow, paramShareWindow, paramChangeSTTWindow, receiveMessage };
};

export default useQuery;
