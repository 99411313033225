import { ILanguages } from '../util/language';
import { LanguageList } from '../util/language'
import { htmlDecode } from '../util/util';
import { LocalStorage } from '../store/LocalStorage';
import { sentryLog } from '../util/sentry';

export const translate = async (
  text: string,
  source: ILanguages,
  target: ILanguages,
): Promise<string> => {
  const endpoint = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const sourceLang = LanguageList[source];
  const targetLang = LanguageList[target];
  const response = await fetch(endpoint, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      q: [text],
      source: sourceLang.translateCode,
      target: targetLang.translateCode,
    }),
    method: 'POST',
  });
  if (sourceLang.translateCode !== targetLang.translateCode) {
    const result = await response.json();
    try {
      return decodeURI(result?.data?.translations?.[0]?.translatedText);
    }catch (e){
      // In case of error, return without decodeURI
      return result?.data?.translations?.[0]?.translatedText;
    }
  } else {
    return text;
  }
}

export const addTranslation = async (sourceMessage: string, sourceLanguage: ILanguages, callback: (toLanguage: ILanguages, translatedText: string) => Promise<void>) => {

  const left = !LocalStorage.language || LocalStorage.language.length === 0 ? [ILanguages["ja-JP"]] : LocalStorage.language;
  const right = !LocalStorage.translationLanguage ? ILanguages["ja-JP"] : LocalStorage.translationLanguage;

  let toLanguage: ILanguages = ILanguages["ja-JP"];

  // 左側と右側が1:1で一緒の場合（例：日本語 ⇔ 日本語）
  if (left.length === 1
    && left[0] === right) {
    // 指定言語以外は指定言語に翻訳する（例：相手が英語を話した時など）
    if (left[0] !== sourceLanguage) {
      // 指定言語に翻訳
      toLanguage = left[0];
    }
  }
  // 左側と右側が1:1で異なる場合（例：日本語 ⇔ 英語）※相互翻訳対象
  else if (left.length === 1
    && left[0] !== right) {
    // 左側以外は左側の言語に翻訳する（例：翻訳言語、または他の言語）
    if (left[0] !== sourceLanguage) {
      // 左側に翻訳
      toLanguage = left[0];
    }
    // 左側は右側の言語に翻訳する（相互翻訳）
    else if (left[0] === sourceLanguage) {
      // 右側に翻訳
      toLanguage = right;
    }
  }
  // 左側が複数言語の場合（単純に左側の言語のみ翻訳対象となる）
  else if (left.length !== 1) {
    // 他の言語の場合は翻訳する（他の人の可能性）
    if (!left.includes(sourceLanguage) && right !== sourceLanguage) {
      // 左側に翻訳
      toLanguage = left[0];
    }
    // 左側の言語の場合
    else if (left.includes(sourceLanguage)) {
      // 右側に翻訳
      toLanguage = right;
    }
  }

  if(sourceLanguage !== toLanguage){
    let translated = sourceMessage;
    try {
      translated = htmlDecode(await translate(sourceMessage, sourceLanguage, toLanguage));
    }catch (e: any){
      console.error('Error during translation', e);
      sentryLog(e);
    }
    // 後続処理をするためにコールバック
    console.log('addTranslation', {sourceMessage, sourceLanguage, toLanguage, translated})
    await callback(toLanguage, translated)
  }
}
