import './MinutesList.css'
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {CrossFade} from "react-crossfade-simple";
import {useTranslation} from "react-i18next";
import {MINUTE, RealtimeMinute} from '../store/AdvisorState'
import {isInitialMinutes, changeLanguageAgenda, changeLanguageMinutes} from "../util/util";
import {LanguageList} from "../util/language";

export interface MinutesListHandles {
    setRealtimeMinutes: (newMinutes: RealtimeMinute[]) => void;
    setAgenda: (newAgenda: string) => void;
}

interface MinutesListProps {
    onAgendaChange?: (agenda: string) => void
}

const MinuteArea = ({agenda, markdown, realtimeMinute}: {
    agenda: string,
    markdown: string,
    realtimeMinute: RealtimeMinute
}) => {
    const {t} = useTranslation()

    const [crossFadeSwitch, setCrossFadeSwitch] = useState('A');
    const [textFirst, setTextFirst] = useState<string>('');
    const [textSecond, setTextSecond] = useState<string>('');
    const textFirstRef = useRef(textFirst);
    const textSecondRef = useRef(textSecond);
    const crossFadeSwitchRef = useRef(crossFadeSwitch);
    textFirstRef.current = textFirst;
    textSecondRef.current = textSecond;

    useEffect(() => {

        let showTexts = markdown;

        if (realtimeMinute.type === MINUTE.agenda && markdown !== '' && !isInitialMinutes(markdown)) {
            showTexts = '';
            const sections = ['minutes', 'concerns', 'pending', 'decisions', 'next'];
            const translations = ['議事録', '懸念事項', '保留事項', '決定事項', '宿題事項'];

            sections.forEach((section, index) => {
                // @ts-ignore
                if (realtimeMinute[section]) {
                    showTexts += `## ${t(translations[index])}\n`;
                    // @ts-ignore
                    showTexts += `- ${realtimeMinute[section].join('\n- ')}\n`;
                }
            });
        }

        // クロスフェード
        const crossFade = () => {
            if (crossFadeSwitchRef.current === 'A') {
                setTextSecond(showTexts);
                textSecondRef.current = showTexts;
            } else {
                setTextFirst(showTexts);
                textFirstRef.current = showTexts;
            }
            setCrossFadeSwitch(crossFadeSwitchRef.current === 'A' ? 'B' : 'A');
            crossFadeSwitchRef.current = crossFadeSwitchRef.current === 'A' ? 'B' : 'A';
        }

        crossFade();
    }, [agenda, markdown, realtimeMinute, t]);

    const formatText = (text: string): string => {
        return text.replace(/^```markdown\s*/, '')
            .replace(/^```\s*/, '')
            .replace(/```\s*$/, '');
    };
    return (
        <CrossFade
            contentKey={crossFadeSwitch}
            timeout={2000}
            onTransition={(from, to) => {
                const fromElem = from.querySelector<HTMLElement>('.markdown');
                const toElem = to.querySelector<HTMLElement>('.markdown');

                if (fromElem) {
                    fromElem.style.animation = '';
                    fromElem.style.animation = 'from-color-change 1s 2';
                    setTimeout(() => {
                        fromElem.style.animation = ''
                    }, 3000);
                }
                if (toElem) {
                    toElem.style.animation = '';
                    toElem.style.animation = 'to-color-change 1s 2';
                    setTimeout(() => {
                        toElem.style.animation = ''
                    }, 3000);
                }
            }}>
            <ReactMarkdown className="markdown"
                           rehypePlugins={[rehypeRaw]}>{crossFadeSwitch === 'A' ? formatText(textFirst) : formatText(textSecond)}</ReactMarkdown>
        </CrossFade>
    )
}

export const MinutesList = forwardRef<MinutesListHandles, MinutesListProps>(({onAgendaChange}: MinutesListProps, ref) => {
    const {i18n} = useTranslation()

    const [agenda, setAgenda] = useState<string>(LanguageList['ja-JP'].summaryAgenda as string);
    const [minutesList, setMinutesList] = useState<RealtimeMinute[]>([]);

    // 言語変更時に規定のタイトル名「全体要約」「議事録」を変更する
    const changeLanguageHandler = useCallback((lng: string) => {
        setAgenda(changeLanguageAgenda(lng, agenda));
        setRealtimeMinutes(changeLanguageMinutes(lng, minutesList));
    }, [agenda, minutesList]);

    useEffect(() => {
        // 言語変更時に規定のタイトル名「全体要約」「議事録」を変更する
        i18n.on('languageChanged', changeLanguageHandler);
        return () => {
            i18n.off('languageChanged', changeLanguageHandler);
        };
    }, [changeLanguageHandler, i18n]);

    const setRealtimeMinutes = (newMinutes: RealtimeMinute[]) => {
        setMinutesList([...newMinutes]);
    };

    // コンポーネント外部からもメソッドを使用可能にする
    useImperativeHandle(ref, () => ({
        setRealtimeMinutes,
        setAgenda,
    }));

    return (
        <>
            {minutesList.map((item, index) => (
                <div
                    key={item.agenda}
                    className={`minutes ${item.agenda === agenda ? ' show' : ''}`}
                >
                    <MinuteArea agenda={item.agenda} markdown={item.markdown} realtimeMinute={item}/>
                </div>
            ))}
        </>
    )
});
