import './LanguageFlag.css'
import type { ILanguages } from '../util/language'

import { useCallback } from 'react'
// import { type DetailedHTMLProps, type HTMLAttributes, useCallback } from 'react'

import { ILanguageObject } from '../util/language'
import AutomaticFlag from '../assets/flag_all.png'
import { useTranslation } from 'react-i18next'


type LanguageFlagProps = JSX.IntrinsicElements['div'] & {
  language?: ILanguageObject | null,
  onItemClick?: (id: ILanguages | null) => void,
  active?:   boolean,
  showName?: boolean,
}

export const LanguageFlag = (props: LanguageFlagProps) => {
  const {t} = useTranslation()
  const {
    className,
    language,
    onClick,
    onItemClick,
    active = false,
    showName = false,
    ...divProps } = props

  const languageID = language?.id ?? null

  const handleClick = useCallback((e: any) => {
    onClick?.(e)
    onItemClick?.(languageID)
  }, [languageID, onClick, onItemClick])

  const image = language?.img ?? AutomaticFlag
  const alt   = language?.languageName ?? "Unspecified"
  const name  = language?.languageName ?? t("言語指定なし")

  return (
    <div
      {...divProps}
      className={`language-flag ${active ? ' active' : ''} ${className || ''}`}
      onClick={handleClick}
    >
      <img src={image} alt={alt} />
      {!!showName && (
        <span>{name}</span>
      )}
      {!!active && (
        <i className='active-icon bi bi-check-circle-fill' />
      )}
    </div>
  )
}
