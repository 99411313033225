import { useCallback, useEffect, useRef, useState } from 'react';
import { WaveFile } from 'wavefile';
import { useAzureSpeech } from './useAzureSpeech';
import { LocalStorage } from '../store/LocalStorage';
import MediaStreamRecorder from 'msr';

export const useWavRecordAzure = (roomOwnerId: string, onSetDisabled: (disabled: boolean) => void, onMessage: (result: string, isFinal?: boolean) => void) => {
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [dataURL, setDataURL] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>();

  const [isDeviceConnected, setIsDeviceConnected] = useState<boolean>(false);
  const [isDeviceAnalyzed, setIsDeviceAnalyzed] = useState<boolean>(false);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isMonitoring, setIsMonitoring] = useState<boolean>(false);
  const [isErrored, setIsErrored] = useState<boolean>(false);

  const errorMessageRef = useRef<string>('');
  const errorReportRef = useRef<string>('');
  const streamRef = useRef<MediaStream>();
  const chunksRef = useRef<Blob[]>([]);
  const deviceIdRef = useRef<string>();
  const audioInfoRef = useRef<AudioInfo>();
  const isInitializedRef = useRef<boolean>(false);

  const isStartedRef = useRef<boolean>(isStarted);
  const recorderRef = useRef<MediaStreamRecorder>();
  const dataAvailableLastTimeRef = useRef<Date>(new Date());

  useEffect(() => {
   deviceIdRef.current = deviceId;
  }, [deviceId]);

  useEffect(() => {
    chunksRef.current = chunks;
  }, [chunks]);

  useEffect(() => {
    isStartedRef.current = isStarted;
  }, [isStarted]);

  let transcriptionStop: () => Promise<boolean> = useCallback(() => new Promise(resolve => resolve(true)), []);
  const onError = useCallback((message: string) => {
    if(isStartedRef.current){
      transcriptionStop();
    }
    errorMessageRef.current = '音声処理中にエラーが発生しました。このデバイスからの音声は非対応です。';
    errorReportRef.current = message;
    setIsErrored(true);

  }, [transcriptionStop]);

  // AzureSTT用ソケット通信
  const { start: startAzureSpeech, stop: stopAzureSpeech, initialized } = useAzureSpeech({
    onMessage: onMessage,
    onError: onError,
    needInterim: true,
    roomOwnerId: roomOwnerId,
  });

  // マイクデバイスと接続
  const accessDevice = useCallback(async () => {
    try {
      const constraints: MediaStreamConstraints = {
        audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
        },
        video: false,
      };
      // デバイスIDが指定されている場合は設定
      if (deviceIdRef.current) {
        if (constraints.audio && typeof constraints.audio !== 'boolean') {
          constraints.audio.deviceId = deviceIdRef.current;
        }
      }
      streamRef.current = await navigator.mediaDevices.getUserMedia(constraints);
      setIsDeviceConnected(true);
      return true;
    } catch (e: any) {
      errorMessageRef.current = 'マイクデバイスにアクセス中にエラーが発生しました。';
      errorReportRef.current = e.toString();
      console.error(e);
      setIsErrored(true);
    }
    return false;
  }, []);

  // マイクデバイスの情報を解析
  const analyzeDevice = useCallback(async () => {
    try {
      if (!streamRef.current) {
        errorMessageRef.current = 'マイクデバイスの情報取得中にエラーが発生しました。';
        errorReportRef.current = 'マイクアクセス処理が行われていません';
        setIsErrored(true);
      } else {
        let track = streamRef.current.getAudioTracks()[0];
        setDeviceId(track.getSettings().deviceId);
        setIsDeviceAnalyzed(true);
        return true;
      }
    } catch (e: any) {
      errorMessageRef.current = 'マイクデバイスの情報取得中にエラーが発生しました。';
      errorReportRef.current = e.toString();
      console.error(e);
      setIsErrored(true);
    }
    return false;
  }, []);

  // 音声キャプチャ用
  const onDataAvailableForTranscription = useCallback((data: Blob) => {
    dataAvailableLastTimeRef.current = new Date();
      data.arrayBuffer().then(async (data: ArrayBuffer) => {
        console.debug('onDataAvailableForTranscription', data);
        if (!audioInfoRef.current) {
          const wavHeader = data.slice(0, 44);
          const dataView = new DataView(wavHeader);
          const channel = dataView.getUint16(22, true);
          const bitDepth = dataView.getUint16(34, true);
          const encoding = `LINEAR${bitDepth}`;
          const sampleRate = dataView.getUint32(24, true);
          audioInfoRef.current = { channel, bitDepth, encoding, sampleRate };
        }
        const rawPCM = data.slice(44);
        setChunks(prevChunks => [...prevChunks, new Blob([rawPCM])]);
      });
  }, []);

  // 文字起こし開始
  const transcriptionStart = useCallback(async () => {
    if (!streamRef.current) {
      errorMessageRef.current = '文字起こし開始中にエラーが発生しました。';
      errorReportRef.current = 'マイクデバイスの情報取得処理が行われていません';
      setIsErrored(true);
      return false;
    } else {
      try {
        startAzureSpeech(streamRef.current);
        setIsStarted(true);
        return true;
      } catch (e: any) {
        errorMessageRef.current = '文字起こし中にエラーが発生しました。';
        errorReportRef.current = e.toString();
        console.error(e);
        setIsErrored(true);
        return false;
      }
    }
  }, [startAzureSpeech]);

  // 文字起こし停止
  transcriptionStop = useCallback(async () => {
    if (!streamRef.current) {
      errorMessageRef.current = '文字起こし開始中にエラーが発生しました。';
      errorReportRef.current = 'マイクデバイスの情報取得処理が行われていません';
      setIsErrored(true);
      return false;
    } else {
      try {
        stopAzureSpeech();
        setIsStarted(false);
        return true;
      } catch (e: any) {
        errorMessageRef.current = '文字起こし中にエラーが発生しました。';
        errorReportRef.current = e.toString();
        console.error(e);
        setIsErrored(true);
        return false;
      }
    }
  }, [stopAzureSpeech]);

  // 音声キャプチャ開始
  const monitoringStart = useCallback(() => {
    // chunksをクリアしてメモリを解放する
    setChunks([]);
    dataURL !== '' && URL.revokeObjectURL(dataURL);
    setDataURL('');

    audioInfoRef.current = undefined;
    dataAvailableLastTimeRef.current = new Date();
    recorderRef.current = new MediaStreamRecorder(streamRef.current!);
    recorderRef.current.stream = streamRef.current!;
    recorderRef.current.mimeType = 'audio/wav';
    recorderRef.current.ondataavailable = onDataAvailableForTranscription;
    recorderRef.current.start(2000);

    setIsMonitoring(true);
  }, [dataURL, onDataAvailableForTranscription]);

  // 音声キャプチャ終了（保存）
  const monitoringStop = useCallback(async () => {
    if (!audioInfoRef.current || chunksRef.current.length === 0) {
      errorMessageRef.current = '音声キャプチャ終了・保存中にエラーが発生しました。';
      errorReportRef.current = 'レコーダーが起動していません。';
      setIsErrored(true);
      return false;
    }

    if (recorderRef.current) {
      recorderRef.current.stop();
      recorderRef.current.ondataavailable = (blob) => {
      };
    }

    // 各チャンクのArrayBufferを結合して、PCMデータを作成
    const audioDataArray = await Promise.all(chunksRef.current.map(async (chunk) => {
      return new Int16Array(await chunk.arrayBuffer());
    }));

    // 結合されたPCMデータ
    const totalLength = audioDataArray.reduce((acc, arr) => acc + arr.length, 0);
    const concatenatedData = new Int16Array(totalLength);
    let offset = 0;
    audioDataArray.forEach(data => {
      concatenatedData.set(data, offset);
      offset += data.length;
    });

    // ライブラリを使ってWAVファイルをエンコード
    const wav = new WaveFile();
    const bitDepthCode = `${audioInfoRef.current.bitDepth}`;
    wav.fromScratch(audioInfoRef.current.channel, audioInfoRef.current.sampleRate, bitDepthCode, concatenatedData);

    // ダウンロード処理
    const url = wav.toDataURI();
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'recording.wav';
    document.body.appendChild(a);
    a.click();
    setDataURL(url);

    // chunksをクリアしてメモリを解放する
    setChunks([]);
    setIsMonitoring(false);
    return true;
  }, []);

  // レコーダー停止処理
  const recorderStop = useCallback(async () => {
    if (isStartedRef.current) {
      await transcriptionStop();
    }
    audioInfoRef.current = undefined;
    recorderRef.current = undefined;
    streamRef.current = undefined;
    setIsDeviceConnected(false);
    return true;
  }, [transcriptionStop]);

  const build = useCallback( async (start: boolean, disabled: boolean = false, orderDeviceId?: string) => {
    onSetDisabled(true);
    if(orderDeviceId){
      deviceIdRef.current = orderDeviceId;
    }
    if(isInitializedRef.current){
      await recorderStop();
    }
    isInitializedRef.current = false;
    if (!await accessDevice()) return false;
    if (!await analyzeDevice()) return false;

    if(start){
      await transcriptionStart();
      onSetDisabled(false);
    }else{
      await transcriptionStop();
      !disabled && onSetDisabled(false);
    }
    return true;
  }, [accessDevice, analyzeDevice, onSetDisabled, recorderStop, transcriptionStart, transcriptionStop]);

  const start = useCallback( async () => {
    onSetDisabled(true);
    if(!await transcriptionStart()) return false;
    onSetDisabled(false);
    return true;
  }, [onSetDisabled, transcriptionStart]);

  const stop = useCallback( async (disabled: boolean) => {
    onSetDisabled(true);
    if(!await transcriptionStop()) return false;
    !disabled && onSetDisabled(false);
    return true;
  }, [onSetDisabled, transcriptionStop]);

  useEffect(() => {
    // 設定が完了したら
    if(initialized){
      // レコーダー起動
      (async() => {
        await build(LocalStorage.autoStart, false);
      })();
    }
  }, [build, initialized]);

  return {
    isSocketConnected: initialized,
    isDeviceConnected,
    isDeviceAnalyzed,
    isStarted,
    isMonitoring,
    isErrored,
    isInitialized: isInitializedRef.current,
    build,
    start,
    stop,
    monitoringStart,
    monitoringStop,
    dataURL,
    deviceId,
    errorMessage: errorMessageRef.current,
    errorReport: errorReportRef.current,
  };
};
