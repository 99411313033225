/**
 * Sequential Job Queue.
 * processes each item in the queue sequentially with given function.
 *
 * NOTE: this is basically a glorified "then" chain.
 *
 * @example
 * ```
 * const queue = new JobQueue<string>(async (item: string) => {
 *    // do something with item...
 * });
 *
 * queue.pushJob("jobA");
 * queue.pushJob("jobB");
 * queue.pushJob("jobD");
 *
 * await queue.stopAndJoin();
 * ```
 */

const SYMBOL_STOP_JOB_LOOP = Symbol("JobQueue Should Stop")

type AsyncOperation<T, R> = (item: T) => Promise<R>
type StopSignal = typeof SYMBOL_STOP_JOB_LOOP

export class JobQueue<T, R = void> {
  /**
   * An operation that gets passed through constructor.
   */
  private operation: AsyncOperation<T, R>

  /**
   * Queued up items for job. gets executed sequentially.
   */
  private queuedItem: Array<T | StopSignal>

  /**
   * Promise for Job loop. needs it to await at the stopAndJoin.
   */
  private currentLoop: Promise<void> | null = null
  private stopSignalled: boolean = false

  constructor(
    op: AsyncOperation<T, R>
  ) {
    this.operation = op
    this.queuedItem = []
  }

  async stopAndJoin() {
    this.stopSignalled = true
    if (this.currentLoop) {
      this.queuedItem.push(SYMBOL_STOP_JOB_LOOP)
      await this.currentLoop
    }
  }

  pushJob(item: T) {
    // TODO(fujii): throw exception?
    if (!this.stopSignalled) {
      this.pushJobInternal(item)
    }
  }

  private triggerJobLoop() {
    if (this.currentLoop || this.stopSignalled) {
      return
    }
    this.currentLoop = this.jobLoop()
  }

  private async jobLoop() {
    while(this.queuedItem.length > 0) {
      const item = this.queuedItem.shift()!
      if (item === SYMBOL_STOP_JOB_LOOP) {
        break
      }
      await this.operation(item)
    }
    this.currentLoop = null
  }

  private pushJobInternal(item: T | StopSignal) {
    this.queuedItem.push(item)
    this.triggerJobLoop()
  }
}
