import { LocalStorage } from "../store/LocalStorage";
import React, { useState } from "react";

import { ReactComponent as SendFill } from '../assets/images/send-fill.svg'
import { useTranslation } from "react-i18next";
import advisorMessages from "../store/AdvisorMessages";

const AdvisorForm = ({token, showAdvisorContent, onAdvisorMessageSubmit}: {token: string | null, showAdvisorContent: () => void, onAdvisorMessageSubmit: (content: string) => void}) => {
    const { t } = useTranslation()
    const [submitText, setSubmitText] = useState('');
    const [composing, setComposition] = useState(false);
    const startComposition = () => setComposition(true);
    const endComposition = () => setComposition(false);

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(composing) return;
        if (e.key !== "Enter") return;
        e.preventDefault();
        handleAdvisorMessageSubmit();
    };

    const handleAdvisorMessageSubmit = () => {
        if(submitText === ''){
            alert(t('donutAIへのメッセージを入力してください'));
            return;
        }
        if(advisorMessages.existUnfinishedMessage()){
            return;
        }

        onAdvisorMessageSubmit(submitText);
        setSubmitText('');
    }

    return (
        <>
            <input
                type="text"
                className="form-control footer-input-form-control"
                placeholder={`(${t('β版')}) ${t('donutAIに質問する...')}`}
                disabled={!(token && !LocalStorage.roomClosed)}
                value={submitText}
                onFocus={showAdvisorContent}
                onKeyDown={handleInputKeyDown}
                onCompositionStart={startComposition}
                onCompositionEnd={endComposition}
                onChange={(e) => setSubmitText(e.currentTarget.value)}
            />
            <button
                className='btn footer-input-form-submit-button'
                disabled={!(token && !LocalStorage.roomClosed) || submitText === ''}
                onClick={handleAdvisorMessageSubmit}
            ><SendFill className='send-fill'/></button>
        </>
    )
}
export default AdvisorForm
