import './UserAvatar.css'

import { useEffect, useRef } from 'react'

interface UserAvatarProps {
  text?: string | number;
  picture?: string;
}

const UserAvatar = ({ text, picture }: UserAvatarProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    new window.bootstrap.Tooltip(divRef.current || imgRef.current)
  }, [])

  return picture ? (
    <img ref={imgRef} className='user-avatar' data-bs-toggle='tooltip' data-bs-placement='top' title={`${text}`}
      src={picture} alt={`${text || 'Avatar'}`} />
  ) : (
    <div ref={divRef} className='user-avatar grad-01' data-bs-toggle='tooltip' data-bs-placement='top' title={`${text}`}>
      <span>{(`${text || ''}`.charAt(0) || '').toUpperCase()}</span>
    </div>
  )
}

export default UserAvatar
