
import { useEffect, useState } from 'react'
import { doc, onSnapshot, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Participant } from '../types/participant'
import { LocalStorage } from '../store/LocalStorage'
import useQuery from './useQuery';
import { INITIAL_ID } from "../util/util";

export const generateUserId = (clientId: string, id: number) => {
  return `${clientId}${id.toString().padStart(2, '0')}`;
}

export const findMaxClientId = (participants: Participant[], clientId: string): number => {
  let maxId: number = 0;
  participants.forEach(participant => {
    if (participant.clientId === clientId && participant.id.startsWith(clientId)) {
      const suffix = parseInt(participant.id.slice(clientId.length), 10);
      if (suffix > maxId) {
        maxId = suffix;
      }
    }
  });
  return maxId;
}

export const addParticipant = async (roomOwnerId: string, meetingId: string, clientId: string, userId: string, participantName: string, isAddedByUser: boolean) => {
  const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);

  try {
    const docSnapshot = await getDoc(meetingDocRef);
    if (!docSnapshot.exists()) {
      // If participant data hasn't been created yet,
      // create a new meeting and add the participant on Firestore.
      const newParticipant: Participant = {
        id: userId,
        name: participantName,
        state: 'ONLINE',
        clientId: clientId,
        isAddedByUser: isAddedByUser
      };
      await setDoc(meetingDocRef, {
        participants: [newParticipant]
      });
      console.log(`Meeting created and participant ${participantName} added successfully.`);
    } else {
      // If participant data already exists, update the participant status to ONLINE.
      const existingParticipants: Participant[] = docSnapshot.data().participants || [];
      const participantIndex = existingParticipants.findIndex(participant => participant.id === userId);
      if (participantIndex !== -1) {
        // If user already exists, update the status to ONLINE.
        existingParticipants[participantIndex].state = 'ONLINE'; // 更新: 参加者の状態をONLINEに変更
        existingParticipants[participantIndex].name = participantName; // 更新: 参加者の状態をONLINEに変更
        // Update all participants on same client to ONLINE
        existingParticipants.forEach(participant => {
          if (participant.clientId === clientId) {
            participant.state = "ONLINE"
          }
        })
        await updateDoc(meetingDocRef, {
          participants: existingParticipants
        });
        console.log(`Participant name:${participantName} id: ${userId} clientId: ${clientId} status updated to ONLINE.`);
      } else {
        const newParticipant: Participant = {
          id: userId,
          name: participantName,
          state: 'ONLINE',
          clientId: clientId,
          isAddedByUser: isAddedByUser
        };
        // Update all participants on same client to ONLINE
        existingParticipants.forEach(participant => {
          if (participant.clientId === clientId) {
            participant.state = "ONLINE"
          }
        })
        await updateDoc(meetingDocRef, {
          participants: [...existingParticipants, newParticipant]
        });
        console.log(`Participant name:${participantName} id: ${userId} clientId: ${clientId} added successfully.`);
      }
    }
  } catch (error) {
    console.error("Error adding participant:", error);
  }
};

export const offlineParticipants = async (roomOwnerId: string, meetingId: string, clientId: string) => {
  const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);

  try {
    const docSnapshot = await getDoc(meetingDocRef);
    if (docSnapshot.exists()) {
      const existingParticipants: Participant[] = docSnapshot.data().participants || [];
      const updatedParticipants = existingParticipants.map(participant => {
        if (participant.clientId === clientId) {
          return {...participant, state: 'OFFLINE'};
        }
        return participant;
      });

      await updateDoc(meetingDocRef, {
        participants: updatedParticipants
      });
      console.log(`Participant with ClientID ${clientId} state set to OFFLINE successfully.`);
    } else {
      console.log("Document does not exist!");
    }
  } catch (error) {
    console.error("Error removing participant:", error);
  }
};


export const removeParticipantbyUserID = async (roomOwnerId: string, meetingId: string, userId: string) => {
  const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);

  try {
    const docSnapshot = await getDoc(meetingDocRef);
    if (docSnapshot.exists()) {
      const existingParticipants: Participant[] = docSnapshot.data().participants || [];
      const updatedParticipants = existingParticipants.filter(participant => participant.id !== userId)
      await updateDoc(meetingDocRef, {
        participants: updatedParticipants
      });
      console.log(`Participant with UserID ${userId} was removed successfully.`);
    } else {
      console.log("Document does not exist!");
    }
  } catch (error) {
    console.error("Error removing participant:", error);
  }
};

export const useParticipants = (roomOwnerId: string, meetingId: string): Participant[] => {
  // Manage participants state by using Firestore realtime database.
  // Participants will be updated in real-time when a new participant joins or leaves the meeting.

  const [participants, setParticipants] = useState<Participant[]>([]);
  const name = LocalStorage.name as string
  const userId = LocalStorage.uid as string

  useEffect(() => {
    // roomOwnerId and meetingId is initialized as internal_testing_01 at first.
    // Once user fill userModal, then meetingId will be updated.
    // Add participant to Firestore after meetingId is updated.
    if (roomOwnerId !== INITIAL_ID && meetingId !== INITIAL_ID) {
      addParticipant(roomOwnerId, meetingId, userId, generateUserId(userId, 1), name, false)

      const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);

      const unsubscribe = onSnapshot(meetingDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setParticipants(data.participants || []);
        } else {
          console.log("No such document!");
        }
      }, (error) => {
        console.error("Error fetching participants:", error);
      });

      return () => unsubscribe();  // Cleanup function to remove listener
    }
  }, [roomOwnerId, meetingId, userId, name]);

  const { getSummaryFlag } = useQuery();
  const summaryFlag = getSummaryFlag();

  useEffect(() => {
    // When user closes or reloads page, remove the participant from Firestore.
    const handleUnload = async (event: Event) => {
      if(summaryFlag) {
        return
      }
      await offlineParticipants(roomOwnerId, meetingId, userId);
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [roomOwnerId, meetingId, userId, summaryFlag]);

  return participants;
};
