import './ChatList.css'

import React, {useCallback, useEffect, useRef} from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import LoadingChatRow from "./LoadingChatRow";
import {observer} from "mobx-react-lite";
import 'github-markdown-css/github-markdown.css';

const AdvisorChatRow = observer(({item, last, onUpdate}: { item: AdvisorChatMessage, last: boolean, onUpdate: () => void }) => {
    const ref = useRef<HTMLDivElement>(null);

    const scroll = useCallback(() => {
        if (last) {
            onUpdate();
        }
    }, [last, onUpdate]);

    useEffect(() => {
        scroll();
    }, [item.content, last, scroll]);

    return (
        <div ref={ref}
             className={`chat-row ${item.role === 'user' ? 'mine' : 'other'} row justify-content-${item.role === 'user' ? 'end' : 'start'}${item.role === 'assistant' && item.content === '' ? ' d-none' : ''}`}>
            <div className='col-auto flex-shrink-1'>
                {item.role === 'assistant' && (
                    <img className='user-avatar'
                         data-bs-toggle='tooltip'
                         data-bs-placement='top'
                         title={`donutAI`}
                         src='/icon-donutAI.png' alt={`donutAI`}/>
                )}
                <div className='chat-text-container'>
                    {item.role === 'assistant' && (
                        <div className='ms-1'>
                            <small>donutAI</small>
                        </div>
                    )}
                    <div className='chat-text text-black'>
                        {item.role === 'assistant' ? (
                            <ReactMarkdown
                                className="markdown-body"
                                rehypePlugins={[rehypeRaw]}>{item.content}</ReactMarkdown>
                        ) : (
                            <span>{item.content}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
});

interface AdvisorContentProps {
    items: AdvisorChatMessage[];
    showLoadingChat: boolean;
}

const AdvisorContent = ({items, showLoadingChat}: AdvisorContentProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const onUpdate = useCallback(() => {
        const container = containerRef.current;

        if (container) {
            // コンテナ内の最後の子要素を取得
            const lastElement = container.lastElementChild;

            if (lastElement) {
                // 最後の要素の位置とコンテナの位置を取得
                const lastElementRect = lastElement.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                if (lastElementRect.bottom > containerRect.bottom) {
                    // 最後の要素がコンテナ内に収まっていない場合にスクロール
                    container.scrollTop = container.scrollHeight;
                }
            }
        }
    }, []);

    return (
        <div ref={containerRef} className='advisor-container container py-3'>
            {items.map((message, i) => (
                <AdvisorChatRow key={message.id} item={message} last={items.length - 1 === i} onUpdate={onUpdate} />
            ))}
            {showLoadingChat && (
                <LoadingChatRow mine={false} autoHidden={false}/>
            )}
        </div>
    )
}

export default AdvisorContent
