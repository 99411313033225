import './TosPage.css'

import { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { LocalStorage } from '../store/LocalStorage'
import { useTranslation } from 'react-i18next'

export const TosPage = observer(() => {
  const { t } = useTranslation()
  const [tos, setTos] = useState(false)

  const toggleTos = useCallback(() => {
    setTos((v) => !v)
  }, [])

  const handleStart = useCallback(() => {
    LocalStorage.setValue('tosAccepted', tos)
    if(tos) LocalStorage.setValue('roomState', null)
  }, [tos])

  return (
    <div className='tos-page py-5 px-2 px-sm-3'>
      <div className='logo-bg'>
        <img src='/logo.png' alt='LOGO' />
      </div>
      <div className='container d-flex flex-column' style={{ height: '100dvh', maxWidth: 800, overflowY: 'auto' }}>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <img src='/logo-wide.png' alt='logo-wide' className='logo-wide' />
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <b>donut AI(議事録ver.)</b>
          </div>
        </div>
        <div className='row mt-5 box-01 bg-white p-3 mx-3' style={{ overflowY: 'auto', height: 'calc(100dvh - 400px)' }}>
          <div className='col'>
            <h3 className="mb-4 text-center">{t('利用規約')}</h3>
            <div>
              <p>ドーナッツロボティクス株式会社（以下「当社」といいます。）は、当社が提供する、リアルタイムでの会議の文字起こし、翻訳、議事録の自動生成等を可能にするサービス「donut AI（議事録ver.）」（理由の如何を問わずその名称又は内容が変更された場合は、当該変更後のサービスを含み、以下「本サービス」といいます。）の利用に係る諸条件を定めるために、以下のとおり利用規約（以下「本利用規約」といいます。）を定めます。ユーザー等又は契約事業者（いずれも第2条に定義されます。）は、本利用規約に同意の上、本サービスをご利用ください。</p>

              <h2 className="h4 mb-4">第1条（本サービス）</h2>
              <ol>
                  <li>本サービスは、ユーザー等が、リアルタイムでの会議の文字起こし、翻訳、議事録の自動生成等を行うためのツールです。</li>
                  <li>本サービスには、OpenAI, L.L.C.が提供するChatGPTが組み込まれています。</li>
                  <li>本サービスの内容は、本利用規約その他当社がユーザー等又は契約事業者に対して通知又は公表する書面（電磁的記録を含み、以下同様とします。）に記載される内容に規定します。</li>
              </ol>
              <h2 className="h4 mb-4">第2条（定義）</h2>
              <p>本利用規約において、以下の用語は、当社が別途定義する場合を除き、以下の内容を意味するものとして用います。</p>
              <ol>
                  <li>「外部ユーザー」とは、ユーザーから本サービスの利用の案内を受け、本規約の内容に同意して、本サービスを利用する個人をさします。</li>
                  <li>「契約事業者」とは、本規約の内容に同意して、自己に所属し、又は自己のために稼働する役職員に、ユーザーとして本サービスを利用させる法人若しくは団体又は個人事業主をさします（第7条においては契約事業者になることを検討して手続を行う者を含みます。）。</li>
                  <li>「契約事業者情報」とは、契約事業者が本サービスの利用に際して当社に対して提供する情報をさします。</li>
                  <li>「契約事業者登録」とは、契約事業者が、自己に所属し、又は自己のために稼働する役職員に本サービスを利用させるために必要となる契約事業者のアカウントの登録をさします。</li>
                  <li>「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）又はアイデア、ノウハウ等をいいます。</li>
                  <li>「本規約」とは、本利用規約、ガイドラインその他当社がユーザー等又は契約事業者に通知又は公表する書面（「利用料金及び契約期間について」と題する書面を含みます。）に記載される内容及び本サービスに組み込まれる外部サービスの利用規約等をさし、これらはすべて本規約を構成します。</li>
                  <li>「本登録情報」とは、ユーザーが、本利用登録に際して登録した情報をさします。</li>
                  <li>「本利用登録」とは、本サービスを利用するために必要となるユーザーのアカウントの登録をいい、オンラインその他当社が認める方法による登録をさします。</li>
                  <li>「役職員」とは、役員、従業員（社員、嘱託社員、準社員、臨時社員、受入出向社員、派遣社員等を含みます。）、業務受託者、インターン等（雇用契約、業務委託契約その他契約の形態を問いません。）をさします。</li>
                  <li>「ユーザー」とは、本規約の内容に同意して、本利用登録を行い、本サービスを用いる個人をさします（第4条においてはユーザーになることを検討して手続を行う者を含みます。）。</li>
                  <li>「ユーザー情報」とは、ユーザーに関する情報（氏名、所属、連絡先、参加した会議の日時、会議での発言内容を含みますがこれらに限られません。）をさします。</li>
                  <li>「ユーザー等」とは、ユーザー及び外部ユーザーをさします。</li>
                  <li>「ユーザー等情報」とは、ユーザー等に関する情報（氏名、所属、連絡先、参加した会議の日時、会議での発言内容を含みますがこれらに限られません。）をさします。</li>
              </ol>

              <h2 className="h4 mb-4">第3条（本規約への同意及び本規約の変更）</h2>
              <ol>
                  <li>本規約は、本サービスの利用に係る諸条件を、ユーザー等又は契約事業者と当社との間で定めることを目的とします。</li>
                  <li>ユーザー等及び契約事業者は、本規約に同意し、本規約の定めに従って本サービスを利用しなければなりません。ユーザー又は契約事業者が本規約に同意し、次条に定める本利用登録又は第7条に定める契約事業者登録が完了することにより、ユーザー又は契約事業者と当社との間で本規約を内容とする本サービスの利用契約（以下「本契約」といいます。）が成立します。また、外部ユーザーが本規約に同意することにより、外部ユーザーと当社との間で本契約が成立します。</li>
                  <li>本利用規約の内容と本利用規約以外の本規約の内容との間に矛盾又は抵触がある場合には、本利用規約以外の本規約の定めが本利用規約の定めに優先して適用されるものとします。</li>
                  <li>当社は、ユーザー等が本サービスを実際に利用することによって、ユーザー等又は契約事業者が、本規約について、有効に同意をしたものとみなします。</li>
                  <li>当社は、当社が必要と判断する場合、本サービスの目的の範囲内で本規約を変更できます。当社は、本規約を変更する場合、変更後の本規約の内容及び効力発生日を当社ウェブサイト上での掲示その他の適切な方法によりあらかじめユーザー等又は契約事業者に通知します。ただし、本規約の変更に際して、法令上ユーザー等又は契約事業者の同意が必要となる場合においては、当社は本規約の変更についてユーザー等又は契約事業者の同意（ユーザー等が当該効力発生日後に本サービスを利用した場合に、変更後の本規約に同意したものとみなすことを含みます。）を取得します。</li>
              </ol>

              <h2 className="h4 mb-4">第4条（本利用登録）</h2>
              <ol>
                  <li>ユーザーは、本サービスの利用に際して、本サービスのアカウント（以下「ユーザーアカウント」といいます。）を登録する必要があります。ユーザーは、本利用登録に際して、自らに関する正確かつ最新の情報を提供しなければなりません。</li>
                  <li>当社は、ユーザーの本登録情報その他当社が保有する情報をもとに本利用登録の可否について審査を行います。かかる審査の結果、ユーザーが以下の各号のいずれかに該当すると当社が合理的に判断した場合には、当社は、本利用登録を認めないことがあります。なお、当社はかかる審査の過程及び審査結果の理由について、ユーザーに対してその内容を説明する義務を負いません。</li>
                  <ol>
                      <li>既にほかのユーザーアカウントを登録済みである場合</li>
                      <li>本サービスと同一若しくは類似し又は競合するサービスを提供する事業者である場合</li>
                      <li>第三者に利用させる目的でユーザーになろうとする場合</li>
                      <li>過去に本規約のいずれかの条項に違反したことがある場合</li>
                      <li>実在しないことが疑われる場合又は登録内容に不正確な情報若しくは虚偽と疑われる情報が含まれている場合</li>
                      <li>本サービスの提供又は他のユーザー等若しくは契約事業者による本サービスの利用に対する妨害若しくは支障を及ぼす行為その他不正行為を行ったことが疑われる場合</li>
                      <li>暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ若しくは特殊知能暴力集団等その他これらに準じる者（以下、総称して「暴力団員等」といいます。）に該当すること、暴力団員等が経営を支配していると認められる関係を有すること、暴力団員等が経営に実質的に関与していると認められる関係を有すること、自ら若しくは第三者の不正の利益を図る目的若しくは第三者に損害を加える目的をもってするなど、暴力団員等を利用していると認められる関係を有すること、暴力団員等に対して資金等を提供し、若しくは便宜を供与するなどの関与をしていると認められる関係を有すること、又は暴力団員等と社会的に非難されるべき関係を有すること（以下、上記のすべてを総称して「反社会的勢力」といいます。）が判明した場合</li>
                      <li>その他当社がユーザーによる本サービスの利用が不適当であると判断する場合</li>
                  </ol>
                  <li>ユーザーは、当社が認める場合を除き、本利用登録を重複して行うことができません。</li>
                  <li>当社は、必要に応じて、ユーザーに対して、本利用登録に必要となる一定の情報及び書類等の提出を求めることができ、この場合、ユーザーは、速やかに当該情報及び書類を当社の定める方法で提出するものとします。</li>
                  <li>ユーザーは、本利用登録の内容に変更があった場合には、直ちに当社が定める手続に従い、登録内容を変更しなければならず、常に自身に関する正確かつ最新の情報が登録されている状態を保持しなければなりません。本利用登録の内容に変更があったにもかかわらず、変更を行っていない場合、当社は、登録内容に変更がないものとして取り扱うことができます。変更の届出があった場合でも、変更届出前に行われた取引や各種手続は、変更前の情報に依拠する場合があります。</li>
                  <li>ユーザーは、自身のアカウントに関する情報（以下「ユーザーアカウント情報」といいます。）を自ら管理しなければならず、これらの情報について、第三者との共有、譲渡、貸与その他いかなる処分も行うことができません。また、ユーザーアカウント情報が第三者に漏えいした場合又はそのおそれがある場合（第三者に利用された形跡がある場合を含みますがこれに限られません。）には、速やかに当社に連絡しなければなりません。</li>
                  <li>ユーザーアカウント情報の管理不十分による情報漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任はユーザーが負うものとします。また、ユーザーアカウント情報が不正に利用されたことにより当社に損害が生じた場合、ユーザーは当該損害を賠償するものとします。</li>
                  <li>当社は、ユーザーアカウント情報が利用された場合、当該ユーザーにより本サービスが利用されたものとみなして本規約を適用します。</li>
                  <li>ユーザーが当社に提供した情報が第三者に利用されたこと又は不正確であったことによってユーザーに生じた損害に関して、当社の故意又は過失に起因する場合を除き、当社は責任を負わないものとします。</li>
              </ol>

              <h2 className="h4 mb-4">第5条（当社による本利用登録の抹消等）</h2>
              <p>当社は、ユーザーが以下のいずれかの各号に該当すると合理的な根拠に基づき判断した場合、事前の通知を行うことなく、本利用登録の抹消、本サービスの全部若しくは一部の利用の停止又はユーザー情報の削除その他当社が必要と認める措置を講じることができます。また、当社は、本条に定める措置を講じたユーザーに対して、将来にわたって本サービスその他当社が提供するサービスの利用を禁止することができます。なお、当社は本条に定める措置を講じた理由について、ユーザーに対してその内容を説明する義務を負いません。</p>
              <ol>
                  <li>本規約のいずれかの条項に違反した場合</li>
                  <li>本規約に基づく債務が履行されない場合</li>
                  <li>法令に違反した場合</li>
                  <li>ユーザーの本登録情報に虚偽の事実があることが判明した場合</li>
                  <li>ユーザーの本登録情報が他のユーザーの本登録情報と重複している場合</li>
                  <li>第4条第2項各号のいずれかに該当する場合</li>
                  <li>本規約の定めに従い必要となる手続を行わない場合</li>
                  <li>当社からの問い合わせその他の回答を求める連絡に対して14日間以上応答がない場合</li>
                  <li>支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申し立てがあった場合</li>
                  <li>ユーザーが自ら又は第三者をして、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動若しくは暴力を用いる行為、又は風評を流布し、偽計を用い若しくは威力を用いて、信用を毀損若しくは業務を妨害する行為をした場合</li>
                  <li>その他当社が本サービスを利用させることについて相応しくないと判断した場合</li>
              </ol>

              <h2 className="h4 mb-4">第6条（ユーザーによる本利用登録の抹消）</h2>
              <ol>
                  <li>ユーザーは、当社所定の手続に従い本利用登録を抹消することができます。</li>
                  <li>ユーザーは、本利用登録を抹消するに際して、当社に対して負っている債務の一切について期限の利益を失い、直ちにすべての債務を履行しなければなりません。</li>
                  <li>ユーザーは、本利用登録の抹消後も、当社及びその他の契約事業者や第三者に対する一切の義務及び債務を免れるものではありません。</li>
                  <li>当社は、ユーザーが本利用登録を抹消した後、ユーザー情報の全部又は一部を当社の裁量で消去することができ、ユーザーは、本利用登録の抹消に際して、法令に定めがある場合を除き、当社に対してユーザー情報の提供を求めることができません。</li>
                  <li>ユーザーは、本利用登録の抹消後に、再度本利用登録を希望する際は、再度本規約に従い登録手続を行う必要があります。ユーザーは再度の本利用登録後、本利用登録の抹消前のユーザー情報が引き継がれないことがあることをあらかじめ承諾するものとします。</li>
              </ol>

              <h2 className="h4 mb-4">第7条（契約事業者登録）</h2>
              <ol>
                  <li>契約事業者は、自己に所属し、又は自己のために稼働する役職員に本サービスを利用させるに際して、当社に対して登録を行う必要があります。契約事業者は、契約事業者登録に際して、自らに関する正確かつ最新の情報を提供しなければなりません。</li>
                  <li>当社は、契約事業者の契約事業者情報その他当社が保有する情報をもとに契約事業者登録の可否について審査を行います。かかる審査の結果、契約事業者が以下の各号のいずれかに該当すると当社が合理的に判断した場合には、当社は、契約事業者登録を認めないことがあります。なお、当社はかかる審査の過程及び審査結果の理由について、契約事業者に対してその内容を説明する義務を負いません。</li>
                  <ol>
                      <li>既にほかの契約事業者アカウントを登録済みである場合</li>
                      <li>本サービスと同一若しくは類似し又は競合するサービスを提供する事業者である場合</li>
                      <li>自己に所属し、又は自己のために稼働する役職員以外の第三者に利用させる目的で契約事業者になろうとする場合</li>
                      <li>過去に本規約のいずれかの条項に違反したことがある場合</li>
                      <li>実在しないことが疑われる場合又は登録内容に不正確な情報若しくは虚偽と疑われる情報が含まれている場合</li>
                      <li>本サービスの提供又は他のユーザー等若しくは契約事業者による本サービスの利用に対する妨害若しくは支障を及ぼす行為その他不正行為を行ったことが疑われる場合</li>
                      <li>反社会的勢力に該当することが判明した場合</li>
                      <li>その他当社が契約事業者による本サービスの利用が不適当であると判断する場合</li>
                  </ol>
                  <li>契約事業者は、当社が認める場合を除き、契約事業者登録を重複して行うことができません。</li>
                  <li>当社は、必要に応じて、契約事業者に対して、契約事業者登録に必要となる一定の情報及び書類等の提出を求めることができ、この場合、契約事業者は、速やかに当該情報及び書類を当社の定める方法で提出するものとします。</li>
                  <li>契約事業者は、契約事業者登録の内容に変更があった場合には、直ちに当社が定める手続に従い、登録内容を変更しなければならず、常に自身に関する正確かつ最新の情報が登録されている状態を保持しなければなりません。契約事業者登録の内容に変更があったにもかかわらず、変更を行っていない場合、当社は、登録内容に変更がないものとして取り扱うことができます。変更の届出があった場合でも、変更届出前に行われた取引や各種手続は、変更前の情報に依拠する場合があります。</li>
                  <li>契約事業者は、自身のアカウントに関する情報（以下「契約事業者アカウント情報」といいます。）を自ら管理しなければならず、これらの情報について、第三者との共有、譲渡、貸与その他いかなる処分も行うことができません。また、契約事業者アカウント情報が第三者に漏えいした場合又はそのおそれがある場合（第三者に利用された形跡がある場合を含みますがこれに限られません。）には、速やかに当社に連絡しなければなりません。</li>
                  <li>契約事業者アカウント情報の管理不十分による情報漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任は契約事業者が負うものとします。また、契約事業者アカウント情報が不正に利用されたことにより当社に損害が生じた場合、契約事業者は当該損害を賠償するものとします。</li>
                  <li>当社は、契約事業者アカウント情報が利用された場合、当該契約事業者により本サービスが利用されたものとみなして本規約を適用します。</li>
                  <li>契約事業者が当社に提供した情報が第三者に利用されたこと又は不正確であったことによって契約事業者に生じた損害に関して、当社の故意又は過失に起因する場合を除き、当社は責任を負わないものとします。</li>
              </ol>

              <h2 className="h4 mb-4">第8条（当社による契約事業者登録の抹消等）</h2>
              <p>当社は、契約事業者が以下のいずれかの各号に該当すると合理的な根拠に基づき判断した場合、事前の通知を行うことなく、契約事業者登録の抹消、契約事業者に所属し、又は契約事業者のために稼働する役職員による本サービスの全部若しくは一部の利用の停止又は契約事業者情報の削除その他当社が必要と認める措置を講じることができます。また、当社は、本条に定める措置を講じた契約事業者に対して、将来にわたって本サービスその他当社が提供するサービスの利用を禁止することができます。なお、当社は本条に定める措置を講じた理由について、契約事業者に対してその内容を説明する義務を負いません。</p>
              <ol>
                  <li>本規約のいずれかの条項に違反した場合</li>
                  <li>本規約に基づく債務が履行されない場合</li>
                  <li>法令に違反した場合</li>
                  <li>契約事業者の契約事業者登録に虚偽の事実があることが判明した場合</li>
                  <li>契約事業者の契約事業者登録が他の契約事業者の本登録情報と重複している場合</li>
                  <li>第7条第2項各号のいずれかに該当する場合</li>
                  <li>本規約の定めに従い必要となる手続を行わない場合</li>
                  <li>当社からの問い合わせその他の回答を求める連絡に対して14日間以上応答がない場合</li>
                  <li>支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申し立てがあった場合</li>
                  <li>契約事業者が自ら又は第三者をして、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動若しくは暴力を用いる行為、又は風評を流布し、偽計を用い若しくは威力を用いて、信用を毀損若しくは業務を妨害する行為をした場合</li>
                  <li>その他当社が本サービスを利用させることについて相応しくないと判断した場合</li>
              </ol>

              <h2 className="h4 mb-4">第9条（契約事業者による契約事業者登録の抹消）</h2>
              <ol>
                  <li>契約事業者は、当社所定の手続に従い契約事業者登録を抹消することができます。</li>
                  <li>契約事業者は、契約事業者登録を抹消するに際して、当社に対して負っている債務の一切について期限の利益を失い、直ちにすべての債務を履行しなければなりません。</li>
                  <li>契約事業者は、契約事業者登録の抹消後も、当社及びその他の第三者に対する一切の義務及び債務を免れるものではありません。</li>
                  <li>当社は、契約事業者が契約事業者登録を抹消した後、契約事業者情報の全部又は一部を当社の裁量で消去することができ、契約事業者は、契約事業者登録の抹消に際して、法令に定めがある場合を除き、当社に対して契約事業者情報の提供を求めることができません。</li>
                  <li>契約事業者は、契約事業者登録の抹消後に、再度契約事業者登録を希望する際は、再度本規約に従い登録手続を行う必要があります。契約事業者は再度の契約事業者登録後、契約事業者登録の抹消前の契約事業者情報が引き継がれないことがあることをあらかじめ承諾するものとします。</li>
              </ol>

              <h2 className="h4 mb-4">第10条（禁止事項）</h2>
              <p>ユーザー等は、本サービスの利用に際して、以下の各号のいずれかに該当する行為をすることはできません。また、契約事業者は、本サービスの利用に際して、自ら又は自らに所属し、若しくは自らのために稼働する役職員であるユーザーをして、以下の各号のいずれかに該当する行為をすることはできません。</p>
              <ol>
                  <li>本規約に違反する行為</li>
                  <li>法令に違反する行為</li>
                  <li>犯罪行為に関連する行為</li>
                  <li>公の秩序又は善良な風俗を害するおそれのある行為</li>
                  <li>当社又は第三者になりすます行為（他のユーザーのユーザーアカウント情報を利用する行為を含みます。）</li>
                  <li>虚偽の情報を流布する行為</li>
                  <li>当社、他のユーザー等又は第三者の情報を改ざん、消去する行為</li>
                  <li>コンピュータ・ウィルスその他の有害なプログラムを流布する行為</li>
                  <li>当社、他のユーザー等又は第三者の知的財産権、名誉権、プライバシーその他の権利若しくは利益を侵害するおそれのある行為</li>
                  <li>過度に暴力的若しくは残虐な表現、過度にわいせつな表現、差別を助長する表現、自殺若しくは自傷行為を助長する表現、薬物の不適切な利用を誘引若しくは助長する表現又は他人に不快感を与える表現その他反社会的な内容を含む表現を送信する行為</li>
                  <li>当社が事前に許諾しない本サービスを利用した営業、宣伝、広告又は勧誘行為</li>
                  <li>当社が事前に許諾しない本サービスの全部又は一部の使用、複製、送信、翻訳、翻案その他の二次利用又は複製行為</li>
                  <li>本サービスのサーバ又はネットワークシステムに過度な負担を与える行為その他の支障を与える行為</li>
                  <li>当社が提供するソフトウェアその他のシステムに対する逆コンパイル、逆アセンブル、リバースエンジニアリングその他の解析行為</li>
                  <li>本サービスのサーバ又はネットワークシステムへの不正アクセスその他当社による本サービスの運営又は他のユーザー等による本サービスの利用への妨害若しくは支障を与える行為</li>
                  <li>不正な目的をもって本サービスを利用する行為</li>
                  <li>反社会的勢力に対する利益供与その他の協力行為</li>
                  <li>当社が本サービス上に掲載する本サービスの利用に関するガイドライン等に抵触する行為</li>
                  <li>前各号のいずれかに該当する行為を直接又は間接に惹起、助長若しくは容易にする行為</li>
                  <li>前各号に定める行為のほか当社が本サービスの目的に照らして合理的な根拠に基づき不適当と判断した行為</li>
              </ol>

              <h2 className="h4 mb-4">第11条（ユーザー情報等の取扱い）</h2>
              <ol>
                  <li>当社は、ユーザー等情報及び契約事業者情報について、本利用規約のほか当社が別途定めるプライバシーポリシーの定めに従い取り扱います。</li>
                  <li>ユーザー等及び契約事業者は、本サービスの利用に先立ち、プライバシーポリシーを確認し、同意した上で、本サービスを利用しなければなりません。</li>
                  <li>ユーザー等は、当社が本サービスの提供にあたり、ユーザー等により行われる会議の日時、会議における発言内容、参加者その他の会議に関する一切の情報を、本サービスの提供（会議内容の文字起こし、翻訳、議事録の自動生成を含みますがこれらに限られません。）及び本サービスの改善並びに当社の製品又はサービスの研究、開発等の目的のため、当社が知得し、利用することに同意するものとします。</li>
              </ol>

              <h2 className="h4 mb-4">第12条（本サービスの利用料金）</h2>
              <ol>
                  <li>ユーザーは、当社に対し、本サービスの利用の対価として、当社が別途定める利用料金を当社が定める方法で支払います。</li>
                  <li>前項の規定にかかわらず、契約事業者が自らに所属し、又は自らのために稼働する役職員にユーザーとして本サービスを利用させる場合、契約事業者は、当社に対し、本サービスの利用の対価として、当社が別途定める利用料金を当社が定める方法で支払います。</li>
              </ol>

              <h2 className="h4 mb-4">第13条（情報提供）</h2>
              <ol>
                  <li>当社は、当社において必要と判断したときは、ユーザー等又は契約事業者に対し、本サービスの利用状況に関する一切の事項についての情報の提供及びこれらに関する資料の提出を求めることができるものとします。</li>
                  <li>当社がユーザー等又は契約事業者に対して前項に定める請求を行ったときは、ユーザー等又は契約事業者は、当社が別途定める期間内に、当社が指定する方法に従って、当社が求める情報の提供及び資料の提出を行わなければなりません。この場合、当該情報の提供及び資料の提出に係る費用はユーザー等又は契約事業者が負担します。</li>
              </ol>

              <h2 className="h4 mb-4">第14条（本サービスの停止）</h2>
              <p>当社は、以下の各号のいずれかに該当する場合には、ユーザー等又は契約事業者に事前に通知することなく本サービスの全部又は一部の提供を停止若しくは中断することができます。</p>
              <ol>
                  <li>本サービスの提供に必要となるコンピュータ、サーバ、通信回線その他の設備（以下「本サービス運営設備」といいます。）の故障、障害、誤操作の発生、不正アクセス、過度なアクセスの集中その他の理由により本サービスの提供ができなくなった場合</li>
                  <li>本サービス運営設備の点検、保守、修理又は変更を行う場合</li>
                  <li>地震、落雷、火災、風水害、停電その他天災地変、戦争、テロ、暴動、伝染病等の不可抗力により本サービスの提供ができなくなった場合</li>
                  <li>法令又はこれに基づく措置により本サービスの提供ができなくなった場合</li>
                  <li>本サービスの提供に必要となる第三者により提供されるサービスの全部又は一部の提供が一時的に停止又は中断された場合</li>
                  <li>電気通信事業者の役務が提供されない場合</li>
                  <li>その他当社が停止又は中断を必要と判断した場合</li>
              </ol>
              <h2 className="h4 mb-4">第15条（本サービスの変更及び終了）</h2>
              <ol>
                  <li>当社は、当社の都合及び裁量により、本サービスの内容を変更又は本サービスの提供を終了することができます。</li>
                  <li>当社は、本サービスの提供を終了する場合、ユーザー等又は契約事業者に対してあらかじめ通知します。</li>
              </ol>
              <h2 className="h4 mb-4">第16条（本契約の終了）</h2>
              <ol>
                  <li>本利用登録の抹消、契約事業者登録の抹消、本サービスの終了その他事由により、当社とユーザー又は契約事業者との間の本契約が終了した場合、当社は、ユーザー又は契約事業者から既に受領した金銭を一切返却しません。</li>
                  <li>本契約終了時点において、当社がユーザー等又は契約事業者に対して債権を有する場合、ユーザー等又は契約事業者は当該債権につき期限の利益を喪失し、速やかに当社の請求に従って残債務を支払わなければなりません。</li>
              </ol>
              <h2 className="h4 mb-4">第17条（権利の帰属）</h2>
              <ol>
                  <li>本サービスに関する権利は、すべて当社又は当社に権利を許諾している第三者に帰属しています。ユーザー等及び契約事業者は、本サービスに関して、一切の権利を取得することはなく、権利者の許可なく、権利者の権利を侵害する一切の行為をしてはなりません。本利用規約に基づく本サービスの利用許諾は、本サービスに関する当社又は権利を有する第三者の権利に関する使用許諾を意味するものではありません。</li>
                  <li>ユーザー等及び契約事業者は、当社及び当社から権利を承継し又は許諾された者に対し、著作者人格権を行使しないことに同意します。</li>
              </ol>
              <h2 className="h4 mb-4">第18条（非保証及びユーザー等の責任）</h2>
              <ol>
                  <li>当社は、本サービスの内容、品質、機能、商品的価値、正確性及び有用性並びに本サービスの安定的継続的な提供について、一切保証しません。</li>
                  <li>当社は、すべてのユーザー等又は契約事業者の端末、対応ブラウザ、ウェブサイトに本サービスとの適合性が存することを保証しないものとし、ユーザー等又は契約事業者は、端末、対応ブラウザ、ウェブサイトの仕様によっては、本サービスを利用できない場合があることをあらかじめ承諾するものとします。</li>
                  <li>当社は、ユーザー等又は契約事業者に対し、情報提供等を行うことがありますが、当該情報を利用して行われる行為について、当社は一切の責任を負いません。</li>
                  <li>当社は、本サービスに関連するコンテンツの中に、コンピュータ・ウィルス等有害なものが含まれていないことに関して、一切保証しません。</li>
                  <li>ユーザー等が本サービスを利用するに当たり、ユーザー等が使用するネットワーク、コンピュータ、ソフトウェア等のユーザー等側の利用環境はユーザー等自身の負担で用意、整備するものとします。ユーザー等の利用環境の整備等、及びユーザー等の利用環境等によってユーザー等又は契約事業者に生じた損害等について、当社は一切の責任を負いません。</li>
                  <li>本サービスに起因又は関連してユーザー等又は契約事業者と、ユーザー等、契約事業者又は第三者との間でトラブルが発生した場合には、ユーザー等又は契約事業者が自らの責任においてこれを解決し、当該解決に要した費用はユーザー等又は契約事業者が負担し当社は一切の責任を負いません。</li>
              </ol>
              <h2 className="h4 mb-4">第19条（損害賠償）</h2>
              <ol>
                  <li>ユーザー等又は契約事業者が本規約に違反した場合、故意過失を問わず、当該ユーザー等又は契約事業者は、当該違反により損害を受けた当該ユーザー等又は契約事業者以外のユーザー等又は契約事業者その他の第三者及び当社に対する損害賠償責任その他一切の責任を負います。</li>
                  <li>当社は、当社の故意又は重過失により、本サービスに起因又は関連してユーザー等又は契約事業者に損害が生じた場合、債務不履行、不法行為その他の請求の原因を問わず、ユーザー等又は契約事業者に現実に生じた直接かつ通常の範囲の損害についてのみ責任を負い、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとし、かつ、その賠償額は本サービスの有料プランの月額利用額（1か月分）を上限とします。</li>
              </ol>
              <h2 className="h4 mb-4">第20条（遅延損害金）</h2>
              <p>ユーザー又は契約事業者が、当社の指定する期日までに当社に対する金銭債務の支払をしなかった場合は、未払額に遅延損害金を付して支払うものとします。この場合の遅延損害金は、支払期日の翌日を起算日とし、年14.6%の日割計算で算出することとします。</p>

              <h2 className="h4 mb-4">第21条（有効期間）</h2>
              <p>本契約は、第3条第2項に定める日に効力を生じ、別途合意された契約期間の満了日、本利用登録若しくは契約事業者登録が抹消された日又は本サービスの提供が終了した日のうち最も早い日まで、当社とユーザー等又は契約事業者との間で有効に存続するものとします。</p>

              <h2 className="h4 mb-4">第22条（反社会的勢力の排除）</h2>
              <ol>
                  <li>当社及びユーザー等又は契約事業者は、それぞれ相手方に対し、自ら又はその代表者、責任者、若しくは実質的に経営権を有する者が、反社会的勢力に該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。</li>
                  <li>当社及びユーザー等又は契約事業者は、それぞれ相手方に対し、次の各号に掲げる行為を行わないことを確約します。</li>
                  <ol>
                      <li>暴力的な手法による要求をすること</li>
                      <li>法的な責任を超えた不当な要求をすること</li>
                      <li>取引に関して、脅迫的な言動をし、又は暴力を用いること</li>
                      <li>風説を流布し、偽計を用い又は威力を用いて当社、他のユーザー等その他第三者の信用を毀損し、又は当社、他のユーザー等その他第三者の業務を妨害すること</li>
                      <li>反社会的勢力に対して名目の如何を問わず資金提供を行うこと</li>
                      <li>反社会的勢力に自己の名義を利用させること</li>
                      <li>その他前各号に準ずること</li>
                  </ol>
                  <li>当社及びユーザー等又は契約事業者は、自らが第1項に該当し、若しくは前項各号に該当する行為を行い、又はそのおそれがあることが判明した場合には、直ちに相手方にその旨を通知しなければならないものとします。</li>
                  <li>当社及びユーザー等又は契約事業者は、相手方が前各号に違反した場合には、何らの催告なしに直ちに、当社とユーザー等又は契約事業者間で締結した一切の契約を解除することができます。</li>
                  <li>当社及びユーザー等又は契約事業者は、前項に基づき契約を解除したことにより、相手方に発生した損害について、一切の賠償責任を負いません。</li>
              </ol>
              <h2 className="h4 mb-4">第23条（通知）</h2>
              <ol>
                  <li>本サービスに関する問い合わせその他ユーザー等又は契約事業者から当社に対する連絡又は通知、及び当社からのユーザー等又は契約事業者に対する連絡又は通知は、本サービスにおける掲示その他当社が適当と判断する方法により行います。</li>
                  <li>当社が、 ユーザーの本登録情報又は契約事業者の契約事業者情報に含まれるメールアドレスその他の連絡先に連絡又は通知を行った場合、ユーザー又は契約事業者は当該連絡又は通知を受領したものとみなします。</li>
              </ol>
              <h2 className="h4 mb-4">第24条（秘密保持）</h2>
              <p>ユーザー等及び契約事業者は、本サービスに関連して当社がユーザー等又は契約事業者に対して提供した情報又はユーザー等又は契約事業者が本サービスを通じて取得した情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。</p>

              <h2 className="h4 mb-4">第25条（地位の譲渡）</h2>
              <ol>
                  <li>ユーザー等又は契約事業者は、当社の書面による事前の承諾なく、本規約に基づく契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。</li>
                  <li>当社が本サービスに係る事業を第三者に譲渡する場合（事業譲渡、会社分割その他本サービスの主体が移転する一切の場合を含みます。）には、当社は、当該事業の譲渡に伴い、本規約に基づく契約上の地位、本規約に基づく権利及び義務並びに本利用登録に伴いユーザーが登録した情報及びユーザー等又は契約事業者による本サービスの利用に係る情報を当該事業の譲受人に譲渡することができるものとし、ユーザー等及び契約事業者は、かかる譲渡につき本利用規約への同意に基づきあらかじめ承諾するものとします。</li>
              </ol>
              <h2 className="h4 mb-4">第26条（分離可能性）</h2>
              <p>本規約のいずれかの条項又はその一部が、法令等により無効と判断された場合であっても、本規約の残りの規定及び一部が無効と判断された規定の残りの部分は継続して完全かつ有効に存続します。</p>

              <h2 className="h4 mb-4">第27条（存続規定）</h2>
              <p>第4条第9項、第7条第9項、第6条第2項ないし第5項、第9条第2項ないし第5項、第10条、第11条、第12条、第16条ないし第28条の規定は、本契約の終了後も有効に存続するものとします。</p>

              <h2 className="h4 mb-4">第28条（準拠法及び管轄裁判所）</h2>
              <ol>
                  <li>本規約の準拠法は日本法とします。</li>
                  <li>本サービスに起因又は関連してユーザー等又は契約事業者と当社の間で生じた一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
              </ol>
              <p>2023年8月31日　制定（初版）</p>
            </div>

            <h3 className="mb-4 text-center">プライバシーポリシー</h3>
            <p>ドーナッツロボティクス株式会社（以下「当社」といいます。）は、当社が提供する、リアルタイムでの会議の文字起こし、翻訳、議事録の自動生成等を可能にするサービス「donut AI(議事録ver.)」（以下「本サービス」といいます。）を利用するユーザー、外部ユーザー及び契約事業者（いずれも本サービスの利用規約第2条に定義され、以下「利用者」と総称します。）の情報について、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）のとおり取り扱います。<br />
              本サービスの利用を希望する皆様は、本プライバシーポリシーに同意の上、本サービスをご利用ください。なお、EUからご利用される場合には、「EU Customers Privacy Policy」をご確認ください。<br />
              なお、本文中の用語の定義は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）及び関係法令によります。</p>

            <h2 className="h4 mb-4">1.個人情報の取得</h2>
            <p>当社は、個人情報（個人情報保護法第2条第1項により定義される個人情報を意味します。以下同様です。）を含め以下の情報を業務上必要な範囲において適法かつ適正な手段により取得します。当社が取得する個人情報には、その収集方法に応じて、以下の情報が含まれます。</p>
            <ol>
              <li>ユーザーに関する情報</li>
              <ol>
                <li>ユーザーの本サービスの登録に際して取得する情報</li>
                <ul>
                  <li>ユーザーの氏名、性別、生年月日、住所、事業内容その他のユーザーに関する基本情報</li>
                  <li>ユーザーの電話番号、メールアドレスその他の連絡先に関する情報</li>
                  <li>ユーザーが本サービスにおいて利用するID、パスワード等のログイン情報</li>
                  <li>ユーザーの銀行口座情報その他の決済に関する情報</li>
                  <li>当社が協業する事業者の製品を利用している場合、当該製品の製造番号</li>
                </ul>
                <li>ユーザーによる本サービスの利用に関連して取得する情報</li>
                <ul>
                  <li>ユーザーにより行われる会議の日時、会議における発言内容、参加者その他の会議に関する一切の情報</li>
                  <li>ユーザーが使用する言語に関する情報</li>
                  <li>ユーザーの当社へのお問い合わせ、ご連絡等に関する情報</li>
                </ul>
                <li>ユーザーによる本サービスの利用状況に関する情報</li>
                <ul>
                  <li>本サービスのアクセス履歴、利用履歴に関する情報</li>
                  <li>Cookie情報、IPアドレス、端末情報、OS情報、ブラウザ情報その他ユーザーの通信に関する情報</li>
                </ul>
              </ol>
              <li>契約事業者に関する情報</li>
              <ol>
                <li>契約事業者の本サービスの登録に際して取得する情報</li>
                <ul>
                  <li>契約事業者の名称（契約事業者が個人の場合は氏名又は屋号、法人の場合は商号を意味します。）、住所、事業内容その他の契約事業者に関する基本情報</li>
                  <li>（契約事業者が法人の場合）代表者の氏名、生年月日、性別、住所その他の特定の個人を識別することができる情報</li>
                  <li>契約事業者の電話番号、メールアドレスその他の連絡先に関する情報</li>
                  <li>契約事業者の銀行口座情報その他の決済に関する情報</li>
                </ul>
                <li>契約事業者による本サービスの利用に関連して取得する情報</li>
                <ul>
                  <li>契約事業者の当社へのお問い合わせ、ご連絡等に関する情報</li>
                </ul>
              </ol>
              <li>外部ユーザーに関する情報</li>
              <ol>
                <li>外部ユーザーによる本サービスの利用に関連して取得する情報</li>
                <ul>
                  <li>外部ユーザーが招待され参加した会議の日時、会議における発言内容、参加者その他の会議に関する一切の情報</li>
                  <li>外部ユーザーが使用する言語に関する情報</li>
                  <li>外部ユーザーの当社へのお問い合わせ、ご連絡等に関する情報</li>
                </ul>
                <li>外部ユーザーによる本サービスの利用状況に関する情報</li>
                <ul>
                  <li>本サービスのアクセス履歴、利用履歴に関する情報</li>
                  <li>Cookie情報、IPアドレス、端末情報、OS情報、ブラウザ情報その他外部ユーザーの通信に関する情報</li>
                </ul>
              </ol>
            </ol>

            <h2 className="h4 mb-4">2.個人情報の利用目的</h2>
            <p>当社は、個人情報について、以下の利用目的の範囲内で利用し、利用者の同意がある場合又は法令で認められている場合を除き、他の目的で利用しません。</p>
            <ol>
              <li>本サービスへの登録、本サービス利用時の本人認証その他の利用者の管理</li>
              <li>本サービスの提供（会議における発言内容のテキスト化を含みますが、これに限られません。）</li>
              <li>本サービスの対価の請求</li>
              <li>不正行為等の防止及び対応</li>
              <li>本サービスの保守、運用、管理等</li>
              <li>マーケティングデータの調査及び分析並びにマーケティング施策の検討及び実施</li>
              <li>当社又は第三者の媒体における、利用者の属性・利用履歴の分析に基づく興味及び関心等にあわせた、当社又は第三者の商品等及びサービスの提供、勧誘、広告その他のマーケティング</li>
              <li>キャンペーン、アンケートの実施</li>
              <li>本サービスの改善</li>
              <li>当社の新商品・サービスの企画、開発等</li>
              <li>お問い合わせ対応及び利用者に対する連絡</li>
              <li>本プライバシーポリシー記載の方法による第三者に対する提供</li>
            </ol>

            <h2 className="h4 mb-4">3.個人情報の処理に関する委託</h2>
            <p>当社は、業務を円滑に進め、また、より良いサービスを提供するため、「2．個人情報の利用目的」の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を外部に委託する場合があります。ただし、委託する個人情報は、委託する業務を遂行するのに必要最小限の情報に限定します。</p>

            <h2 className="h4 mb-4">4.個人情報の第三者提供</h2>
            <p>当社は、「3. 個人情報の処理に関する委託」若しくは本条に定める場合、利用者の事前同意がある場合又は法令で認められている場合その他以下に定める場合を除き、個人情報を第三者に提供いたしません。</p>
            <ol>
              <li>代金決済、利用者から当社に対する問い合わせへの対応その他本サービスの運営及び提供のために、決済代行業者、業務委託先その他の第三者に提供する場合</li>
              <li>利用者が本サービスの利用規約、プライバシーポリシーその他当社との間で契約した契約上の定めに違反し、当社の権利、財産や本サービス等を保護するために、個人情報の提供が必要と判断した場合</li>
              <li>本サービスの利用者その他第三者の権利や利益等を守るため又は第三者との紛争を解決するために個人情報の提供が必要と判断した場合</li>
              <li>当社の株式の譲渡、合併等の組織再編行為、事業譲渡その他これらに類する取引の実行及びこれに先立って行われる調査や交渉に際して個人情報を開示する場合</li>
              <li>当社や第三者の広告の配信又は表示のために利用者に関する情報の提供が必要と判断した場合</li>
              <li>当社や第三者の提供するサービス内に本サービスの情報を配信若しくは表示するために利用者に関する情報の提供が必要と判断した場合</li>
              <li>「6．Cookieその他の技術の利用」に従い情報収集モジュール提供者へ個人情報が提供される場合</li>
            </ol>
            <p>当社は、次の外国にある第三者に取得情報を提供する場合があります。この場合に参考となるべき国別の情報は、現時点において、次に掲げるとおりです。</p>
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">国名</th>
                  <th className="text-center">当該外国の個人情報保護制度</th>
                  <th className="text-center">第三者が講ずる個人情報保護措置</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>アメリカ合衆国</td>
                  <td>
                    個人情報保護委員会が提供する情報を以下のリンクからご確認ください。<br />
                    https://www.ppc.go.jp/files/pdf/USA_report.pdf
                  </td>
                  <td>提供先の第三者は、OECDプライバシーガイドライン8原則に対応する措置を講じております。</td>
                </tr>
                <tr>
                  <td>シンガポール</td>
                  <td>
                    個人情報保護委員会が提供する情報を以下のリンクからご確認ください。 <br />
                    https://www.ppc.go.jp/files/pdf/singapore_report.pdf
                  </td>
                  <td>提供先の第三者は、OECDプライバシーガイドライン8原則に対応する措置を講じております。</td>
                </tr>
              </tbody>
            </table>

            <h2 className="h4 mb-4">5.個人情報の管理</h2>
            <ol>
              <li>内容の正確性の確保<br />
                当社は、個人情報につき、「2．個人情報の利用目的」の達成に必要な範囲内において、正確かつ最新の内容に保つとともに、利用する必要がなくなったときは当該個人情報を消去するよう努めます。
              </li>
              <li>安全管理措置<br />
                当社は、個人情報の漏えい、滅失又は毀損の防止その他の安全管理のために必要かつ適切な措置を行います。当社における安全管理措置の内容については、「8.お問い合わせ窓口」までお問い合わせください。
              </li>
              <li>従業者の監督<br />
                当社は、個人情報を従業者に取り扱わせるにあたっては、個人情報の適正な取扱いを周知徹底するとともに適正な教育を行い、必要かつ適正な監督を行います。
              </li>
              <li>委託先の監督<br />
                当社は、個人情報の取扱いを委託する場合には、委託先には適切な安全管理措置を講じている協力会社を選定し、委託先に対し必要かつ適切な監督を行います。
              </li>
            </ol>

            <h2 className="h4 mb-4">6.Cookieその他の技術の利用</h2>
            <ol>
              <li>当社は、本サービスの提供に際して、Cookie及びこれに類する技術を利用することがあります。当社は、利用者のCookie情報、利用者が閲覧した広告及びウェブページ並びに利用者の利用環境等の情報を自動的に利用者のブラウザから取得し、サーバに記録することで、本サービスの利用状況等を把握し、本サービスの品質を向上させるためにこれらの情報を用いることがあります。</li>
              <li>Cookieを無効化したい利用者は、ウェブブラウザの設定を変更することによりCookieを無効化することができます。ただし、Cookieを無効化すると、本サービスを正常に利用できない場合があります。</li>
              <li>当社は、第1項に定めるほか、Google LLCが提供するサービス「Googleアナリティクス」を利用して、本サービスの利用に際して利用者が利用する通信端末に関する情報（通信端末の個体識別番号、IPアドレス、位置情報、Cookie情報、利用ログ等）を取得し、利用者に関する情報の解析並びにその結果を用いた本サービスの利用状況の計測、分析及び本サービスの改善を目的として、これらの情報を利用することがあります。Google analyticsの詳細及びオプトアウト手続については、「Google アナリティクス利用規約」及び「Google のサービスを使用するサイトやアプリから収集した情報の Google による使用」をご確認ください。</li>
            </ol>

            <h2 className="h4 mb-4">7.保有個人データに関する受付</h2>
            <p>当社は利用者からの個人情報保護法に基づく保有個人データの利用目的の通知、保有個人データ若しくは第三者提供記録の開示、保有個人データの訂正・追加・削除又は保有個人データの利用停止・第三者提供の停止のご請求に適切に対応いたします。手続等については、「8.お問い合わせ窓口」までお問い合わせください。</p>

            <h2 className="h4 mb-4">8.お問い合わせ窓口</h2>
            <p>本サービスの個人情報の取扱いに関するご質問、ご照会、苦情等は、以下の窓口までご連絡ください。<br />
              〒105-6490 <br />
              東京都港区虎ノ門1丁目17番1号  虎ノ門ヒルズビジネスタワー15階<br />
              ドーナッツロボティクス株式会社<br />
              メール：info@donutrobotics.com<br /><br />


              2023年8月31日制定<br /><br />

              東京都港区虎ノ門1丁目17番1号<br />
              虎ノ門ヒルズビジネスタワー15階<br />
              ドーナッツロボティクス株式会社<br />
              代表取締役　小野泰助
            </p>



            <h3 className="mb-4 text-center mt-5">EU Customers Privacy Policy</h3>
            <p>This EU Customers Privacy Policy shall be read and construed in conjunction with the other provisions of our Privacy Policy. Donut Robotics Co., Ltd. ("We", " us", or " our") process any personal data of Customers in the European Union, Iceland, Lichtenstein and Norway in accordance with the General Data Protection Regulation (Regulation (EU) 2016/679 of 27 April 2016) (hereinafter referred to as " GDPR"). For the purposes of this EU Customers Privacy Policy, the terms defined under the GDPR have the meanings ascribed to them under the GDPR.</p>
            <h2 className="h4 mb-4">1. Processing purposes and legal justification of the processing</h2>
            <p>We generally use the following legal justifications: (i) You have given your consent to the processing (Article 6(1)(a) GDPR; "Consent Justification"), and (ii) the processing is necessary for (a) the performance of a contract to which the data subject is party (Article 6(1)(b) GDPR; "Contract Justification"), (b) compliance with a legal obligation (Article 6(1)(c) GDPR; "Legal Obligation Justification") and (c) realizing a legitimate interest (Article 6(1)(f) GDPR; "Legitimate Interest Justification").</p>
            <p>We process your personal data for the purposes and on the legal basis described below.</p>
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">Purposes of processing</th>
                  <th className="text-center">Legal basis of processing</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Article 2 (1), (2), (3), (5) and (11) of the Privacy Policy
                    Article 4 (1) of the Privacy Policy</td>
                  <td>Contract Justification</td>
                </tr>
                <tr>
                  <td>Article 2 (4) of the Privacy Policy
                    Article 4 (2) and (3) of the Privacy Policy</td>
                  <td>Legitimate Interest Justification. Our legitimate interest is to detect and prevent any possible fraud.</td>
                </tr>
                <tr>
                  <td>Article 2 (6), (7), (8), (9) and (10) of the Privacy Policy
                    Article 4 (4), (5), (6) and (7) of the Privacy Policy</td>
                  <td>Consent Justification</td>
                </tr>
              </tbody>
            </table>


            <h2 className="h4 mb-4">2. Disclosure of personal data</h2>
            <ol>
              <li>Your personal data will be processed by our duly authorized personnel in accordance with the GDPR. We may engage third parties as processors on our behalf and disclose your personal data to them. Those third parties include service providers that convert voice data into text, as well as third parties as stated in Article 4 (1), (5), (6) and (7) of the Privacy Policy.</li>
              <li>We may also disclose your personal data (i) where required by the applicable laws or regulations, (ii) in connection with corporate activities such as mergers, and (iii) when defending ourselves in an administrative or judicial proceeding.</li>
            </ol>


            <h2 className="h4 mb-4">3. Transfer to third countries outside the EEA</h2>
            <p>Your personal data may be transferred to third countries outside the European Economic Area with or without an adequate decision by the European Commission. In the case of any transfer of personal data to a country that does not have an adequate level of data protection in light of the standards under the GDPR, we will ensure such transfer will be made through the Standard Contractual Clauses adopted in accordance with the GDPR.</p>


            <h2 className="h4 mb-4">4. Retention periods for and deletion of Your Data</h2>
            <ol>
              <li>Your personal data processed for the purposes hereunder will be stored only to the extent necessary. If a judicial action is initiated, your personal data may be stored until the end of such action, including any potential periods for appeal, and will then be deleted or archived as permitted by applicable law.</li>
              <li>In principle, we will retain your personal data only as long as required or permitted by applicable law. Afterwards, we will remove your personal data from our systems and records and/or take steps to properly anonymize it so that you can no longer be identified from it.</li>
            </ol>

            <h2 className="h4 mb-4">5. Your statutory rights</h2>
            <p>Under the conditions set out under applicable law (i.e., the GDPR), you have the following rights:</p>
            <ol>
              <li>Right of access: You have the right to obtain confirmation from us as to whether or not personal data concerning you is being processed, and, where that is the case, to request access to said personal data. The access information includes – inter alia – the purposes of the processing, the categories of personal data concerned, and the recipients or categories of recipients to whom the personal data have been or will be disclosed. You have the right to obtain a copy of the personal data undergoing processing. For additional copies requested by you, we may charge a reasonable fee based on administrative costs.</li>
              <li>Right to rectification: You have the right to obtain from us the rectification of inaccurate personal data concerning you. Depending on the purposes of the processing, you have the right to have incomplete personal data completed, including by means of providing a supplementary statement.</li>
              <li>Right to erasure (right to be forgotten): You have the right to ask us to erase your personal data.</li>
              <li>Right to restriction of processing: You have the right to request the restriction of processing your personal data. In this case, the respective data will be marked and may only be processed by us for certain purposes.</li>
              <li>Right to data portability: You have the right to receive the personal data concerning you which you have provided to us in a structured, commonly used and machine-readable format and you have the right to transmit said personal data to another entity without hindrance from us.</li>
              <li>Right to withdraw your consent: If you have given your consent regarding certain types of processing activities, you can withdraw this consent at any time with future effect. Such a withdrawal will not affect the lawfulness of the processing prior to the consent withdrawal.</li>
              <li>Right to object:
                <p className="border border-secondary p-2">You have the right to object at any time, on grounds relating to your particular situation, to the processing of your personal data by us and we can be required to no longer process your personal data. If you have a right to object and you exercise this right, your personal data will no longer be processed for such purposes by us. Exercising this right will not incur any costs. Such a right to object may not exist if the processing of your personal data is necessary to take steps prior to entering into a contract or to perform a contract already concluded.</p>
                <p>Please note that the aforementioned rights might be limited under applicable national data protection law. We remain the universal point of contact for your execution of these rights.You also have the right to lodge a complaint with the competent supervisory authority in the member state of your habitual residence or in the member state wherein the alleged infringement of the GDPR took place.</p>
              </li>
            </ol>

            <h2 className="h4 mb-4">6. No obligation to provide your personal data</h2>
            <p>You have no obligation to provide your personal data to us either under applicable law or a contract. Once you make an order for the Service, we may require your personal data based on the contract with you, in which case your failure to provide personal data we require may prevent us from providing you with the Service.</p>

            <h2 className="h4 mb-4">7. Identity and contact details of the controller</h2>
            <p>When you interact and provide your personal data with us, we are the controller of your personal data who determine the purposes and means of processing your personal data. You can contact us by visiting our principal place of business at Toranomon Hills Business Tower 15F, 17-1, Toranomon 1-chome, Minato-ku, Tokyo 105-6490, Japan or sending an email to the following email address : info@donutrobotics.com.<br /><br />
              EU Customers Privacy Policy last updated: 31 August, 2023
            </p>



            <h1 className="h3 mb-4 text-center mt-5">利用者に関する情報の外部送信</h1>

            <p>当社は、アクセス解析による当社サービスの改善等の目的のために、タグや情報収集モジュール等のツールを利用して、お客様に関する情報を収集し、外部に送信しています。ツール提供者の名称、ツール提供者に送信されるお客様に関する情報の内容、送信される情報の利用目的等の詳細については、以下をご確認ください。<br />
              なお、お客様に関する情報の送信先となるツール提供者は、下記の目的及び当社に対するサービスの提供の目的のほか、ツール提供者のプライバシーポリシー等に基づきお客様の情報を利用する場合があります。</p>
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">サービス名</th>
                  <th className="text-center">送信先事業者名</th>
                  <th className="text-center">送信される利用者の情報</th>
                  <th className="text-center">当社における利用目的</th>
                  <th className="text-center">送信先事業者における利用目的</th>
                  <th className="text-center">オプトアウト／その他</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Googleアナリティクス</td>
                  <td>Google LLC及びその関係会社</td>
                  <td>・インターネット通信に通常用いられるシステム、デバイス、ネットワーク、通信に関する情報<br />
                    ・位置情報<br />
                    ・サイト・アプリ上での行動に関するデータ<br />
                    ・閲覧ページに関するデータ<br />
                    ・ユーザー識別子（cookie、端末識別子等）</td>
                  <td>閲覧の傾向や履歴の分析のため</td>
                  <td><a href="https://policies.google.com/privacy?hl=ja">詳細</a></td>
                  <td><a href="https://tools.google.com/dlpage/gaoptout?hl=ja">詳細</a></td>
                </tr>
                <tr>
                  <td>Web Speech API</td>
                  <td>orld Wide Web Consortium</td>
                  <td>・API を使用する Web サイトのドメイン、デフォルトのブラウザ言語、Web サイトの言語設定
                    ・音声データ</td>
                  <td>取得した音声データをテキストに変換するため</td>
                  <td>音声データをテキストに変換するため</td>
                  <td><a href="https://wicg.github.io/speech-api/">詳細</a></td>
                </tr>
                <tr>
                  <td>Google Speech to Text API</td>
                  <td>Google LLC及びその関係会社</td>
                  <td>・音声データ
                    ・音声データを文字変換した情報</td>
                  <td>取得した音声データをテキストに変換するため</td>
                  <td>音声データをテキストに変換するため</td>
                  <td><a href="https://cloud.google.com/terms">詳細</a></td>
                </tr>
                <tr>
                  <td>AmiVoice API</td>
                  <td>株式会社アドバンスト・メディア</td>
                  <td>・音声データ
                    ・音声データを文字変換した情報</td>
                  <td>取得した音声データをテキストに変換するため</td>
                  <td>音声データをテキストに変換するため</td>
                  <td><a href="https://acp.amivoice.com/amivoice_api/terms/">詳細</a></td>
                </tr>
                <tr>
                  <td>Azure Speech to Text API</td>
                  <td>Microsoft Corporation</td>
                  <td>・音声データ
                    ・音声データを文字変換した情報</td>
                  <td>取得した音声データをテキストに変換するため</td>
                  <td>音声データをテキストに変換するため</td>
                  <td><a href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/code-of-conduct">詳細</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='row justify-content-center mt-5 text-primary'>
          <div className='col-auto'>
            <div className='form-check'>
              <input id='tosCheck' className='form-check-input' type='checkbox' checked={tos} onChange={toggleTos} />
              <label className='form-check-label' htmlFor='tosCheck'>
                {t('利用規約およびプライバシーポリシーに同意しました')}
              </label>
            </div>
          </div>
        </div>
        <div className='row justify-content-center mt-5 text-primary'>
          <div className='col-auto'>
            <button className={`btn btn-secondary px-5 rounded-pill${tos ? ' grad-02' : ''}`}
              disabled={!tos} onClick={handleStart}>
              {t('開始')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})
