import './MainPage.css'
import './SummaryOnly.css'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { TypeaheadRef } from 'react-bootstrap-typeahead';
import useQuery from '../hook/useQuery';

import { LanguageFlag } from '../component/LanguageFlag'
import ChatList from '../component/ChatList'
import { LocalStorage } from '../store/LocalStorage'
import { guestLanguage, ILanguageObject, ILanguages, LanguageList } from '../util/language';
import Messages from '../store/Messages'
import { UserInfoModal } from '../component/UserInfoModal'
import { TroubleshootingModal } from '../component/TroubleshootingModal'
import { ConfirmAIAdvisorModal } from "../component/ConfirmAIAdvisorModal";
import { ConfirmMeetingActiveModal } from '../component/ConfirmMeetingActiveModal'
import { MEETING_FINISH_MODAL_ACTION, MeetingFinishModal } from "../component/MeetingFinishModal";
import { SttChangeModal } from "../component/SttChangeModal";
import { INITIAL_ID, parseQueryParam, randomId, isDefaultMinutes, changeLanguageAgendaList, isNotProduction } from '../util/util'
import { useChat } from '../hook/useChat'
import { textToSpeech } from '../util/TTS'
import RecordButtonAzure, { RecordButtonHandles } from '../component/RecordButtonAzure'
import { addTranslation } from '../api/GoogleApi';
import { Modal } from '../component/Modal'
import { useParticipants } from '../hook/useParticipants'
import { addOrUpdateSpeaker } from '../hook/useSpeakers'
import { Speaker } from '../types/speaker'
import  PerticipantsWidget from '../component/participantsWidget'

/* === Svg's === */
import { ReactComponent as SpeakerOn } from '../assets/images/speaker.svg'
import { ReactComponent as SpeakerOff } from '../assets/images/speaker_off.svg'
import { ReactComponent as WindowStack } from '../assets/images/window-stack.svg'
import { ReactComponent as CaretUpFill } from '../assets/images/caret-up-fill.svg'
import { ReactComponent as CaretDownFill } from '../assets/images/caret-down-fill.svg'

import { Crystal } from '../component/Crystal'
import { bootstrapQuery, useMediaQuery } from '../hook/useMediaQuery'
import AudioManager from '../store/AudioManager'
import { audioMasterAnalyserBus } from '../util/audioContext'
import AdvisorStateInst, { RealtimeMinute } from '../store/AdvisorState'
import ConfirmMeetingActiveModalStateInst from '../store/ConfirmMeetingActiveModalState'
import { MinutesList, MinutesListHandles } from "../component/MinutesList";
import { Option, TypeaheadPropsAndState } from "react-bootstrap-typeahead/types/types";
import { AgendaSelect, AgendaSelectHandles } from "../component/AgendaSelect";
import { sentryLog, sentrySetUser } from '../util/sentry';
import { updateUserLanguage, useUserLanguages } from "../hook/useUserLanguage";
import AdvisorContent from "../component/AdvisorContent";
import advisorMessages from "../store/AdvisorMessages";
import AdvisorForm from "../component/AdvisorForm";

import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "animate.css/animate.min.css";

const Fade = cssTransition({
  enter: "animate__animated animate__fadeIn",
  exit: "animate__animated animate__fadeOut",
});

const USER_ID = 'local_user_uniq'

const SelfTranslateModal = ({ open, lang, onSelect }: { open: boolean, lang: ILanguages | null, onSelect: (lang: ILanguages | null) => void }) => {
  const { t } = useTranslation()

  return (
    <Modal.Root open={open}>
      <Modal.Header>{t("発言翻訳先の言語指定")}</Modal.Header>
      <Modal.Body>
        <div className='row border rounded pt-3' style={{ flex: 1, overflowY: 'auto' }}>
          <div className='row language-select mx-0'>
            <div className='col-12 text-center text-secondary h5'>
              {t("発言の自動翻訳先を指定してください。")}
            </div>
            {/*<div className='col-6 col-sm-4 col-md-3 col-xl-2 mb-2'>
              <LanguageFlag active={lang == null} showName
                language={null} onItemClick={onSelect} />
            </div>*/}
            {Object.values(LanguageList).map((language) => (
              <div key={language.id} className='col-6 col-sm-4 col-md-3 col-xl-2 mb-2'>
                <LanguageFlag active={language.id === lang} showName
                              language={language} onItemClick={onSelect} />
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal.Root>
  )
}

type ToggleSpeakerSpeechProps = JSX.IntrinsicElements['div'] & {
  stopAdvisory: () => void
}

const ToggleSpeakerSpeech = observer(({ stopAdvisory, ...divProps }: ToggleSpeakerSpeechProps) => {
  const { t } = useTranslation()
  const SelfSpeakComponent = (
    LocalStorage.speakTranslateLocal
    ? SpeakerOn
    : SpeakerOff
  )
  const SpeakComponentStyle = { width: 35, height: 35 }

  if (LocalStorage.language.length === 0 && LocalStorage.translationLanguage == null) {
    return null
  }

  const handleSpeakTranslateLocalClick = useCallback(() => {
    LocalStorage.setValue('speakTranslateLocal', !LocalStorage.speakTranslateLocal);
    stopAdvisory();
  }, [stopAdvisory]);

  return(
    <div {...divProps}>
      <button
        className="flex-shrink-1 btn badge rounded-circle shadow-none"
        title={t("翻訳されたメッセージを発話する")}
        onClick={handleSpeakTranslateLocalClick}
      >
        <SelfSpeakComponent style={SpeakComponentStyle} />
      </button>
    </div>
  )
})

type HeaderProps = {
  onLeftFlagClick:  () => void,
  onRightFlagClick: () => void,
  onClickCrystal: () => void,
  stopAdvisory: () => void,
  // AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
  // デフォルト値は false ミーティング参加時のソケット通信で設定値を受け取る（ useChat.ts にて定義・更新される）
  enableAIAdvisor: boolean,
}

const Header = observer((props: HeaderProps) => {
  const {t} = useTranslation()
  const isOnPC = useMediaQuery(bootstrapQuery.md)

  const leftFlagLanguage: ILanguageObject[] = LocalStorage.language ? LocalStorage.language.map(l => LanguageList[l]) : [LanguageList[ILanguages["ja-JP"]]];

  // 翻訳言語
  const rightFlagLanguage: ILanguageObject | null = (
    LocalStorage.translationLanguage
      ? LanguageList[LocalStorage.translationLanguage]
      : null
  )

  return (
    <section className='chat-header flex-fill'>
      <div className='row justify-content-stretch align-items-end'>
        <div className='col-4 logo-wrap text-center'>
          {!isOnPC && <ToggleSpeakerSpeech stopAdvisory={props.stopAdvisory} />}
          <img
            className={isOnPC ? 'gptech-logo' : 'gptech-logo-phone'}
            src='/donutai_1_0_logo.png'
            alt='gptech-logo'
          />
        </div>

        <Crystal onClickCrystal={props.onClickCrystal} enableAIAdvisor={props.enableAIAdvisor} />

        {(!!LocalStorage.name) && (
          <div className='col-4 lang-wrap ms-auto position-relative'>
            <div className='row gx-0 align-items-center'>
              {isOnPC ? (
                <>
                  {/*
                    for a PC!!
                  */}
                  <div className='text-truncate text-end col-6'>
                    <b>{LocalStorage.name}</b>
                  </div>
                  <div className='col-2'>
                    {t('さん')}
                  </div>

                  <span className='w-100'></span>
                  <div className='flag-button justify-content-center col-8' style={{width: "auto"}}>
                    {leftFlagLanguage.map((language, index) => (
                      <LanguageFlag key={index} language={language} onClick={props.onLeftFlagClick} />
                    ))}
                    <span>⇔</span>
                    <LanguageFlag language={rightFlagLanguage}  onClick={props.onRightFlagClick} />
                  </div>

                  <ToggleSpeakerSpeech
                    stopAdvisory={props.stopAdvisory}
                    className='col-4 d-flex justify-content-end align-items-center'
                  />
                </>
              ) : (
                <>
                  {/*
                    for a phone!!!
                  */}
                  <div className='text-center text-center col-12'>
                    <b>{LocalStorage.name}</b>
                  </div>

                  <div className='flag-button justify-content-center col-12' style={{width: "auto"}}>
                    <LanguageFlag language={leftFlagLanguage[0]} onClick={props.onLeftFlagClick} />
                    <span>⇔</span>
                    <LanguageFlag language={rightFlagLanguage}  onClick={props.onRightFlagClick} />
                  </div>
                </>
              )}
            </div>
          </div>
        )}

      </div>
    </section>
  )
})


export const MainPage = observer(() => {
  const { t, i18n } = useTranslation()

  const [showSttChangeModal, setShowSttChangeModal] = useState(false)
  const [showConfirmAIAdvisorModal, setShowConfirmAIAdvisorModal] = useState(false)
  const [showConfirmMeetingActiveModal, setShowConfirmMeetingActiveModal] = useState(false)
  const [showTroubleshootingModal, setShowTroubleshootingModal] = useState(false)
  const [showMeetingFinishModal, setShowMeetingFinishModal] = useState(false)
  const [showInputModal, setShowInputModal] = useState(true)
  const [expandLanguageList, setExpandLanguageList] = useState(false)
  const [openTranslatingModal, setOpenTranslatingModal] = useState(false)
  const [recordValues, setRecordValues] = useState({isRecording:false, isDisabled:false});
  const [agenda, setAgenda] = useState<string[]>([]);
  const [typeaheadShow, setTypeaheadShow] = useState<boolean>(true);
  const [agendaValue, setAgendaValue] = useState<string>(LanguageList['ja-JP'].summaryAgenda as string);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [showLoadingChat, setShowLoadingChat] = useState(false)
  const [showLoadingAdvisorChat, setShowLoadingAdvisorChat] = useState(false)
  const [isShowChatContent, setIsShowChatContent] = useState(true)


  const { getSummaryFlag, paramSummaryWindow, paramShareWindow, receiveMessage } = useQuery();
  const summaryFlag = getSummaryFlag();

  if(summaryFlag) {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop?.parentNode?.removeChild(backdrop);
    }
  }

  receiveMessage();

  const handleSelfTranslatingSelection = (lang: ILanguages | null) => {
    LocalStorage.setValue('translationLanguage', lang)
    setOpenTranslatingModal(false)
  }

  const recordRef = useRef<RecordButtonHandles>(null);
  const minutesListRef = useRef<MinutesListHandles>(null);
  const agendaSelectRef = useRef<AgendaSelectHandles>(null);
  const typeaheadRef = useRef<TypeaheadRef>(null);
  const timerRef = useRef(null);
  const chatContentRef = useRef<HTMLDivElement>(null);
  const advisorContentRef = useRef<HTMLDivElement>(null);
  const bsCollapseChatContentRef = useRef<any>(null);
  const bsCollapseAdvisorContentRef = useRef<any>(null);

  const token = useMemo(() => {
    let token = parseQueryParam('t')
    if (!token && process.env.NODE_ENV === 'development') {
      // test only
      token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tT3duZXIiOjEsInJvb21JZCI6IjJzUmpldnRaa0hPbll5V2t0NEZVIiwidGl0bGUiOiJ0ZXN0LXBodW9jZGgiLCJpYXQiOjE2ODcxMzkzOTd9.pM0JnovD4cJqPJUh1F4nJ1wpidL3Ttm1PrOU6QQxPgQ'
      // internal_testing_01
      // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiJpbnRlcm5hbF90ZXN0aW5nXzAxIiwicm9vbU93bmVyIjoiMSIsImlhdCI6MTcxMjcyNTkxN30.J-OpC4HGLz5b7YiM3--KTCrFgSvXLS1AheOZMhOjpjg'
      // internal_testing_02
      // token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiJpbnRlcm5hbF90ZXN0aW5nXzAyIiwicm9vbU93bmVyIjoiMSIsImlhdCI6MTcxMjcyNTkxN30.38mAnxSsHFWfEjypwcz2RxC_1Yz-SHyXuNpGeH2bSVc'
    }
    return token
  }, [])
  const { sendMessage, changeName, changeLabels, agendaAdd, agendaEdit, agendaDelete, agendaChange, initiateAdvisory: initAdvisor, stopAdvisory, roomClose, advisorChatRequest, meetingIdRef, enableAIAdvisorRef, roomOwnerIdRef } = useChat(token)
  const roomOwnerId = roomOwnerIdRef.current as string;
  const meetingId = meetingIdRef.current as string;
  const enableAIAdvisor = enableAIAdvisorRef.current as boolean;
  const participants = useParticipants(roomOwnerId, meetingId);

  // ユーザー言語設定
  useUserLanguages(roomOwnerId, meetingId);

  useEffect(() => {
    // mediaDevices support
    if (!navigator.mediaDevices) {
      console.warn('このブラウザを対応しません。')
    }

    // ask user to input name and language
    if (token) {
      if (!LocalStorage.name || !LocalStorage.language) {
        setShowInputModal(true)
      }
    }

    return () => {
      setShowInputModal(true)
    }
  }, [token])

  // ルーム開閉状態による自動制御
  useEffect(() => {
    const handleRoomStateChange = () => {
      if(LocalStorage.roomClosed) {
        // ルームが開始されていない状態・終了した状態の場合は音声レコーダー停止（クリック不可）
        recordRef.current && recordRef.current.stopRecording(true);
      } else{
        // ルームが開始されたらクリック可能とする
        recordRef.current && recordRef.current.enableToggle();
        // 言語設定済・自動的にマイクをオンにする設定の場合は音声レコーダー開始
        if (token && !summaryFlag && LocalStorage.autoStart && LocalStorage.language && LocalStorage.name) {
          recordRef.current && recordRef.current.startRecording();
        }
      }
    };
    // ローカルストレージのデータが変更された際に発火するリスナーとして追加
    window.addEventListener('localstorage-change-roomState', handleRoomStateChange);

    return () => {
      // リスナー解除
      window.removeEventListener('localstorage-change-roomState', handleRoomStateChange);
    };
  }, [summaryFlag, token]);

  // 音声レコーダー・ボタンの変化があったら発火する
  const onRecordStateChange = useCallback(({isRecording, isDisabled}: {isRecording: boolean, isDisabled: boolean}) => {
    setRecordValues({isRecording, isDisabled});
  },[]);

  // 他のクラスに音声レコーダーの更新を伝播させる
  useEffect(() => {
    if (recordRef.current) {
      AudioManager.setRecordRef(recordRef);
      AdvisorStateInst.setRecordRef(recordRef);
    }
  }, [recordValues]);

  const name = LocalStorage.name
  // update name to server
  useEffect(() => {
    if (name) {
      changeName(name)
      if (LocalStorage.uid) {
        Messages.changeName(LocalStorage.uid, name)
      }
    }
  }, [changeName, name])

  useEffect(() => {
    if (LocalStorage.uid != null && token !== null){
      const meetingId = token as string
      sentrySetUser(LocalStorage.uid, meetingId)
    }
  }, [token])

  useEffect(() => {
    // 自身のclientIdを持つ参加者をTTSに報告する人数とする
    const filtered = participants.filter(p => p.clientId === (LocalStorage.uid || USER_ID));
    recordRef.current && recordRef.current.updateClusterAmount(filtered.length);
  }, [participants])

  useEffect(() => {
    if(roomOwnerId === LocalStorage.ownerId){
      setIsOwner(true);
    }
  }, [roomOwnerId]);

  // const { onStart, onStop, sendData } = useGoogleSpeech({
  //   userId: USER_ID,
  //   onMessage: (result, isFinal) => {
  //     console.log(result)
  //   },
  //   needInterim: false,
  // })

  // const handleMicData = useCallback((data: BlobPart) => {
  //   sendData(data)
  // }, [sendData])

  // const handleStartRecording = useCallback(() => {
  //   onStart(LanguageList['ja-JP'].id)
  // }, [onStart])

  const handleMicData = useCallback(async (talkId: string, speakerId: string, text: string, language: string, isFinal: boolean = false) => {
    if (LocalStorage.roomClosed) {
      return
    }

    const msgText = text.trim()

    // ドラフト文の場合はローディング表示
    if(!isFinal){
      setShowLoadingChat(true);
      return;
    }else{
      // 最終結果の場合は非表示
      setShowLoadingChat(false);
    }

    // 最終結果かつ空文字である場合は処理しない
    if (msgText === '') {
      return;
    }

    const messageId = randomId()
    const languageID = guestLanguage(language)
    const languageCode = (
      languageID?.id ?? LocalStorage.language[0] ?? ILanguages['ja-JP']
    )
    const speaker: Speaker = {
      id: `${LocalStorage.uid}/${speakerId}`,
      userId: null
    }

    sendMessage(msgText, messageId, speaker.id, talkId, languageCode)

    // Add speaker to firestore.

    await addOrUpdateSpeaker(roomOwnerId, meetingId, speaker.id, speaker.userId)

    Messages.add({
      id: messageId,
      text: msgText,
      talkLabel: talkId,
      userId: LocalStorage.uid || USER_ID,
      speakerId: speaker.id,
      sourceLanguage: languageCode,
      translations: {},
    })

    if (languageID) {
      try {
        await addTranslation(msgText, languageID.id, async (toLanguage: ILanguages, translatedText: string) => {
          Messages.addTranslation(messageId, toLanguage, translatedText)

          if (LocalStorage.speakTranslateLocal) {
            AudioManager.queuePlayAudio(
              textToSpeech(translatedText, toLanguage),
              audioMasterAnalyserBus
            )
          }
        })
      }catch (e: any) {
        console.error('Error during translation or speech synthesis:', e);
        sentryLog(e);
      }
    }
  }, [sendMessage, roomOwnerId, meetingId])

  const showLanguageSelection = useCallback(() => {
    setExpandLanguageList(true)
    setShowInputModal(true)
  }, [])

  const handleInputeModalClose = useCallback(() => {
    setExpandLanguageList(false)
    setShowInputModal(false)

    if (token && !summaryFlag && LocalStorage.autoStart && LocalStorage.language && LocalStorage.name && !LocalStorage.roomClosed && !AdvisorStateInst.isRunning) {
      recordRef.current && recordRef.current.startRecording()
    }else if(LocalStorage.roomClosed){
      recordRef.current && recordRef.current.stopRecording(true)
    }
  }, [token, summaryFlag])

  const handleTroubleshootingModalShow = useCallback(() => {
    setShowTroubleshootingModal(true)
  }, [])

  const handleTroubleshootingModalClose = useCallback(() => {
    setShowTroubleshootingModal(false)
  }, [])

  const handleMeetingFinishModalClose = useCallback((action: MEETING_FINISH_MODAL_ACTION) => {
    setShowMeetingFinishModal(false)
    switch (action){
      case MEETING_FINISH_MODAL_ACTION.leave:
        window.close();
        // javascriptで画面を開いていない場合 画面が閉じられないためブランク画面に移動する
        window.open('about:blank', '_self');
        break;
      case MEETING_FINISH_MODAL_ACTION.end:
        roomClose();
        window.close();
        // javascriptで画面を開いていない場合 画面が閉じられないためブランク画面に移動する
        window.open('about:blank', '_self');
        break;
    }
  }, [roomClose])

  const handleConfirmMeetingActiveModalClose = useCallback((close: boolean) => {
    setShowConfirmMeetingActiveModal(false)
    if(close){
      roomClose();
    }
  }, [roomClose])

  ConfirmMeetingActiveModalStateInst.callback = () => {
    setShowConfirmMeetingActiveModal(true);
    if(timerRef.current){
      // 既存のタイマーをクリア
      // @ts-ignore
      clearTimeout(timerRef.current);
    }

    // 新しいタイマーを設定
    // @ts-ignore
    timerRef.current = setTimeout(() => {
      if (ConfirmMeetingActiveModalStateInst.isShow) {
        setShowConfirmMeetingActiveModal(false);
        roomClose();
      }
    }, 60000);
  }

  useEffect(() => {
    ConfirmMeetingActiveModalStateInst.isShow = showConfirmMeetingActiveModal;
  }, [showConfirmMeetingActiveModal]);

  const initiateAdvisory = useCallback(async () => {
    if(AdvisorStateInst.isRunning){
      stopAdvisory();
    }else{
      setShowConfirmAIAdvisorModal(true);
    }
  }, [stopAdvisory]);

  const handleConfirmAIAdvisorModalClose = useCallback((show: boolean) => {
    setShowConfirmAIAdvisorModal(false);
    if (LocalStorage.roomClosed) {
      return;
    }
    if(show){
      initAdvisor()
    }
  }, [initAdvisor])

  const handleSummaryWindowShow = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set(paramSummaryWindow, '1');
    openWindow(url.toString());
  }

  const openWindow = (url: string, width: number = 600, height: number = 600) => {
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    window.open(
        url,'Window', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  }

  const changeLanguageHandler = useCallback((lng: string) => {
    // htmlの言語設定を変更する
    document.documentElement.setAttribute('lang', lng);
    // 言語変更時に規定のタイトル名「全体要約」「議事録」を変更する
    setAgenda(changeLanguageAgendaList(lng, agenda));
    // 言語設定をfirestoreに送信
    updateUserLanguage(roomOwnerId, meetingId, lng);
  }, [agenda, meetingId, roomOwnerId]);

  useEffect(() => {
    // 言語変更時
    i18n.on('languageChanged', changeLanguageHandler);
    return () => {
      i18n.off('languageChanged', changeLanguageHandler);
    };
  }, [changeLanguageHandler, i18n]);

  const handleAgendaSelect = (selected: Option[]) => {
    const selectAgenda = selected[0] as string;
    if(!selectAgenda){
      return;
    }
    if(selectAgenda !== ''){
      agendaSelectRef.current && agendaSelectRef.current.setAgendaSelected(selectAgenda);
    }else if(!isDefaultMinutes(selectAgenda)) {
      agendaSelectRef.current && agendaSelectRef.current.setAgendaSelected('');
    }
    agendaSelectRef.current && agendaSelectRef.current.setAgendaEditValue('');

    if(agenda.includes(selectAgenda)){
      minutesListRef.current && minutesListRef.current.setAgenda(selectAgenda);
      agendaChange(selectAgenda);
    }
  }

  const handleAgendaInput = (text: string, event: ChangeEvent<HTMLInputElement>) => {
    agendaSelectRef.current && agendaSelectRef.current.setAgendaEditValue(text);
  }

  const agendaFilterBy = (option: Option, state: TypeaheadPropsAndState) => {
    return true;
  }

  const onAgendaAdd = (val: string): boolean => {
    const trim = val.trim();
    if(trim === ''){
      alert(t('議題を入力して下さい'));
      return false;
    }
    if(isDefaultMinutes(trim)){
      alert(t('「{{agenda}}」以外の議題を入力して下さい', {agenda: trim}));
      return false;
    }
    if(agenda.includes(trim)){
      alert(t('議題「{{agenda}}」はすでに存在しています', {agenda: trim}));
      return false;
    }
    agendaAdd(trim);
    return true;
  }

  const onAgendaEdit = (val: string, targetAgenda: string): boolean => {
    const trim = val.trim();
    if(trim === ''){
      alert(t('議題を入力して下さい'));
      return false;
    }
    if(isDefaultMinutes(trim)){
      alert(t('「{{agenda}}」以外の議題を入力して下さい', {agenda: trim}));
      return false;
    }
    if(agenda.includes(trim)){
      alert(t('議題「{{agenda}}」はすでに存在しています', {agenda: trim}));
      return false;
    }
    if(!agenda.includes(targetAgenda)){
      alert(t('編集前の議題「{{agenda}}」はすでに削除されたか、存在していません', {agenda: trim}));
      return false;
    }
    agendaEdit(trim, targetAgenda);
    return true;
  }

  const onAgendaDelete = (targetAgenda: string): boolean => {
    if(isDefaultMinutes(targetAgenda)){
      alert(t('「{{agenda}}」は削除できません', {agenda: targetAgenda}));
      return false;
    }
    if(!agenda.includes(targetAgenda)){
      alert(t('議題「{{agenda}}」はすでに削除されたか、存在していません', {agenda: targetAgenda}));
      return false;
    }
    agendaDelete(targetAgenda);
    return true;
  }

    // 表示するアジェンダを切り替える
  const handleAgendaChange = useCallback((targetAgenda: string) => {
    if (targetAgenda !== agendaValue) {
      // Typeahead コンポーネントは入力値をコントロールできず
      // 初期値のみ設定可能なので、初期値を変更しつつ表示・非表示を切り替える

      // TODO: setTimeoutに頼らない実装
      // setTimeoutをしない場合、以下の状況下でバグが生じることがわかっています。
      // アジェンダを編集したり削除する -> 他のユーザーに対してsocket通信経由でアジェンダ情報を更新を通知
      // -> アジェンダの議事録の表示は切り替わるが、Typeaheadの表示が切り替わらない

      // Typeahead コンポーネント非表示
      setTypeaheadShow(false);
      setTimeout(() => {
        // 初期値を設定
        setAgendaValue(targetAgenda);
        // Typeahead コンポーネント表示
        setTypeaheadShow(true);
        setTimeout(() => {
          // Typeahead コンポーネントの選択値を設定
          agendaSelectRef.current && agendaSelectRef.current.setAgendaSelected(targetAgenda);
          // Typeahead コンポーネントの編集値を初期化
          agendaSelectRef.current && agendaSelectRef.current.setAgendaEditValue('');
          // 表示するアジェンダ情報を切り替え
          minutesListRef.current && minutesListRef.current.setAgenda(targetAgenda);
          // 選択中のアジェンダの変更を通知
          agendaChange(targetAgenda);
        }, 0);
      }, 0);
    }
  }, [agendaChange, agendaValue]);

  useEffect(() => {
    AdvisorStateInst.callbackRealtimeMinutesList = (minutes: RealtimeMinute[], targetAgenda?: string | null) => {
      setAgenda(minutes.map(m => m.agenda));
      if(targetAgenda){
        // 編集中の議題の入力値
        const editValue = agendaSelectRef.current?.getAgendaEditValue();
        // 選択中の議題が「全体要約」「議事録」または 編集中の議題の入力値がない場合は、表示している議題を変更する
        if(isDefaultMinutes(targetAgenda) || !editValue){
          handleAgendaChange(targetAgenda);
        }
      }
      minutesListRef.current && minutesListRef.current.setRealtimeMinutes(minutes);
      if(targetAgenda){
        minutesListRef.current && minutesListRef.current.setAgenda(targetAgenda);
      }
    };

    return () => {
      AdvisorStateInst.callbackRealtimeMinutesList = (minutes: RealtimeMinute[], targetAgenda?: string | null) => {}
    };
  }, [handleAgendaChange]);

  const handleExitBtnClick = () => {
    if(isOwner){
      setShowMeetingFinishModal(true);
    }else{
      window.close();
      // javascriptで画面を開いていない場合 画面が閉じられないためブランク画面に移動する
      window.open('about:blank', '_self');
    }
  }

  useEffect(() => {
    // Collapseを生成（生成されている場合は再生成しない）
    if(!bsCollapseChatContentRef.current){
      bsCollapseChatContentRef.current = new window.bootstrap.Collapse(chatContentRef.current, {
        toggle: false,
      });
    }
  }, []);

  useEffect(() => {
    // Collapseを生成（生成されている場合は再生成しない）
    if(!bsCollapseAdvisorContentRef.current){
      bsCollapseAdvisorContentRef.current = new window.bootstrap.Collapse(advisorContentRef.current, {
        toggle: false,
      });
    }
  }, []);

  const toggleContentVisibility = () => {
    if (isShowChatContent) {
      showAdvisorContent();
    } else {
      showChatContent();
    }
  };

  const showChatContent = () => {
    bsCollapseChatContentRef.current.show();
    bsCollapseAdvisorContentRef.current.hide();
    setIsShowChatContent(true);
  };

  const showAdvisorContent = () => {
    bsCollapseChatContentRef.current.hide();
    bsCollapseAdvisorContentRef.current.show();
    setIsShowChatContent(false);
  };

  const onAdvisorMessageSubmit = useCallback((content: string) => {
    advisorMessages.addUserMessage(content);
    const message = advisorMessages.initialAdvisorMessage();
    advisorChatRequest(message.id, content);
    setShowLoadingAdvisorChat(true);
  }, [advisorChatRequest]);

  useEffect(() => {
    if(advisorMessages.length() === 0){
      advisorMessages.addMessage('assistant', t('こんにちは! donutAIです。\n会議の内容などについて、お気軽に質問してくださいね。'), true);
    }
  }, [t, onAdvisorMessageSubmit]);

  advisorMessages.beforeStreamMessage = () => {
    setShowLoadingAdvisorChat(false);
  }

  const roomStateMessages = {
    waiting: t('開始されるのを待っています'),
    completed: t('会議が終了されました'),
    notexist: t('その会議は存在しません'),
    connecting: null,
    incall: null,
  };

  const handleShowSttPrompt = () => {
    setShowSttChangeModal(true);
  }

  const handleSttChangeModalClose = useCallback(() => {
    setShowSttChangeModal(false);
  }, [])

  return (
    <div className={`${summaryFlag ? 'SummaryOnly' : ''}`}>
      <div className='main-page px-3 pb-1'>
        {(isNotProduction()) && (
          <div style={{ position: 'absolute', top: 10, left: 10 }}>
            {/* GoogleSTTに変更中のため非表示 */}
            {/* TODO: GoogleSTT ⇔ 独自STTに切り替えができるようにする */}
            <button className='btn btn-danger btn-sm d-none' onClick={handleShowSttPrompt}>
            {t('STT変更')}
            </button>
          </div>
        )}
        <div className='logo-bg'>
          <img src='/logo.png' alt='LOGO' />
        </div>

        <section className='flex-fill d-flex flex-column'>
          <div className='row justify-content-center'>
            <Header
              onLeftFlagClick={showLanguageSelection}
              onRightFlagClick={() => setOpenTranslatingModal(true)}
              onClickCrystal={initiateAdvisory}
              stopAdvisory={stopAdvisory}
              enableAIAdvisor={enableAIAdvisor}
            />
          </div>

          <div className='row justify-content-center flex-fill main-wrapper'>
            <section className='box-left col-2'>
              <h4 className='my-4 text-center'>{t('参加者一覧')}</h4>
              <div className='users-list-bubble'>
                <PerticipantsWidget participants={participants} roomOwnerId={roomOwnerIdRef.current} meetingId={meetingIdRef.current} />
              </div>
              <button type="button" className="btn btn-troubleshooting w-100 mt-2" onClick={handleTroubleshootingModalShow}>{t('トラブルシューティング')}</button>
            </section>

            <section className='col-8 main-container'>
              {roomStateMessages[LocalStorage.roomState] && (
                  <div className='box-01 no-token'>
                    <h1 className='text-secondary text-center'>
                      {roomStateMessages[LocalStorage.roomState]}
                    </h1>
                  </div>
              )}

              <div className={`box-01 accordion${LocalStorage.roomState !== 'incall' ? ' d-none' : ''}`}>
                <div className="accordion-item">
                  <div
                      id="chat-content"
                      ref={chatContentRef}
                      className={`accordion-collapse collapse collapse show`}
                  >
                    <div className="accordion-body">
                      <ChatList
                          items={Array.from(Messages.data.values())}
                          userId={LocalStorage.uid || USER_ID}
                          roomOwnerId={roomOwnerIdRef.current}
                          meetingId={meetingIdRef.current}
                          showLoadingChat={showLoadingChat}
                      />
                    </div>
                  </div>
                  <button
                      className={`btn btn-chat-switch w-100${isShowChatContent ? ' show-chat' : ' show-advisor'}`}
                      type="button"
                      onClick={toggleContentVisibility}
                      aria-controls="chat-content advisor-content"
                  >
                    {isShowChatContent ? (<CaretUpFill className="caret-up-fill" />) : (
                        <CaretDownFill className="caret-down-fill"/>)}
                  </button>
                  <div
                      id="advisor-content"
                      ref={advisorContentRef}
                      className={`accordion-collapse collapse`}
                  >
                    <div className="accordion-body">
                      <AdvisorContent
                          items={Array.from(advisorMessages.data.values())}
                          showLoadingChat={showLoadingAdvisorChat}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`footer-input-form${LocalStorage.roomState !== 'incall' ? ' d-none' : ''}`}>
                <AdvisorForm
                    token={token}
                    showAdvisorContent={showAdvisorContent}
                    onAdvisorMessageSubmit={onAdvisorMessageSubmit}/>
              </div>
            </section>

            <section className='box-summary box-right col-2'>
              <h4 className='my-4 text-center'>{t('現在の進捗')}<WindowStack className="window-stack text-primary" onClick={handleSummaryWindowShow}/></h4>
              <div className={`${summaryFlag ? ' row g-2' : ''}`}>
                <div className={`summary-agenda clearfix${summaryFlag ? ' col-md-4 mt-0' : ''}`}>
                  <AgendaSelect
                      ref={agendaSelectRef}
                      typeaheadRef={typeaheadRef}
                      agendaFilterBy={agendaFilterBy}
                      agenda={agenda}
                      typeaheadShow={typeaheadShow}
                      handleAgendaSelect={handleAgendaSelect}
                      handleAgendaInput={handleAgendaInput}
                      handleAgendaChange={handleAgendaChange}
                      agendaValue={agendaValue}
                      onAgendaAdd={onAgendaAdd}
                      onAgendaEdit={onAgendaEdit}
                      onAgendaDelete={onAgendaDelete} />
                </div>
                <div className={`summary-bubble${summaryFlag ? ' col-md-8 opn_win' : ''}`}>
                  <MinutesList ref={minutesListRef}/>
                </div>
              </div>
            </section>
          </div>

          <div className='row justify-content-center'>
            {!!token && meetingId !== INITIAL_ID && !LocalStorage.roomClosed && !summaryFlag && (
              <div className='bottom-bar flex-fill'>
                <RecordButtonAzure
                  meetingId={meetingId}
                  onData={handleMicData}
                  ref={recordRef}
                  onRecordStateChange={onRecordStateChange}
                  changeLabels={changeLabels}
                  roomOwnerId={roomOwnerId}
                />
              </div>
            )}
          </div>
          {!LocalStorage.roomClosed && !summaryFlag && (
              <button className='btn btn-danger btn-room-close' onClick={handleExitBtnClick}>{t('終了')}</button>
          )}
        </section>
      </div>
      {isNotProduction() && (<SttChangeModal open={showSttChangeModal} onClose={handleSttChangeModalClose} />)}
      <ConfirmAIAdvisorModal open={showConfirmAIAdvisorModal} onClose={handleConfirmAIAdvisorModalClose} />
      <ConfirmMeetingActiveModal open={showConfirmMeetingActiveModal} onClose={handleConfirmMeetingActiveModalClose} />
      <MeetingFinishModal open={showMeetingFinishModal} onClose={handleMeetingFinishModalClose} />
      <TroubleshootingModal open={showTroubleshootingModal} onClose={handleTroubleshootingModalClose} />
      <UserInfoModal open={!AdvisorStateInst.isRunning && showInputModal} languageListOpen={expandLanguageList} onClose={handleInputeModalClose} />
      <SelfTranslateModal open={!AdvisorStateInst.isRunning && openTranslatingModal} lang={LocalStorage.translationLanguage} onSelect={handleSelfTranslatingSelection} />
      <iframe src={`${process.env.REACT_APP_CLIPEAR_USER_MANGEMENT_URL}?${paramShareWindow}=1`} title='CLIPEAR_USER_MANGEMENT_URL' className='d-none'/>

      {/* interim result */}
      {isShowChatContent && ( <ToastContainer
        position="bottom-center"
        closeButton={false}
        transition={Fade}
        hideProgressBar={true}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
        className="interim"
        autoClose={false}
        closeOnClick /* クリックでトーストを非表示にする */
      />)}
    </div>
  )
})
