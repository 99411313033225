import { makeAutoObservable, observable } from 'mobx'
import { randomId } from "../util/util";

class AdvisorMessage implements AdvisorChatMessage {
  id: string;
  role: string;
  content: string;
  finish: boolean;

  constructor(data: AdvisorChatMessage) {
    this.id = data.id
    this.role = data.role
    this.content = data.content
    this.finish = data.finish

    makeAutoObservable(this)
  }
}

export class AdvisorMessages {
  data = observable.map<string, AdvisorMessage>()
  beforeStreamMessage = () => {}

  constructor() {
    makeAutoObservable(this)
  }

  setData = (messages: AdvisorMessage[]) => {
    this.data.clear()
    messages.forEach((message) => {
      this.data.set(message.id, message)
    })
  }

  add = (message: AdvisorMessage) => {
    this.data.set(message.id, new AdvisorMessage(message))
  }

  addMessage = (role: string, content: string, finish: boolean) => {
    const id = randomId();
    const message: AdvisorMessage = {
      id, role, content, finish
    };
    this.data.set(id, new AdvisorMessage(message))
    return message;
  }

  addAdvisorMessage = (content: string) => {
    return this.addMessage('assistant', content, false);
  }

  addUserMessage = (content: string) => {
    return this.addMessage('user', content, true);
  }

  initialAdvisorMessage = () => {
    return this.addMessage('assistant', '', false);
  }

  existMessage = (id: string) => {
    // idが存在するか確認
    return this.data.has(id);
  }

  existUnfinishedMessage = () => {
    // finish: false のデータが存在するか確認
    return Array.from(this.data.entries()).findIndex(([key, message]) => !message.finish) !== -1;
  }

  updateUnfinishedMessage = (id: string, newContent: string, finish: boolean) => {
    // マップからMessageオブジェクトを取得
    const message = this.data.get(id);
    // finish: true ではない場合に更新する
    if (message && !message.finish) {
      // Messageオブジェクトのプロパティを更新
      message.content = newContent;
      message.finish = finish;
      // 変更後のオブジェクトを再度マップに設定
      this.data.set(id, message);
    }
  }

  get = (messageId: string) => {
    return this.data.get(messageId)
  }

  remove = (messageId: string) => {
    this.data.delete(messageId)
  }

  getAllMessages = () => {
    const messages = Array.from(this.data.values())
    return messages.map(m => {
      return {role: m.role, content: m.content};
    })
  }

  length = () => {
    return this.data.size;
  }
}

const advisorMessages = new AdvisorMessages()

export default advisorMessages
