import { observer } from 'mobx-react-lite'
import React, {useCallback, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next'

export enum MEETING_FINISH_MODAL_ACTION {
  'leave',
  'end',
  'cancel',
}

export const MeetingFinishModal = observer(({ open, onClose }: { open?: boolean, onClose?: (action: MEETING_FINISH_MODAL_ACTION) => void }) => {
  const { t } = useTranslation()
  const myModal = useRef<any>()

  useEffect(() => {
    if (!myModal.current) {
      myModal.current = new window.bootstrap.Modal(document.getElementById('meeting_finish'), {
        keyboard: false
      })
    }

    if (open) {
      myModal.current.show()
    }else{
      myModal.current.hide()
    }
  }, [onClose, open])

  const handleMeetingFinishModalLeaveClose = useCallback(() => {
    onClose && onClose(MEETING_FINISH_MODAL_ACTION.leave)
  }, [onClose])

  const handleMeetingFinishModalEndClose = useCallback(() => {
    onClose && onClose(MEETING_FINISH_MODAL_ACTION.end)
  }, [onClose])

  const handleMeetingFinishModalCancelClose = useCallback(() => {
    onClose && onClose(MEETING_FINISH_MODAL_ACTION.cancel)
  }, [onClose])

  return (
    <div className='modal fade' id='meeting_finish' data-bs-backdrop='static'
      data-bs-keyboard='false' tabIndex={-1} aria-labelledby='meetingFinishModal' aria-hidden='true'>
      <div className='modal-dialog'>
        <div className='modal-content' style={{ borderRadius: '1rem' }}>
          <div className='modal-header border-bottom-0'>
            <h5 className='modal-title' id='meetingFinishModal'>
              {t('ミーティング終了')}
            </h5>
            <button type='button' className='btn-close' onClick={handleMeetingFinishModalCancelClose}></button>
          </div>
          <div className='modal-body'>
            <button type='button' className='btn btn-primary w-100 mb-3' onClick={handleMeetingFinishModalLeaveClose}>
              {t('ミーティングを退出')}
            </button>
            <button type='button' className='btn btn-danger w-100 mb-3' onClick={handleMeetingFinishModalEndClose}>
              {t('全員ミーティングを終了する')}
            </button>
            <button type='button' className='btn btn-secondary w-100 mb-3' onClick={handleMeetingFinishModalCancelClose}>
              {t('キャンセル')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})
