import { observer } from 'mobx-react-lite'
import React, {useCallback, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next'

export const ConfirmAIAdvisorModal = observer(({ open, onClose }: { open?: boolean, onClose?: (close: boolean) => void }) => {
  const { t } = useTranslation()
  const myModal = useRef<any>()

  useEffect(() => {
    if (!myModal.current) {
      myModal.current = new window.bootstrap.Modal(document.getElementById('confirm_ai_advisor'), {
        keyboard: false
      })
    }

    if (open) {
      myModal.current.show()
    }else{
      myModal.current.hide()
    }
  }, [onClose, open])

  const handleConfirmAIAdvisorModalYesClose = useCallback(() => {
    onClose && onClose(true)
  }, [onClose])

  const handleConfirmAIAdvisorModalNoClose = useCallback(() => {
    onClose && onClose(false)
  }, [onClose])

  return (
    <div className='modal fade' id='confirm_ai_advisor' data-bs-backdrop='static'
      data-bs-keyboard='false' tabIndex={-1} aria-labelledby='confirmAIAdvisorModal' aria-hidden='true'>
      <div className='modal-dialog'>
        <div className='modal-content' style={{ borderRadius: '1rem' }}>
          <div className='modal-header border-bottom-0 pb-2'>
            <h5 className='modal-title' id='confirmAIAdvisorModal'>
              {t('donutAIに意見を求めますか？')}
            </h5>
            <button type='button' className='btn-close' onClick={handleConfirmAIAdvisorModalNoClose}></button>
          </div>
          <div className='modal-body pt-1 pb-1'>
            <p>
              {t('音声が出ます。')}<br/>
              {t('クリックすると停止します。')}
            </p>
          </div>
          <div className='modal-footer pt-2'>
            <button type='button' className='btn btn-primary me-2' onClick={handleConfirmAIAdvisorModalYesClose}>
              {t('はい')}
            </button>
            <button type='button' className='btn btn-secondary' onClick={handleConfirmAIAdvisorModalNoClose}>
              {t('いいえ')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
});
