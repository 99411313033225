import { STOP_ADVISER_AUDIO_EVENT, CreateEvent } from "./eventListener";

export const audioContext = new AudioContext()

export const audioMasterAnalyserBus = new AnalyserNode(audioContext, {
  fftSize: 2048,
  smoothingTimeConstant: 0.2,
})
audioMasterAnalyserBus.connect(audioContext.destination)

export async function playAudio(
  buffer: AudioBuffer,
  outputTo: AudioNode
): Promise<void> {
  const bufferSource = new AudioBufferSourceNode(audioContext, {
    buffer,
  })
  bufferSource.connect(outputTo)

  return new Promise(resolve => {
    // 音声停止イベント作成
    const cleanup = CreateEvent(STOP_ADVISER_AUDIO_EVENT, () => {
      // イベントが発火したら音声停止
      if (bufferSource && bufferSource.buffer) {
        bufferSource.stop();
      }
    });
    bufferSource.onended = () => {
      // 音声停止イベント解除
      cleanup();
      resolve();
    };
    bufferSource.start()
  })
}
