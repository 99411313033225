import './TosPage.css'

import { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { LocalStorage } from '../store/LocalStorage'
import { useTranslation } from 'react-i18next'

import { Troubleshooting } from '../component/Troubleshooting'

export const PreparePage = observer(() => {
  const { t } = useTranslation()
  const [pre, setPre] = useState(false)

  const togglePre = useCallback(() => {
      setPre((v) => !v)
  }, [])

  const handleStart = useCallback(() => {
    LocalStorage.setValue('preAccepted', pre)
    if(pre) LocalStorage.setValue('roomState', null)
  }, [pre])

  return (
    <div className='pre-page py-5 px-2 px-sm-3'>
      <div className='logo-bg'>
        <img src='/logo.png' alt='LOGO' />
      </div>
        <div className='container d-flex flex-column' style={{height: '100dvh', maxWidth: 800, overflowY: 'auto'}}>
            <div className='row justify-content-center'>
                <div className='col-auto'>
                    <img src='/logo-wide.png' alt='logo-wide' className='logo-wide'/>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-auto'>
                    <b>donut AI(議事録ver.)</b>
                </div>
            </div>
            <div className='row mt-5 box-01 bg-white p-3 mx-3' style={{overflowY: 'auto', height: 'calc(100dvh - 400px)'}}>
                <div className='col'>
                    <Troubleshooting title={t('ご使用前の準備')} />
                </div>
            </div>
            <div className='row justify-content-center mt-5 text-primary'>
                <div className='col-auto'>
                    <div className='form-check'>
                        <input id='tosCheck' className='form-check-input' type='checkbox' checked={pre}
                               onChange={togglePre}/>
                        <label className='form-check-label' htmlFor='tosCheck'>
                            {t('マイクとスピーカーの入出力デバイスが正しく設定されていることを確認しました')}
                        </label>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center mt-5 text-primary'>
                <div className='col-auto'>
                    <button className={`btn btn-secondary px-5 rounded-pill${pre ? ' grad-02' : ''}`}
                            disabled={!pre} onClick={handleStart}>
                        {t('開始')}
                    </button>
                </div>
            </div>
        </div>
    </div>
)
})
