import './Crystal.css'
import React, { useEffect, useRef } from "react"
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { audioMasterAnalyserBus } from "../util/audioContext"
import AdvisorState from '../store/AdvisorState'

interface CrystalProps {
  onClickCrystal: () => void
  enableAIAdvisor: boolean // AIアドバイザー機能を使用するかどうか（「お話してもよろしいですか？」を表示するかどうか）
}

function onUpdate(element: HTMLVideoElement, audioArray: Uint8Array) {
  audioMasterAnalyserBus.getByteFrequencyData(audioArray)
  const peak = audioArray.reduce((prev, curr) => {
    return Math.max(prev, curr)
  }, 0)
  let peakRange = peak / 255
  peakRange = peakRange * peakRange * peakRange
  const zoomLevel = 100 + Math.max(Math.floor(peakRange * 33), 0)
  element.style.scale = `${zoomLevel}%`
}

export const Crystal = observer(({ onClickCrystal, enableAIAdvisor } : CrystalProps) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLVideoElement>(null)
  const wrapRef = useRef<HTMLDivElement>(null)

  // AIアドバイザーの能動的なメッセージが存在するかどうか
  const hasProactiveMessage = AdvisorState.hasLlmAdvisorMessage

  useEffect(() => {
    if (ref.current) {
      ref.current.play()

      const audioArray = new Uint8Array(audioMasterAnalyserBus.frequencyBinCount)
      const element = ref.current
      const handle = setInterval(() => {
        onUpdate(element, audioArray)
      }, 16)

      return () => clearInterval(handle)
    }
  }, [])

  const handleHover = (enter: boolean) => {
    if (wrapRef.current) {
      const element = wrapRef.current
      const zoomLevel = enter ? 105 : 100;
      element.style.scale = `${zoomLevel}%`
    }
  }

  return (
    <div ref={wrapRef} className="col-4 d-flex justify-content-center align-items-end">
      <video
        ref={ref}
        src="/crystal_graphics.mp4" loop
        width="100%"
        autoPlay
        muted
        disablePictureInPicture
        playsInline
        style={{
          mask: 'radial-gradient(#fff 50%, rgba(0, 0, 0, 0) 70%)',
          cursor: "pointer",
        }}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        onClick={onClickCrystal}
      />
      { enableAIAdvisor && <button
          onClick={onClickCrystal}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          className={`btn btn-primary accuracy_button${hasProactiveMessage ? ' standby' : ''}`}>{t('お話してもよろしいですか？')}</button>}
    </div>
  )
});
