// アドバイザー音声停止イベント名
export const STOP_ADVISER_AUDIO_EVENT = 'STOP_ADVISER_AUDIO_EVENT';

// イベントを発火する
export const DispatchEvent = (eventName: string) => {
    const event = new Event(eventName);
    document.dispatchEvent(event);
}

// イベントを作成する
export const CreateEvent = (eventName: string, func: () => void) => {
    document.addEventListener(eventName, func);
    return () => {
        // 解除するfunctionを返却
        document.removeEventListener(eventName, func);
    };
}
