import { useEffect, useState } from 'react'
import { doc, onSnapshot, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { UserLanguage } from '../types/userLanguage'
import { LocalStorage } from '../store/LocalStorage'
import { ILanguages } from "../util/language";
import { INITIAL_ID } from "../util/util";

// firestore に保存するユーザー言語設定データを追加・更新する
export const updateUserLanguage = async (roomOwnerId: string, meetingId: string, language: string) => {
  const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);
  const clientId = LocalStorage.uid as string;

  try {
    const docSnapshot = await getDoc(meetingDocRef);
    if (!docSnapshot.exists()) {
      // firestore にまだユーザー言語設定データがない場合は追加
      // ※会議作成時はまだユーザー言語設定データがない
      const newUserLanguage: UserLanguage = {
        id: clientId,
        language: language,
      };
      await setDoc(meetingDocRef, {
        userLanguages: [newUserLanguage]
      });
      console.log(`Meeting created and user language data added successfully.`);
    } else {
      // firestore にユーザー言語設定データが存在する場合は更新
      const existingUserLanguages: UserLanguage[] = docSnapshot.data().userLanguages || [];
      const userLanguageIndex = existingUserLanguages.findIndex(userLanguage => userLanguage.id === clientId);
      if (userLanguageIndex !== -1) {
        // firestore にすでに同じユーザーの言語設定データが存在する場合は更新
        existingUserLanguages[userLanguageIndex].language = language; // ユーザーの言語を変更
        await updateDoc(meetingDocRef, {
          userLanguages: existingUserLanguages
        });
        console.log(`change language : ${language} (id: ${clientId})`);
      } else {
        // firestore に同じユーザーの言語設定データが存在しない場合は追加
        const newUserLanguage: UserLanguage = {
          id: clientId,
          language: language,
        };
        await updateDoc(meetingDocRef, {
          userLanguages: [...existingUserLanguages, newUserLanguage]
        });
        console.log(`language : ${language} (id: ${clientId}) added successfully.`);
      }
    }
  } catch (error) {
    console.error("Error adding user language:", error);
  }
};

// ユーザー言語設定データを操作するためのhook
export const useUserLanguages = (roomOwnerId: string, meetingId: string): UserLanguage[] => {
  const [userLanguages, setUserLanguage] = useState<UserLanguage[]>([]);

  useEffect(() => {
    // オーナーIDとミーティングIDが揃ったらユーザー言語設定データを追加
    if (roomOwnerId !== INITIAL_ID && meetingId !== INITIAL_ID) {
      updateUserLanguage(roomOwnerId, meetingId, LocalStorage.language[0] ?? ILanguages['ja-JP'])

      const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);

      const unsubscribe = onSnapshot(meetingDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setUserLanguage(data.userLanguages || []);
        } else {
          console.log("No such document!");
        }
      }, (error) => {
        console.error("Error fetching user languages:", error);
      });

      return () => unsubscribe();
    }
  }, [roomOwnerId, meetingId]);

  return userLanguages;
};
