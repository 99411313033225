import './RecordButton.css'

import React, { MutableRefObject, useCallback, useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { observer } from 'mobx-react-lite'

import { LocalStorage } from '../store/LocalStorage'
import { useTranslation } from "react-i18next";
import { toast, Id } from 'react-toastify';
import { ILanguages } from '../util/language';
import { useWavRecordAzure } from '../hook/useWavRecordAzure';

interface RecordButtonProps {
  meetingId: string
  onData?: (talkId: string, speakerId: string, text: string, language: string, isFinal: boolean) => void
  onRecordStateChange: ({isRecording, isDisabled}: {isRecording: boolean, isDisabled: boolean}) => void
  changeLabels: (idMap: { [id: string]: string }) => void
  roomOwnerId: string
}

export interface RecordButtonHandles {
  startRecording: (disabled?: boolean) => void;
  stopRecording: (disabled?: boolean) => void;
  handleToggle: () => void;
  disableToggle: () => void;
  enableToggle: () => void;
  getIsRecording: () => boolean;
  getIsDisabled: () => boolean;
  updateClusterAmount: (value: any) => void;
}

const SecondCounter = observer(() => {
  const state = LocalStorage.roomState
  const [dynTime, setDynTime] = useState(0)

  useEffect(() => {
    let handle: NodeJS.Timer | null = null
    const time = LocalStorage.endTime

    if (state === 'incall' && time) {
      handle = setInterval(() => {
        setDynTime(Math.floor((time.getTime() - Date.now()) / 1000))
      }, 1000)
    }

    return () => {
      if (handle)
        clearInterval(handle)
    }
  }, [state])

  if (state === 'waiting' || state === 'completed' || LocalStorage.endTime == null) {
    return null
  }

  const MINUTES = 60
  const HOURS   = 60 * MINUTES

  let time = Math.abs(dynTime)
  const hours = Math.floor(time / HOURS).toString().padStart(2, '0')
  time %= HOURS
  const minutes = Math.floor(time / MINUTES).toString().padStart(2, '0')
  time %= MINUTES
  const seconds = time.toString().padStart(2, '0')

  return <span className='gap-2'>
    <i className="bi bi-clock" />
     <span className={dynTime < 0 ? 'text-danger' : ''}>
       {dynTime < 0 ? ' -' : ' '}{hours}:{minutes}:{seconds}
     </span>
  </span>
})

const RecordButtonAzure = forwardRef<RecordButtonHandles, RecordButtonProps>(({ meetingId, onData, onRecordStateChange, changeLabels, roomOwnerId }: RecordButtonProps, ref) => {
  const { t } = useTranslation()
  const [isDisabled, setIsDisabled] = useState(false)
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<string|null>(null);

  // 話者分離機能は未実装だが既存機能に合わせて updateClusterAmount を実装する
  // TODO: 話者分離機能機能が使用可能になったら修正する
  const [clusterAmount, setClusterAmount] = useState(1)
  function updateClusterAmount(newClusterAmount: number) {
    console.log(`update cluster amount: ${clusterAmount} -> ${newClusterAmount}`);
    setClusterAmount(newClusterAmount);
  }

  const lastIResult = useRef('')
  const bottomRef = useRef<HTMLDivElement>(null)

  // ドラフト文（isFinal:false）の表示・非表示処理
  let interimRef: MutableRefObject<null|Id> = useRef(null);
  const dismissInterim = useCallback(() => {
    if(interimRef.current !== null){
      try{
        toast.dismiss(interimRef.current);
      }catch (e){
        console.error(e)
      }
      interimRef.current = null;
    }
  }, []);
  const showInterim = useCallback((result: string) => {
    try {
      if(!result || result.trim() === ''){
        return;
      }
      if(interimRef.current !== null){
        toast.update(interimRef.current, { render: result });
      }else{
        interimRef.current = toast(result);
      }
    }catch (e){
      console.error(e);
      dismissInterim();
    }
  }, [dismissInterim]);

  // 話者分離機能は未実装のため固定値を使用する
  // TODO: 話者分離機能機能が使用可能になったら修正する
  const talkIdNumberRef = useRef<number>(1);
  const speakerIdRef = useRef<string>("user1");

  const onAzureSttMsg = useCallback((message: string, isFinal?: boolean, language?: string) => {
    // console.log(message, isFinal, language)
    if (isFinal) {
      lastIResult.current = ''
      dismissInterim()
    } else {
      lastIResult.current = message
      showInterim(message);
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }, 100)
    }

    // 話者分離機能は未実装のため固定値を使用する
    // TODO: 話者分離機能機能が使用可能になったら修正する
    // 会話IDの数字部分を加算
    talkIdNumberRef.current++;
    // ゼロ埋めして会話IDを作成（既存機能に合わせる）
    const talkId = "t" + String(talkIdNumberRef.current).padStart(5, '0');
    // 既存機能に合わせて後続処理を実施
    onData && onData(talkId, speakerIdRef.current, message, language || ILanguages["ja-JP"], !!isFinal)
  }, [dismissInterim, showInterim, onData])

  const onSetDisabled = useCallback((disabled: boolean) => {
    setIsDisabled(disabled);
  }, []);

  const {
    isSocketConnected, // ソケット通信が開始されたか
    isDeviceAnalyzed, // 音声デバイスと接続・解析したか
    isStarted, // 文字起こしを開始したか
    isMonitoring, // 音声をキャプチャ中か
    isErrored, // エラーが発生したか
    build: recorderBuild, // レコーダー起動処理
    start: recorderStart, // 録音開始処理
    stop: recorderStop, // 録音停止処理
    monitoringStart, // 音声キャプチャ開始処理
    monitoringStop, // 音声キャプチャ終了（保存）処理
    dataURL, // 音声キャプチャデータ（base64URL）
    deviceId: recorderDeviceId, // レコーダーに設定されているデバイスID
    errorMessage, // エラー発生時のメッセージ
    errorReport, // エラー発生時の詳細
  } = useWavRecordAzure(roomOwnerId, onSetDisabled, onAzureSttMsg)

  // レコーダーが起動したかのRef変数
  const isInitializedRef = useRef<boolean>(isDeviceAnalyzed);
  useEffect(() => {
    isInitializedRef.current = isDeviceAnalyzed;
  }, [isDeviceAnalyzed]);

  // 文字起こしを開始しているかのRef変数
  const isStartedRef = useRef<boolean>(isStarted);
  useEffect(() => {
    isStartedRef.current = isStarted;
  }, [isStarted]);

  // 文字起こし開始指示
  const start = useCallback((disabled?: boolean) => {
    if(isInitializedRef.current && !isStartedRef.current){
      recorderStart();
    }
  }, [recorderStart])

  // 文字起こし終了指示
  const stop = useCallback((disabled?: boolean) => {
    // ドラフト文表示停止
    dismissInterim();
    if(isInitializedRef.current && isStartedRef.current){
      recorderStop(!!disabled);
    }
  }, [dismissInterim, recorderStop]);

  // 音声レコーダー状態切替
  const handleToggle = useCallback(() => {
    if(!isStartedRef.current){
      start();
    }else{
      stop();
    }
  }, [start, stop]);

  // エラーメッセージ表示用のトースト
  let errorToastRef: MutableRefObject<null|Id> = useRef(null);
  useEffect(() => {
    // エラー状態の場合のみ処理
    if(!isErrored){
      return;
    }
    let message = "エラーが発生しました";
    if(errorMessage !== ''){
      message = errorMessage;
    }
    if(process.env.NODE_ENV !== 'production' && errorReport !== ''){
      message += errorReport;
    }
    if(errorToastRef.current !== null){
      // 表示中なら更新
      toast.update(errorToastRef.current, { render: message });
    }else{
      // 新たに表示
      errorToastRef.current = toast.error(message);
    }
  }, [errorMessage, errorReport, isErrored]);

  // 音声監視状態切替
  const handleWatchToggle = useCallback(() => {
    if (isMonitoring) {
      // すでに監視中の場合は停止（保存）
      monitoringStop();
    } else {
      // 監視スタート
      monitoringStart();
    }
  }, [isMonitoring, monitoringStart, monitoringStop]);


  const audioInputDevicesRef = useRef(audioInputDevices);
  useEffect(() => {
    audioInputDevicesRef.current = audioInputDevices;
  }, [audioInputDevices]);

  // 規定のデバイスIDが確認できたら選択させる
  useEffect(() => {
    if(recorderDeviceId !== undefined){
      setSelectedAudioInputDevice(recorderDeviceId);
    }
  }, [recorderDeviceId]);

  // 選択デバイス変更の検知
  const handleChangeDevice = useCallback((deviceId: string) => {
    setSelectedAudioInputDevice(deviceId);
    recorderBuild(isStartedRef.current, false, deviceId);
  }, [recorderBuild]);

  // デバイス一覧の更新
  const fetchAudioInputDevices = useCallback( async () => {
    const audioInput = (
      await navigator.mediaDevices.enumerateDevices()
    ).filter((d) => d.kind === "audioinput");
    setAudioInputDevices(audioInput);
    return audioInput;
  }, []);

  const refreshDevices = useCallback(async () => {
    const audioInput = await fetchAudioInputDevices();
    if(audioInput.length === 0 || (audioInput.length === 1 && audioInput[0].deviceId === "")){
      const intervalId = setInterval(async () => {
        if(audioInputDevicesRef.current.length === 0 || (audioInputDevicesRef.current.length === 1 && audioInputDevicesRef.current[0].deviceId === "")){
          await fetchAudioInputDevices();
        }else{
          clearInterval(intervalId);
        }
      }, 2000);
    }
  }, [fetchAudioInputDevices]);

  useEffect(() => {
    // 初回デバイス一覧取得
    refreshDevices();
    // デバイスの着脱が行われるタイミングで更新処理を実施
    navigator.mediaDevices.addEventListener("devicechange", refreshDevices);
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        refreshDevices
      );
    };
  }, [refreshDevices]);

  // ボタン無効化（クリック不可）
  const disableToggle = useCallback(() => {
    // ボタン無効化（クリック不可）
    setIsDisabled(true);
  }, []);

  // ボタン有効化（クリック可）
  const enableToggle = useCallback(() => {
    setIsDisabled(false);
  }, []);

  useEffect(() => {
    onRecordStateChange({isRecording: isStarted, isDisabled});
  }, [isDisabled, isStarted, onRecordStateChange]);

  useEffect(() => {
    const handleLanguageChange = () => {
      const currentIsStarted = isStartedRef.current;
      if(isInitializedRef.current && isStartedRef.current){
        // ドラフト文表示停止
        dismissInterim();
        if(!currentIsStarted){
          recorderStop(false);
        }else{
          recorderStop(true).then(() => {
            recorderStart()
          })
        }
      }
    };
    // ローカルストレージのデータが変更された際に発火するリスナーとして追加
    window.addEventListener('localstorage-change-language', handleLanguageChange);
    window.addEventListener('localstorage-change-translationLanguage', handleLanguageChange);

    return () => {
      // リスナー解除
      window.removeEventListener('localstorage-change-language', handleLanguageChange);
      window.removeEventListener('localstorage-change-translationLanguage', handleLanguageChange);
    };
  }, [dismissInterim, recorderStop, recorderStart]);

  // コンポーネント外部からもメソッドを使用可能にする
  useImperativeHandle(ref, () => ({
    startRecording: start,
    stopRecording: stop,
    handleToggle,
    disableToggle,
    enableToggle,
    getIsRecording: () => isStarted,
    getIsDisabled: () => isDisabled,
    updateClusterAmount,
  }));

  return isSocketConnected ? (
    <div className='record-container my-2 pt-2'>
      <div className="row align-items-center flex-nowrap">
        <div className="col-4">

          {selectedAudioInputDevice && audioInputDevices.length !== 0 && (
            <select
              className="form-select form-select-sm"
              style={{height: "32px", top: "-10px", position: "relative", fontSize: "13px"}}
              disabled={isDisabled || !isDeviceAnalyzed || selectedAudioInputDevice.length === 0}
              value={selectedAudioInputDevice}
              onChange={ e => handleChangeDevice(e.target.value)}
            >
              {audioInputDevices.map( (device, index) => <option key={index} value={device.deviceId}>{device.label}</option>)}
            </select>
          )}
          <SecondCounter />
        </div>
        <div className="col-4">
          <div className="d-flex justify-content-center">
            <div className="d-flex justify-content-center control-panel">
              <button
                className={`mic-btn p-2 ${isStarted ? 'active' : ''}`}
                disabled={isDisabled || !isDeviceAnalyzed}
                onClick={handleToggle}
              >
                {isStarted
                  ? <i className="bi bi-mic-fill mic-icon"></i>
                  : <i className="bi bi-mic-mute-fill mic-icon"></i>
                }
              </button>
            </div>
          </div>
        </div>
        {process.env.REACT_APP_ENVIRONMENT !== "production" && (
          <div className="col-4">
            <button
              className={`w-100 btn btn-sm ${isMonitoring ? 'btn-danger' : 'btn-primary'}`}
              style={{marginBottom: "5px"}}
              disabled={isDisabled || !isStarted}
              onClick={handleWatchToggle}
            >
              {isMonitoring
                ? '保存'
                : '録音'
              }
            </button>
            <audio
              className={`w-100`}
              style={{height: "26px", marginTop: "5px"}}
              src={dataURL}
              controls
            />
          </div>
        )}
      </div>
      <small className={`mb-2 fs-8 ${isDisabled || isStarted ? 'd-none' : ''}`}>
        {t('マイクボタンをクリックし、会話をスタートしてください。')}
      </small>
    </div>
  ) : (
    <div className='record-container mb-1 mt-2 py-2 text-danger'>
      <p className="m-0 mb-1 fw-bold">
        {t('マイクが取得できませんでした。')}
      </p>
      <div>
        <p className="m-0">
          {t('ブラウザをリロードして「マイクの許可」を行うか、他のタブやアプリがマイクを使用していないか確認してください。')}
        </p>
      </div>
    </div>
  )
});

export default observer(RecordButtonAzure)
