import './InlineFlag.css'

import { useMemo } from 'react'

import { LanguageList, guestLanguage } from '../util/language'

export const InlineFlag = ({ lang }: { lang: string }) => {
  const iLang = useMemo(() => {
    return guestLanguage(lang)
  }, [lang])

  return iLang ? (
    <img className='flag-inline me-2' src={LanguageList[iLang.id].img}
      alt={LanguageList[iLang.id].languageName} />
  ) : null
}
