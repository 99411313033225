import { useEffect, useRef } from 'react'

const DELTA_TIME = 16
const BACKGROUND_COLOR = '#D6D6D6'
const FOREGROUND_COLOR = 'rgb(1, 160, 233)'

function renderTick(analyser: AnalyserNode, buffer: Uint8Array, canvas: HTMLCanvasElement, parent: HTMLElement) {
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return
  }

  const WIDTH  = parent.clientWidth
  const HEIGHT = parent.clientHeight
  canvas.width = WIDTH
  canvas.height = HEIGHT

  ctx.fillStyle = BACKGROUND_COLOR
  ctx.strokeStyle = FOREGROUND_COLOR

  ctx.lineWidth = 2
  ctx.fillRect(0, 0, WIDTH, HEIGHT)
  analyser.getByteTimeDomainData(buffer)

  let x = 0
  ctx.beginPath()

  for (let i = 0; i < buffer.length; ++i) {
    // NOTE(fujii): Byte Domain goes from 0 to 255 with 128 when there's no sound.
    let y = (buffer[i] / 128.0) * (HEIGHT / 2)
    // TODO: Polish shape of sawtooth

    if (i === 0) {
      ctx.moveTo(x, y)
    } else {
      ctx.lineTo(x, y)
    }
    x += (WIDTH / buffer.length)
  }

  ctx.lineTo(x, HEIGHT/2)
  ctx.stroke()
}


const Oscilloscope = ({ analyser }: { analyser: AnalyserNode }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const canvasElement = canvasRef.current
    const parentElement = parentRef.current
    if (canvasElement && parentElement) {
      const audioArray = new Uint8Array(analyser.frequencyBinCount)
      const oscilloTick = setInterval(
        () => renderTick(analyser, audioArray, canvasElement, parentElement),
        DELTA_TIME
      )

      return (() => {
        clearTimeout(oscilloTick)
      })
    }
  }, [analyser])

  return (
      <div ref={parentRef} className="col-auto" style={{ width: "100%" }}>
        <canvas ref={canvasRef} style={{ display: 'block' }}></canvas>
      </div>
  )
}

export default Oscilloscope
