
import { useEffect, useState } from 'react'
import { doc, onSnapshot, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Speaker } from '../types/speaker'
import { Participant } from '../types/participant'


export const  getSpeakerDisplayName = async (roomOwnerId: string, meetingId: string, speaker: Speaker) => {
  if (speaker.userId){
    const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);
    const docSnapshot = await getDoc(meetingDocRef);
    if (docSnapshot.exists()) {
      const participants: Participant[] = docSnapshot.data().participants
      const participantIndex = participants.findIndex(participant => participant.id === speaker.userId);
      const participant = participantIndex !== -1 ? docSnapshot.data().participants[participantIndex] : null;
      return participant.name
    }
  }
  return speaker.id
}

export const addOrUpdateSpeaker = async (roomOwnerId: string, meetingId: string, speakerId: string, userId: string | null) => {
  const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);

  try {
    const docSnapshot = await getDoc(meetingDocRef);
    if (!docSnapshot.exists()) {
      const speaker: Speaker = {
        id: speakerId,
        userId: userId
      };
      if (userId) {
        const username = await getSpeakerDisplayName(roomOwnerId, meetingId, speaker)
        speaker.username = username
      }
      await setDoc(meetingDocRef, {
        speakers: [speaker]
      });
    } else {
      // If speaker data already exists, update the participant status to ONLINE.
      const speakers: Speaker[] = docSnapshot.data().speakers || [];
      const speakerIndex = speakers.findIndex(speaker => speaker.id === speakerId);
      if (speakerIndex !== -1) {
        // If user already exists, update the status to ONLINE.
        speakers[speakerIndex].userId = userId;
        if (userId) {
          const username = await getSpeakerDisplayName(roomOwnerId, meetingId, speakers[speakerIndex])
          speakers[speakerIndex].username = username
        }
        await updateDoc(meetingDocRef, {
          speakers: speakers
        });
      } else {
        const newSpeaker: Speaker = {
          id: speakerId,
          userId: userId,
        };
        if (userId) {
          const username = await getSpeakerDisplayName(roomOwnerId, meetingId, newSpeaker)
          newSpeaker.username = username
        }
        await updateDoc(meetingDocRef, {
          speakers: [...speakers, newSpeaker]
        });
      }
    }
  } catch (error) {
    console.error("Error adding/update speaker:", error);
  }
};

export const useSpeakers = (roomOwnerId: string, meetingId: string): Speaker[] => {
  // Manage participants state by using Firestore realtime database.
  // Participants will be updated in real-time when a new participant joins or leaves the meeting.

  const [speakers, setSpeakers] = useState<Speaker[]>([]);

  useEffect(() => {
      const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);
      const unsubscribe = onSnapshot(meetingDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setSpeakers(data.speakers || []);
        } else {
          console.log("No such document!");
        }
      }, (error) => {
        console.error("Error fetching speakers:", error);
      });

      return () => unsubscribe();  // Cleanup function to remove listener
  }, [roomOwnerId, meetingId]);

  return speakers;
};
