import { makeAutoObservable } from "mobx"

/**
 * 会議を継続するかを尋ねるモーダルの状態管理クラス
 */
export class ConfirmMeetingModalState {
  isShow: boolean = false
  callback = () => {}

  constructor() {
    makeAutoObservable(this)
  }
}

const ConfirmMeetingModalStateInst = new ConfirmMeetingModalState()
export default ConfirmMeetingModalStateInst
