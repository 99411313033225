import { SttDictionariesFirebaseData } from '../types/sttDictionaries';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { firestore } from '../firebase';
import { QueryDocumentSnapshot } from '@firebase/firestore';
import { MutableRefObject, useCallback, useRef } from 'react';

export const useSttDictionaries = (roomOwnerId: string) => {
  // 正規表現化された辞書情報
  const dictionariesRegexRef: MutableRefObject<null|RegExp> = useRef(null);
  // 辞書登録がない: true / 辞書登録がある: false
  const isUseDictionaryRef: MutableRefObject<boolean> = useRef(false);
  // 扱いやすい形にした辞書情報
  const dictionariesRef: MutableRefObject<{[key: string]: string}> = useRef({});

  // システム辞書の取得
  const getSystemSttDictionaries = useCallback(async (): Promise<SttDictionariesFirebaseData[]> => {
    const sttDictionaries = collection(firestore, `config/general/sttDictionaries`)

    const queries = []
    queries.push(orderBy('targetWord', 'asc'))

    const q = query(sttDictionaries, ...queries)
    const snap = await getDocs(q);

    const arr: SttDictionariesFirebaseData[] = [];
    snap.docs.forEach((doc: QueryDocumentSnapshot) => {
      const sttDictionariesData = doc.data() as SttDictionariesFirebaseData
      arr.push(sttDictionariesData);
    });
    return arr;
  }, []);

  // ユーザー辞書の取得
  const getUserSttDictionaries = useCallback(async (user: string | number | undefined): Promise<SttDictionariesFirebaseData[]> => {
    const sttDictionaries = collection(firestore, `users/${user}/sttDictionaries`)

    const queries = []
    queries.push(orderBy('targetWord', 'asc'))

    const q = query(sttDictionaries, ...queries)
    const snap = await getDocs(q);

    const arr: SttDictionariesFirebaseData[] = [];
    snap.docs.forEach((doc: QueryDocumentSnapshot) => {
      const sttDictionariesData = doc.data() as SttDictionariesFirebaseData
      arr.push({...sttDictionariesData, id: doc.id});
    });
    return arr;
  }, []);

  // 辞書登録した言葉を置換する
  const replaceTextByDictionaries = useCallback(async (text: string) => {
    if(!dictionariesRegexRef.current){
      // システム全体の辞書・ユーザーごとの辞書を取得
      const [systemDict, userDict] = await Promise.all([getSystemSttDictionaries(), getUserSttDictionaries(roomOwnerId)]);
      // 置換する文字（sourceWords）を持つ辞書情報のみ抽出
      const mergedDict: SttDictionariesFirebaseData[] = [...systemDict, ...userDict].filter(dict => dict.sourceWords && dict.sourceWords.length !== 0);
      console.log(mergedDict);
      // システム・ユーザーの辞書を扱いやすい形にマージ
      for (const { targetWord, sourceWords } of mergedDict) {
        // @ts-ignore
        for (const sourceWord of sourceWords) {
          dictionariesRef.current[sourceWord] = targetWord;
        }
      }
      // 登録がない場合
      isUseDictionaryRef.current = Object.keys(dictionariesRef.current).length === 0;
      // 辞書登録したデータを正規表現化
      dictionariesRegexRef.current = new RegExp(Object.keys(dictionariesRef.current).join('|'), 'g');
    }
    // 辞書登録した言葉を置換（登録がない場合は処理しない）
    return isUseDictionaryRef.current ? text : text.replace(dictionariesRegexRef.current, (matched) => dictionariesRef.current[matched]);
  }, [getSystemSttDictionaries, getUserSttDictionaries, roomOwnerId]);

  return {replaceTextByDictionaries};
}
