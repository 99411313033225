import 'react-tooltip/dist/react-tooltip.css'

import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import React, {ChangeEvent, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Typeahead, TypeaheadRef} from "react-bootstrap-typeahead";
import {Option, TypeaheadPropsAndState} from "react-bootstrap-typeahead/types/types";
import { Tooltip } from 'react-tooltip'

import { ReactComponent as CaretUpFill } from '../assets/images/caret-up-fill.svg'
import { ReactComponent as CaretDownFill } from '../assets/images/caret-down-fill.svg'
import { ReactComponent as PlusCircleFill } from '../assets/images/plus-circle-fill.svg'
import { ReactComponent as PencilFill } from '../assets/images/pencil-fill.svg'
import { ReactComponent as Trash3Fill } from '../assets/images/trash3-fill.svg'
import { isDefaultMinutes, changeLanguageAgenda, changeLanguageAgendaList } from "../util/util";
import { LanguageList } from "../util/language";
import { LocalStorage } from "../store/LocalStorage";
import useQuery from "../hook/useQuery";

export const AgendaAddModal = observer(({ open, onClose, onAgendaAdd, defaultValue }: { open?: boolean, onClose?: () => void, onAgendaAdd: (val: string) => boolean, defaultValue: string }) => {
    const {t} = useTranslation()
    const myModal = useRef<any>()

    const [val, setVal] = useState(defaultValue)

    useEffect(() => {
        if (!myModal.current) {
            myModal.current = new window.bootstrap.Modal(document.getElementById('agendaAdd'), {
                keyboard: false
            })
        }

        if (open) {
            myModal.current.show()
        } else {
            myModal.current.hide()
        }
    }, [onClose, open])

    const handleValChange = useCallback((e: any) => {
        setVal(e.target.value)
    }, [])

    const handleAgendaClose = useCallback(() => {
        onClose && onClose()
    }, [onClose])

    const handleAgendaAdd = useCallback(() => {
        if(onAgendaAdd){
            if(!onAgendaAdd(val)){
                return;
            }
        }
        onClose && onClose()
    }, [onAgendaAdd, onClose, val])

    return (
        <div className='modal fade' id='agendaAdd'
             tabIndex={-1} aria-labelledby='agendaAddLabel' aria-hidden='true'>
            <div className='modal-dialog modal-lg'>
                <div className='modal-content' style={{borderRadius: '1rem'}}>
                    <div className='modal-header border-bottom-0'>
                        <h5 className='modal-title' id='userInfoLabel'>
                            {t('議題追加')}
                        </h5>
                        <button type='button' className='btn-close' onClick={handleAgendaClose}></button>
                    </div>
                    <div className='modal-body'>
                        <div className='container'>
                            <div className="row mb-3">
                                <label htmlFor="agendaAddInput" className="col-sm-2 col-form-label">{t('議題')}</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="agendaAddInput" value={val} onChange={handleValChange} placeholder={t('議題を入力...')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-secondary' onClick={handleAgendaClose}>
                            {t('閉じる')}
                        </button>
                        <button type='button' className='btn btn-primary'
                                disabled={val === ''} onClick={handleAgendaAdd}>
                            {t('OK')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export const AgendaEditModal = observer(({ open, onClose, onAgendaEdit, defaultValue, targetAgenda }: { open?: boolean, onClose?: () => void, onAgendaEdit: (val: string, targetAgenda: string) => boolean, defaultValue: string, targetAgenda: string }) => {
    const {t} = useTranslation()
    const myModal = useRef<any>()

    // 編集中フラグ
    const editing = useRef<boolean>(true)

    const [val, setVal] = useState(defaultValue !== '' ? defaultValue : targetAgenda)

    useEffect(() => {
        if (!myModal.current) {
            myModal.current = new window.bootstrap.Modal(document.getElementById('agendaEdit'), {
                keyboard: false
            })
        }

        if (open) {
            // 編集中ではない場合は入力値を変更
            if(!editing.current){
                setVal(defaultValue !== '' ? defaultValue : targetAgenda);
            }
            myModal.current.show()
        } else {
            // 編集中フラグ:OFF
            editing.current = false;
            myModal.current.hide()
        }
    }, [defaultValue, onClose, open, targetAgenda])

    const handleValChange = useCallback((e: any) => {
        // 編集中フラグ:ON
        editing.current = true;
        setVal(e.target.value)
    }, [])

    const handleAgendaClose = useCallback(() => {
        onClose && onClose()
    }, [onClose])

    const handleAgendaEdit = useCallback(() => {
        if(onAgendaEdit){
            if(!onAgendaEdit(val, targetAgenda)){
                return;
            }
        }
        onClose && onClose()
    }, [onAgendaEdit, onClose, targetAgenda, val])

    return (
        <div className='modal fade' id='agendaEdit'
             tabIndex={-1} aria-labelledby='agendaEditLabel' aria-hidden='true'>
            <div className='modal-dialog modal-lg'>
                <div className='modal-content' style={{borderRadius: '1rem'}}>
                    <div className='modal-header border-bottom-0'>
                        <h5 className='modal-title' id='userInfoLabel'>
                            {t('議題編集')}
                        </h5>
                        <button type='button' className='btn-close' onClick={handleAgendaClose}></button>
                    </div>
                    <div className='modal-body'>
                        <div className='container'>
                            <div className="row mb-3">
                                <label htmlFor="agendaEditTargetInput" className="col-sm-2 col-form-label">{t('変更前の議題')}</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="agendaEditTargetInput" value={targetAgenda} readOnly={true} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="agendaEditInput" className="col-sm-2 col-form-label">{t('変更後の議題')}</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="agendaEditInput" value={val}
                                           onChange={handleValChange} placeholder={t('議題を入力...')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-secondary' onClick={handleAgendaClose}>
                            {t('閉じる')}
                        </button>
                        <button type='button' className='btn btn-primary'
                                disabled={val === ''} onClick={handleAgendaEdit}>
                            {t('OK')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export const AgendaDeleteModal = observer(({ open, onClose, onAgendaDelete, targetAgenda }: { open?: boolean, onClose?: () => void, onAgendaDelete: (targetAgenda: string) => boolean, targetAgenda: string }) => {
    const {t} = useTranslation()
    const myModal = useRef<any>()

    useEffect(() => {
        if (!myModal.current) {
            myModal.current = new window.bootstrap.Modal(document.getElementById('agendaDelete'), {
                keyboard: false
            })
        }

        if (open) {
            myModal.current.show()
        } else {
            myModal.current.hide()
        }
    }, [onClose, open])

    const handleAgendaClose = useCallback(() => {
        onClose && onClose()
    }, [onClose])

    const handleAgendaDelete = useCallback(() => {
        if(onAgendaDelete){
            if(!onAgendaDelete(targetAgenda)){
                return;
            }
        }
        onClose && onClose()
    }, [onAgendaDelete, onClose, targetAgenda])

    return (
        <div className='modal fade' id='agendaDelete'
             tabIndex={-1} aria-labelledby='agendaDeleteLabel' aria-hidden='true'>
            <div className='modal-dialog modal-lg'>
                <div className='modal-content' style={{borderRadius: '1rem'}}>
                    <div className='modal-header border-bottom-0'>
                        <h5 className='modal-title' id='userInfoLabel'>
                            {t('議題削除')}
                        </h5>
                        <button type='button' className='btn-close' onClick={handleAgendaClose}></button>
                    </div>
                    <div className='modal-body'>
                        <div className='container'>
                            <div className="row mb-3">
                                <label htmlFor="agendaDeleteInput" className="col-sm-2 col-form-label">{t('削除する議題')}</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="agendaDeleteInput" value={targetAgenda} readOnly={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-secondary' onClick={handleAgendaClose}>
                            {t('閉じる')}
                        </button>
                        <button type='button' className='btn btn-primary' onClick={handleAgendaDelete}>
                            {t('OK')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
})

export interface AgendaSelectHandles {
    setAgendaSelected: (newAgendaSelected: string) => void;
    setAgendaEditValue: (newAgendaEditValue: string) => void;
    getAgendaEditValue: () => string;
}

interface AgendaSelectProps {
    typeaheadShow: boolean,
    agenda: string[],
    agendaFilterBy: (option: Option, state: TypeaheadPropsAndState) => boolean,
    agendaValue: string,
    handleAgendaSelect: (selected: Option[]) => void,
    handleAgendaInput: (text: string, event: ChangeEvent<HTMLInputElement>) => void,
    handleAgendaChange: (targetAgenda: string) => void,
    typeaheadRef: Ref<TypeaheadRef>,
    onAgendaAdd: (val: string) => boolean,
    onAgendaEdit: (val: string, targetAgenda: string) => boolean,
    onAgendaDelete: (targetAgenda: string) => boolean,
}

export const AgendaSelect = forwardRef<AgendaSelectHandles, AgendaSelectProps>(({
  typeaheadRef,
  agendaFilterBy,
  agenda,
  typeaheadShow,
  handleAgendaSelect,
  handleAgendaInput,
  handleAgendaChange,
  agendaValue,
  onAgendaAdd,
  onAgendaEdit,
  onAgendaDelete
}: AgendaSelectProps, ref) => {
    const {t, i18n} = useTranslation()
    const { getSummaryFlag } = useQuery();
    const summaryFlag = getSummaryFlag();

    const [defaultInputValue, setDefaultInputValue] = useState(agendaValue)
    const [agendaList, setAgendaList] = useState(agenda)
    const [typeaheadShowState, setTypeaheadShowState] = useState(typeaheadShow)
    const [showAgendaAddModal, setShowAgendaAddModal] = useState(false)
    const [showAgendaEditModal, setShowAgendaEditModal] = useState(false)
    const [showAgendaDeleteModal, setShowAgendaDeleteModal] = useState(false)
    const [agendaSelected, setAgendaSelected] = useState(LanguageList['ja-JP'].summaryAgenda as string)
    const [agendaEditValue, setAgendaEditValue] = useState('')

    const handleAgendaAddModalClose = () => {
        setShowAgendaAddModal(false);
    }
    const handleAgendaEditModalClose = () => {
        setShowAgendaEditModal(false);
    }
    const handleAgendaDeleteModalClose = () => {
        setShowAgendaDeleteModal(false);
    }

    const handleAgendaAdd = () => {
        setShowAgendaAddModal(true);
    }
    const handleAgendaEdit = () => {
        setShowAgendaEditModal(true);
    }
    const handleAgendaDelete = () => {
        setShowAgendaDeleteModal(true);
    }

    // コンポーネント外部からもメソッドを使用可能にする
    useImperativeHandle(ref, () => ({
        setAgendaSelected,
        setAgendaEditValue,
        getAgendaEditValue: () => agendaEditValue,
    }));

    useEffect(() => {
        setDefaultInputValue(agendaValue);
    }, [agendaValue]);

    useEffect(() => {
        setAgendaList(agenda);
    }, [agenda]);

    useEffect(() => {
        setTypeaheadShowState(typeaheadShow);
    }, [typeaheadShow]);

    // 言語変更時に規定のタイトル名「全体要約」「議事録」を変更する
    const changeLanguageHandler = useCallback((lng: string) => {
        const changeAgenda = changeLanguageAgenda(lng, agendaSelected);
        const changeAgendaList = changeLanguageAgendaList(lng, agendaList);
        setAgendaSelected(changeAgenda);
        setDefaultInputValue(changeAgenda);
        setAgendaList(changeAgendaList);
        setTypeaheadShowState(false);
        setTimeout(() => {
            setTypeaheadShowState(true);
        }, 0);
    }, [agendaList, agendaSelected]);

    useEffect(() => {
        // 言語変更時に規定のタイトル名「全体要約」「議事録」を変更する
        i18n.on('languageChanged', changeLanguageHandler);
        return () => {
            i18n.off('languageChanged', changeLanguageHandler);
        };
    }, [agendaList, agendaSelected, changeLanguageHandler, i18n]);

    return (
        <>
            {(agendaList.length !== 0 && typeaheadShowState) && (
                <Typeahead
                    ref={typeaheadRef}
                    filterBy={agendaFilterBy}
                    id="summary-agenda-list"
                    className={`${summaryFlag ? ' d-inline-block d-sm-inline-block d-md-none' : ''}`}
                    options={agendaList}
                    onChange={handleAgendaSelect}
                    defaultInputValue={defaultInputValue}
                    onInputChange={handleAgendaInput}
                    placeholder={t('議題を入力...')}>
                    {({isMenuShown, toggleMenu}) => (
                        <button
                            id="summary-agenda-toggle-button"
                            className="toggle-button"
                            onClick={toggleMenu}
                            onMouseDown={(e) => {
                                e.preventDefault()
                            }}>
                            {isMenuShown ? <CaretUpFill className="caret-up-fill"/> :
                                <CaretDownFill className="caret-down-fill"/>}
                        </button>
                    )}
                </Typeahead>
            )}
            <div className={`list-group${summaryFlag ? ' d-none d-sm-none d-md-block' : ' d-none'}`}>
                {agendaList.map((item) => (
                    <button key={item} type="button"
                            onClick={() => {
                                handleAgendaChange(item);
                            }}
                            className={`list-group-item list-group-item-action${item === agendaSelected ? ' active' : ''}`}>
                        {item}
                        {!isDefaultMinutes(item) && item === agendaSelected && (
                            <div className="btn-group floating" role="group">
                                <button
                                    id="summary-agenda-edit-button-wide"
                                    className={`btn btn-success control-button edit-button ok`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAgendaEdit();
                                    }}
                                    disabled={LocalStorage.roomClosed}
                                >
                                    <PencilFill className="pencil-fill"/>
                                </button>
                                <Tooltip anchorSelect="#summary-agenda-edit-button-wide"
                                         place="bottom">{t('議題編集')}</Tooltip>
                                <button
                                    id="summary-agenda-delete-button-wide"
                                    className={`btn btn-danger control-button delete-button ok`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAgendaDelete();
                                    }}
                                    disabled={LocalStorage.roomClosed}
                                >
                                    <Trash3Fill className="trash3-fill"/>
                                </button>
                                <Tooltip anchorSelect="#summary-agenda-delete-button-wide"
                                         place="bottom">{t('議題削除')}</Tooltip>
                            </div>
                        )}
                    </button>
                ))}
                {!LocalStorage.roomClosed && (<button
                    id="summary-agenda-add-button-wide"
                    className={`list-group-item list-group-item-action list-group-item-success text-center`}
                    onClick={handleAgendaAdd}
                >
                    <PlusCircleFill className="plus-circle-fill"/> {t('議題追加')}
                </button>)}
            </div>
            <div className={`btn-group${summaryFlag ? ' d-inline-block d-sm-inline-block d-md-none btn-group-agenda' : ' btn-group-agenda'}`} role="group">
                <button
                    id="summary-agenda-add-button"
                    className={`btn btn-primary control-button add-button ok`}
                    onClick={handleAgendaAdd}
                    disabled={LocalStorage.roomClosed}
                >
                    <PlusCircleFill className="plus-circle-fill"/>
                </button>
                <Tooltip anchorSelect="#summary-agenda-add-button" place="bottom">{t('議題追加')}</Tooltip>
                <button
                    id="summary-agenda-edit-button"
                    className={`btn btn-success control-button edit-button${agendaSelected !== '' && !isDefaultMinutes(agendaSelected) ? ' ok' : ''}`}
                    onClick={handleAgendaEdit}
                    disabled={LocalStorage.roomClosed || !(agendaSelected !== '' && !isDefaultMinutes(agendaSelected))}
                >
                    <PencilFill className="pencil-fill"/>
                </button>
                <Tooltip anchorSelect="#summary-agenda-edit-button" place="bottom">{t('議題編集')}</Tooltip>
                <button
                    id="summary-agenda-delete-button"
                    className={`btn btn-danger control-button delete-button${agendaSelected !== '' && !isDefaultMinutes(agendaSelected) && agendaEditValue === '' ? ' ok' : ''}`}
                    onClick={handleAgendaDelete}
                    disabled={LocalStorage.roomClosed || !(agendaSelected !== ''  && !isDefaultMinutes(agendaSelected) && agendaEditValue === '')}
                >
                    <Trash3Fill className="trash3-fill"/>
                </button>
                <Tooltip anchorSelect="#summary-agenda-delete-button" place="bottom">{t('議題削除')}</Tooltip>
            </div>
            <AgendaAddModal open={showAgendaAddModal} onClose={handleAgendaAddModalClose} defaultValue={agendaEditValue}
                            onAgendaAdd={onAgendaAdd}/>
            <AgendaEditModal open={showAgendaEditModal} onClose={handleAgendaEditModalClose}
                             defaultValue={agendaEditValue} targetAgenda={agendaSelected} onAgendaEdit={onAgendaEdit}/>
            <AgendaDeleteModal open={showAgendaDeleteModal} onClose={handleAgendaDeleteModalClose}
                               targetAgenda={agendaSelected} onAgendaDelete={onAgendaDelete}/>
        </>
    )
})
