import { RefObject } from "react";
import { makeAutoObservable } from 'mobx'
import { playAudio, audioContext } from '../util/audioContext'
import { JobQueue } from '../util/JobQueue'
import { RecordButtonHandles } from '../component/RecordButton'

/*
 Manages all things audio.
 TODO:
  - AudioManager should be a singleton accessed through class .instance
  - AudioManager should contain playAudio, audioContext and audioMasterAnalyserBus as a static member
  - figure out a clean way to provide access to a convolver impulse

 TODO: Merge this into SessionInfo or something
 along with AdvisorState and several variables in LocalStorage
*/

type PlayAudioJob = {
  buffer: AudioBuffer | Promise<AudioBuffer>,
  destination: AudioNode,
}

/**
 * A promise for loading convolution impulse response.
 *
 * A convolution impulse is a short impact sound used for convolution.
 * it is typically used for implementing reverb effect.
 *
 * @todo clean it up. there are better ways to fetch this probably.
 */
const convolverImpulsePromise = fetch('/spring_reverb_impulse.mp3')
  .then(response => response.arrayBuffer())
  .then(arraybuf => audioContext.decodeAudioData(arraybuf))

export class AudioManager {
  currentlyPlaying: number = 0
  playingAudioQueue: JobQueue<PlayAudioJob>
  private recorderRef: RefObject<RecordButtonHandles> | undefined;
  private beforeIsRecording: boolean = false;

  constructor() {
    makeAutoObservable(this, {
      playingAudioQueue: false,
    })

    this.playingAudioQueue = new JobQueue(async data => {
      await playAudio(await data.buffer, data.destination)
      this.finishPlayAudio()
    })
  }

  static _convolverImpulse: AudioBuffer
  async getConvolverImpulse(): Promise<AudioBuffer> {
    if (AudioManager._convolverImpulse) {
      return AudioManager._convolverImpulse
    }

    const value = await convolverImpulsePromise
    AudioManager._convolverImpulse = value

    return value
  }

  setRecordRef(recorderRef: RefObject<RecordButtonHandles>): void {
    this.recorderRef = recorderRef;
  }

  // Needs to be separated because they're on a different tick.
  private finishPlayAudio() {
    this.currentlyPlaying -= 1
    if(this.recorderRef && this.recorderRef.current){
      this.recorderRef.current.enableToggle();
      if(this.beforeIsRecording){
        this.recorderRef.current.startRecording();
      }
      this.beforeIsRecording = false;
    }
  }

  queuePlayAudio(buffer: AudioBuffer | Promise<AudioBuffer>, destination: AudioNode) {
    if(this.recorderRef && this.recorderRef.current){
      if(this.recorderRef.current.getIsRecording()){
        this.beforeIsRecording = true;
        this.recorderRef.current.stopRecording(true);
      }else{
        this.recorderRef.current.disableToggle();
      }
    }
    this.currentlyPlaying += 1
    this.playingAudioQueue.pushJob({ buffer, destination })
  }
}

const AudioManagerInst = new AudioManager()
export default AudioManagerInst
