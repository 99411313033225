import './ChatList.css'

import ChatRow from './ChatRow'
import LoadingChatRow from "./LoadingChatRow";

interface ChatListProps {
  items: ChatMessage[];
  userId: number | string;
  roomOwnerId: string;
  meetingId: string;
  showLoadingChat: boolean;
}

const ChatList = ({ items, userId, roomOwnerId, meetingId, showLoadingChat }: ChatListProps) => {

  return (
    <div className='chat-container container py-3'>
      {items.map((message, i) => (
        <ChatRow key={message.id} user={userId} item={message} last={items.length - 1 === i} roomOwnerId={roomOwnerId} meetingId={meetingId} />
      ))}
      {showLoadingChat && (
          <LoadingChatRow mine={true} />
      )}
    </div>
  )
}

export default ChatList
