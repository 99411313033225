import "./NotFoundPage.css"

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

export const NotFoundPage = observer(() => {
  const { t } = useTranslation()

  const backdrop = document.querySelector('.modal-backdrop');
  if (backdrop) {
    backdrop?.parentNode?.removeChild(backdrop);
  }

  return (
    <div className='not-found-page py-5 px-2 px-sm-3'>
      <div className='logo-bg'>
        <img src='/logo.png' alt='LOGO' />
      </div>
      <div className='container d-flex flex-column' style={{ height: '100%', maxWidth: 800, overflowY: 'auto' }}>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <img src='/logo-wide.png' alt='logo-wide' className='logo-wide' />
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-auto'>
            <b>donut AI(議事録ver.)</b>
          </div>
        </div>
        <div className='mt-5 box-01 bg-white p-3 mx-3 d-flex align-items-center justify-content-center' style={{ overflowY: 'auto', height: 'calc(100vh - 400px)' }}>
            <div>
                <h3 className="mb-4 text-center">{t('その会議は存在しません')}</h3>
                <p className="mb-4 text-center">{t('入力されたURLの会議は存在しません。URLを確認して、再度お試しください。')}</p>
            </div>
        </div>
      </div>
    </div>
  )
})
